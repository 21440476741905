import React, { Component } from 'react';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import '../CSS/Settings.css';
import { getIntegrations, changeIntegration } from '../utils/OpenCampground_Database_Calls'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});
const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class PaymentConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payment: [],
            paymentUpdate: false,
            name: '',
            tempSelectedValue: '',
            open: false,
            popupTitle: '',
            popupText: ''
        };
    }

    async componentDidMount() {
        this.payment()
    }

    payment = async () => {
        if (this.state.paymentUpdate) {
            let payment = await getIntegrations(this.props.serverName)
            console.log(payment)
            this.setState({
                payment: payment,
                name: payment.name
            })
        } else {
            if (this.state.payment.length > 0) {
            } else {
                let payment = await getIntegrations(this.props.serverName)
                console.log(payment)
                this.setState({
                    payment: payment,
                    name: payment.name
                })
            }
        }
    }

    isRadioChecked = (value) => {
        return this.state.name === value;
    }

    savePaymentConfiguration = async () => {
        let saveresponse = await changeIntegration(this.props.serverName, this.state.name)
        this.setState({
            paymentUpdate: true,
            popupText: 'Payment gateway changed to ' + this.state.name,
            open: true
        })
        // this.payment()
    }

    handleCancel = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Dialog open={this.state.open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
                        <DialogTitle>{this.state.popupTitle}</DialogTitle>
                        <DialogContent>
                            {this.state.popupText}
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={this.handleCancel} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Typography>Payment Gateway Setup</Typography>
                    <div className='paymentConfig'>
                        <div className='row'>
                            <label>
                                <Radio type='radio' name='option' value='none' checked={this.isRadioChecked('None')} onChange={() => { this.setState({ name: 'None' }) }} />
                                None
                            </label>
                        </div>
                        <div className='row'>
                            <label>
                                <Radio type='radio' name='option' value='CampPay' checked={this.isRadioChecked('CampPay')} onChange={() => { this.setState({ name: 'CampPay' }) }} />
                                OpenWorldPay
                            </label>
                            <label>
                                <Radio type='radio' name='option' value='CampPay_o' checked={this.isRadioChecked('CampPay_o')} onChange={() => { this.setState({ name: 'CampPay_o' }) }} />
                                OpenWorldPay - Office Only
                            </label>
                            <label>
                                <Radio type='radio' name='option' value='CampPay_r' checked={this.isRadioChecked('CampPay_r')} onChange={() => { this.setState({ name: 'CampPay_r' }) }} />
                                OpenWorldPay - Remote Only
                            </label>
                        </div>
                        <div className='row'>
                            <label>
                                <Radio type='radio' name='option' value='CardConnect' checked={this.isRadioChecked('CardConnect')} onChange={() => { this.setState({ name: 'CardConnect' }) }} />
                                CardConnect
                            </label>
                            <label>
                                <Radio type='radio' name='option' value='CardConnect_o' checked={this.isRadioChecked('CardConnect_o')} onChange={() => { this.setState({ name: 'CardConnect_o' }) }} />
                                CardConnect - Office Only
                            </label>
                            <label>
                                <Radio type='radio' name='option' value='CardConnect_r' checked={this.isRadioChecked('CardConnect_r')} onChange={() => { this.setState({ name: 'CardConnect_r' }) }} />
                                CardConnect - Remote Only
                            </label>
                        </div>
                        {/* <div className='row'>
                            <label>
                                <Radio type='radio' name='option' value='PayPal' checked={this.isRadioChecked('PayPal')} onChange={() => { this.setState({ name: 'PayPal' }) }} />
                                PayPal
                            </label>
                            <label>
                                <Radio type='radio' name='option' value='PayPal_o' checked={this.isRadioChecked('PayPal_o')} onChange={() => { this.setState({ name: 'PayPal_o' }) }} />
                                PayPal - Office Only
                            </label>
                            <label>
                                <Radio type='radio' name='option' value='PayPal_r' checked={this.isRadioChecked('PayPal_r')} onChange={() => { this.setState({ name: 'PayPal_r' }) }} />
                                PayPal - Remote Only
                            </label>
                        </div> */}
                    </div>
                    <Button className='viewButtons' style={{ backgroundColor: '#2dcd7a', color: '#000000', marginTop: '3%' }} onClick={this.savePaymentConfiguration}>Save</Button>
                </ThemeProvider>
            </>
        );
    };
};
export default withStyles(useStyles)(PaymentConfiguration);