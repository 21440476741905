import React, { Component } from 'react';
import { Button, TextField, Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../CSS/Settings.css';
import { deleteRecord, getBlackoutDates, createNewBlackout, updateBlackout } from '../utils/OpenCampground_Database_Calls';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class RemoteBlackouts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blackouts: [],
            blackoutsUpdate: false,
            editingBlackout: null,
            addNew: false,
            name: '',
            start: null,
            end: null,
            active: false,
            editMode: false,
            formValid: false
        };
    }

    async componentDidMount() {
        this.remoteBlackouts();
    }

    new = async () => {
        this.setState({
            addNew: true
        });
    }

    handleChange = (event) => {
        if (event.target.id === 'active') {
            this.setState({
                [event.target.id]: event.target.checked
            });
        } else if (event.target.id === 'name') {
            this.setState({
                [event.target.id]: event.target.value
            }, () => {
                this.formValidation();
            });
        }
    }

    formValidation = () => {
        if (this.state.name && this.state.start && this.state.end) {
            this.setState({
                formValid: true
            });
        } else if (this.state.formValid) {
            this.setState({
                formValid: false
            });
        }
    }

    deleteRecord = async (data) => {
        await deleteRecord(this.props.serverName, "blackouts", data.id);
        this.setState({
            blackoutsUpdate: true
        });
        this.remoteBlackouts();
    }

    remoteBlackouts = async () => {
        if (this.state.blackoutsUpdate) {
            let getBlackoutDatesResponse = await getBlackoutDates(this.props.serverName);
            this.setState({
                blackouts: getBlackoutDatesResponse
            });
        } else {
            if (this.state.blackouts.length === 0) {
                let getBlackoutDatesResponse = await getBlackoutDates(this.props.serverName);
                this.setState({
                    blackouts: getBlackoutDatesResponse
                });
            }
        }
    }

    addNew = async () => {
        await createNewBlackout(this.props.serverName, this.state.name, this.state.start.format('YYYY-MM-DD'), this.state.end.format('YYYY-MM-DD'), this.state.active);
        this.cancelEdit();
        this.remoteBlackouts();
    }

    updateBlackout = async () => {
        let result = await updateBlackout(this.props.serverName, this.state.name, this.state.start.format('YYYY-MM-DD'), this.state.end.format('YYYY-MM-DD'), this.state.active, this.state.editingBlackout.id);
        this.cancelEdit();
        this.remoteBlackouts();
    }

    editBlackout = (blackout) => {
        this.setState({
            editMode: true,
            addNew: false,
            name: blackout.name,
            start: dayjs(blackout.start),
            end: dayjs(blackout.end),
            active: blackout.active,
            editingBlackout: blackout
        });
    }

    cancelEdit = () => {
        this.setState({
            blackoutsUpdate: true,
            editingBlackout: null,
            editMode: false,
            addNew: false,
            editingSpace: null,
            name: '',
            start: null,
            end: null,
            active: false
        });
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Button className='resButton' onClick={this.new} style={{ backgroundColor: '#2dcd7a', color: '#000000', marginBottom: '2%', textTransform: "capitalize" }}>
                    Add New Online Blackout
                </Button>
                {this.state.addNew || this.state.editMode
                    ?
                    <>
                        <Dialog open={this.state.addNew || this.state.editMode} onClose={() => {
                            this.cancelEdit();
                        }} maxWidth="sm" fullWidth>
                            <DialogContent dividers>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                    <h2 style={{ margin: "0px", marginBottom: "10px" }}>{this.state.editMode ? "Update Blackout" : "Add Blackout"}</h2>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                        <TextField
                                            id="name"
                                            required
                                            label="Blackout Name"
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                id='start'
                                                label='Start Date'
                                                value={this.state.start}
                                                onChange={(date) => {
                                                    this.setState({ start: date }, () => {
                                                        this.formValidation();
                                                    });
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                id='end'
                                                label='End Date'
                                                value={this.state.end}
                                                onChange={(date) => {
                                                    this.setState({ end: date }, () => {
                                                        this.formValidation();
                                                    });
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <FormControlLabel
                                            label={"Active"}
                                            control={
                                                <Checkbox
                                                    id="active"
                                                    checked={this.state.active}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button onClick={this.state.editMode ? this.updateBlackout : this.addNew} disabled={this.state.editMode ? false : !this.state.formValid} style={{ backgroundColor: '#2dcd7a', color: '#000000' }}>{this.state.editMode ? "Update" : "Add"}</Button>
                                        <Button onClick={() => this.cancelEdit()} style={{ backgroundColor: '#FDECEB', color: '#DB3737', marginLeft: '8px' }}>Cancel</Button>
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </>
                    : ""}
                <>
                    <TableContainer component={Paper} style={{ width: '90%', margin: '0 auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Blackout Name</TableCell>
                                    <TableCell>Start</TableCell>
                                    <TableCell>End</TableCell>
                                    <TableCell>Active</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.blackouts.map((blackout) => (
                                    <TableRow key={blackout.id}>
                                        <TableCell>{blackout.name}</TableCell>

                                        <TableCell>{blackout.start}</TableCell>

                                        <TableCell>{blackout.end}</TableCell>

                                        <TableCell>{(blackout.active ? 'Yes' : 'No')}</TableCell>
                                        <TableCell>
                                            <div>
                                                <Button onClick={() => this.editBlackout(blackout)} className='viewButtons' style={{ backgroundColor: '#d4d4d4', color: 'black' }}>Edit</Button>
                                                <Button onClick={() => this.deleteRecord(blackout)} className='viewButtons'>Delete</Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            </ThemeProvider>
        );
    }
}
export default RemoteBlackouts;