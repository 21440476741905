import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from '@material-ui/core';
import '../CSS/NewReservation.css';
import { getTaxes, getEmailTemplates, getEmails, getCampers, getGroups, createnewCamper, createNewGroup, createGroupReservations } from '../utils/OpenCampground_Database_Calls'
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import reservationConfirmation from '../assets/reservation_confirm.png'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import GroupIcon from '@mui/icons-material/Group';
import PetsIcon from '@mui/icons-material/Pets';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Autocomplete, createFilterOptions } from '@mui/material';
import { format } from 'date-fns';

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    }
});

class GroupReservationUserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allGuests: [],
            //wagon Master details
            fname: [''],
            lname: [''],
            emailId: [''],
            phoneNumber: [''],
            address: [''],
            city: [''],
            state: [''],
            zip: [''],
            guests: [''],
            pets: [''],
            childrens: [''],
            camperId: [''],
            //group
            groupName: '',
            groupId: '',
            groups: [],
            //taxes
            taxes: [],
            tax: [],
            taxPercent: [],
            taxName: [],
            //total
            totalAmount: [],
            subTotal: [],
            monthsTotal: [],
            weeksTotal: [],
            daysTotal: [],
            days: '',
            weeks: '',
            months: '',
            //popup
            open: false,
            popupText: '',
            openGroupModal: false,
            wait: false,
            //email
            remoteResReceivedMailMessage: '',
            remoteReservationReceivedSubject: '',
            sender: '',
            reply: '',
            cc: '',
            bcc: '',
            selectedSpaceIndex: 1,
            formValid: false,
            groupIDS: [],
            spaceName: [],
            siteIds: [],
        };
    }

    async componentDidMount() {
        console.log("spaceDate: ", this.props.spacesSelected)
        this.getGuests()
        let getGroupsResponse = await getGroups(this.props.serverName)
        let mailMessages = await getEmailTemplates(this.props.serverName)
        let email = await getEmails(this.props.serverName)

        //calculate total
        let term = this.getDuration(this.props.checkIn, this.props.checkOut);
        let subTotalArray = [];
        let monthsTotalArray = [];
        let weeksTotalArray = [];
        let daysTotalArray = [];
        this.props.spacesSelected.forEach((space) => {
            let subTotal = 0.0;
            let monthsTotal = 0;
            let weeksTotal = 0;
            let daysTotal = 0;

            if (term[2] > 0) {
                if (space.monthly > 0) {
                    monthsTotal = term[2] * space.monthly;
                    subTotal += monthsTotal;
                } else {
                    term[1] += term[2] * 4;
                    term[2] = 0;
                }
            }
            if (term[1] > 0) {
                if (space.weekly > 0) {
                    weeksTotal = term[1] * space.weekly;
                    subTotal += weeksTotal;
                } else {
                    term[0] += term[1] * 7;
                    term[1] = 0;
                }
            }
            if (term[0] > 0) {
                daysTotal = term[0] * space.daily;
                subTotal += daysTotal;
            }

            subTotalArray.push(subTotal);
            monthsTotalArray.push(monthsTotal);
            weeksTotalArray.push(weeksTotal);
            daysTotalArray.push(daysTotal);
        });
        this.calculateTaxAndTotal(subTotalArray, term);
        this.setState({
            groups: getGroupsResponse.body,
            subTotal: subTotalArray,
            monthsTotal: monthsTotalArray,
            weeksTotal: weeksTotalArray,
            daysTotal: daysTotalArray,
            days: term[0],
            weeks: term[1],
            months: term[2],
            remoteResReceivedMailMessage: mailMessages[3].body,
            remoteReservationReceivedSubject: email.remote_res_subject,
            sender: email.sender,
            reply: email.reply,
            cc: email.cc,
            bcc: email.bcc,
            siteIds: this.props.spacesSelected.map(item => item.siteId),
            spaceName: this.props.spacesSelected.map(item => item.spaceName)
        })
    }

    calculateTaxAndTotal = async (subTotalArray, term) => {
        let taxes = await getTaxes(this.props.serverName);
        let totalAmountArray = [];
        let taxArray = [];
        let taxPercentArray = [];
        let taxNameArray = [];

        subTotalArray.forEach((subTotal) => {
            let totalAmount = subTotal;
            let tax = [];
            let taxPercent = [];
            let taxName = [];

            for (let index = 0; index < taxes.length; index++) {
                const element = taxes[index];
                if (element.is_percent === 1) {
                    if (element.apl_daily === 0 && element.apl_week === 0 && element.apl_month === 0) {
                        console.log("No tax");
                    } else if (element.apl_daily === 1) {
                        taxPercent.push(element.percent);
                        tax.push((subTotal * (element.percent / 100)).toFixed(2));
                        taxName.push(element.name);
                    }
                } else {
                    let totalDays = term[0];
                    if (element.apl_month === 1) {
                        if (element.monthly_charge_daily === 1) {
                            totalDays = totalDays + term[2] * 30;
                        } else {
                            taxPercent.push(element.amount);
                            tax.push((term[2] * element.amount).toFixed(2));
                            taxName.push(element.name);
                        }
                    } else if (element.apl_week === 1) {
                        if (element.weekly_charge_daily === 1) {
                            totalDays = totalDays + term[1] * 7;
                        } else {
                            taxPercent.push(element.amount);
                            tax.push((term[1] * element.amount).toFixed(2));
                            taxName.push(element.name);
                        }
                    }
                    taxPercent.push(element.amount);
                    tax.push((totalDays * element.amount).toFixed(2));
                    taxName.push(element.name);
                }
            }

            for (let index = 0; index < tax.length; index++) {
                totalAmount += parseFloat(tax[index]);
            }

            totalAmountArray.push(totalAmount);
            taxArray.push(tax);
            taxPercentArray.push(taxPercent);
            taxNameArray.push(taxName);
        });

        this.setState({
            taxes: taxes,
            totalAmount: totalAmountArray,
            tax: taxArray,
            taxPercent: taxPercentArray,
            taxName: taxNameArray,
        });
    };

    getGuests = async () => {
        try {
            let listOfGuests = await getCampers(this.props.serverName);
            this.setState({
                allGuests: listOfGuests
            });
        } catch (error) {
            console.error("Error fetching guests:", error);
        }
    }

    getDuration(checkIn, checkOut) {
        const msPerDay = 24 * 60 * 60 * 1000;
        const msPerWeek = msPerDay * 7;
        const startDate = new Date(checkIn);
        const endDate = new Date(checkOut);
        let totalDays = Math.floor((endDate - startDate) / msPerDay);
        const months = Math.floor(totalDays / 30);
        totalDays %= 30;
        const weeks = Math.floor(totalDays / 7);
        totalDays %= 7;
        const days = totalDays;
        return [days, weeks, months];
    }

    getDays = () => {
        const msPerDay = 24 * 60 * 60 * 1000;
        const startDate = new Date(this.props.checkIn);
        const endDate = new Date(this.props.checkOut);
        let totalDays = Math.floor((endDate - startDate) / msPerDay);
        return totalDays;
    }

    handleCancel = () => {
        if (!this.state.wait) {
            this.setState({ open: false }, () => {
                this.props.history.replace(`/manager?serverName=${this.props.serverName}&view=reservations`);
            });
        }
    }

    userDetailsChange = (id, value, index) => {
        if (id === 'groupName') {
            this.setState({
                groupName: value
            }, () => {
                this.dataValidation()
            })
        } else {
            let finalValue
            if (id === 'phoneNumber' || id === 'guests' || id === 'childrens' || id === 'pets') {
                finalValue = value.replace(/[^0-9]/g, '')
            } else {
                finalValue = value
            }
            this.setState((prevState) => {
                const updatedArray = [...prevState[id]];
                if (updatedArray.length <= index) {
                    updatedArray.length = index + 1;
                    updatedArray[index] = '';
                }
                updatedArray[index] = finalValue;

                return {
                    [id]: updatedArray,
                };
            }, () => {
                this.dataValidation();
            });
        }
    };

    dataValidation = async () => {
        const len = this.props.spacesSelected.length;
        const fieldsToMatchLength = ['fname', 'lname', 'guests', 'childrens', 'pets'];
        const fieldsWithAtLeastZeroIndex = [
            'emailId', 'phoneNumber', 'address', 'city', 'state', 'zip'
        ];
        const areLengthFieldsValid = fieldsToMatchLength.every((field) => {
            const fieldArray = this.state[field];
            return (
                fieldArray.length === len &&
                fieldArray.every((entry) => entry.trim() !== '')
            );
        });
        const areZeroIndexFieldsValid = fieldsWithAtLeastZeroIndex.every((field) => {
            const fieldArray = this.state[field];
            return fieldArray[0]?.trim() !== '';
        });
        const isGroupNameValid = this.state.groupName.trim() !== '';
        this.setState({
            formValid: areLengthFieldsValid && areZeroIndexFieldsValid && isGroupNameValid
        });
    };

    handleSpaceChange = (event) => {
        this.setState({ selectedSpaceIndex: event.target.value });
    };

    makeReservations = async () => {
        this.setState({
            open: true,
            popupText: 'Please wait we are creating your group reservation...',
            wait: true
        })
        console.log(this.state.camperId)
        let groupIdForReservations
        if (this.state.groupId) {
            groupIdForReservations = this.state.groupId
            console.log("using existing group with id: ", groupIdForReservations)
        } else {
            console.log("creating new group")
            let organizerCamperId
            if (this.state.camperId[0]) {
                organizerCamperId = this.state.camperId[0]
                console.log("using existing camper with id: ", organizerCamperId)
            } else {
                let createNewCamperResponse = await createnewCamper(this.props.serverName, this.state.fname[0], this.state.lname[0], this.state.emailId[0], this.state.phoneNumber[0],
                    this.state.address[0], this.state.city[0], this.state.state[0], this.state.zip[0])
                console.log(createNewCamperResponse)
                organizerCamperId = createNewCamperResponse.body.insertId
                const updatedCamperId = [...this.state.camperId]
                updatedCamperId[0] = organizerCamperId
                this.setState({
                    camperId: updatedCamperId
                });
                console.log("created a new camper with id: ", organizerCamperId)
            }
            let createGroupResponse = await createNewGroup(this.props.serverName, this.state.groupName, this.props.checkIn, this.props.checkOut, organizerCamperId, this.props.spacesSelected.length)
            console.log(createGroupResponse)
            groupIdForReservations = createGroupResponse.body.insertId
        }
        console.log("GroupId for reservation: ", groupIdForReservations)
        console.log("camperId's for reservation: ", this.state.camperId)

        let groupReservationResponse = await createGroupReservations(this.props.serverName, this.state.lname, this.state.fname, this.state.address, this.state.city, this.state.state, this.state.zip, this.state.phoneNumber, this.state.emailId,
            groupIdForReservations, this.state.siteIds, this.props.checkIn, this.props.checkOut, this.state.guests, this.state.pets, this.state.childrens, this.state.subTotal, format(new Date(), 'yyyy-MM-dd'), this.state.totalAmount, this.state.camperId, this.props.spacesSelected.length)
        console.log(groupReservationResponse)
        this.setState({
            open: true,
            popupText: 'Reservations created with ids: ' + groupReservationResponse.ids,
            groupIDS: groupReservationResponse.ids,
            wait: false
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid className='reservationList' style={{ width: '100%' }}>
                <ThemeProvider theme={theme} classes={{ paper: classes.dialogPaper }}>
                    <Dialog open={this.state.open} onClose={this.handleCancel}>
                        <DialogTitle>Group Reservation Created</DialogTitle>
                        <DialogContent>
                            {this.state.wait
                                ?
                                <>
                                    {this.state.popupText}
                                </>
                                :
                                <>
                                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                        <thead style={{ backgroundColor: "#f1f2f4", color: "black" }}>
                                            <tr>
                                                <th style={{ textAlign: "left", padding: "10px" }}>Name</th>
                                                <th style={{ textAlign: "left", padding: "10px" }}>Space</th>
                                                <th style={{ textAlign: "left", padding: "10px" }}>Res #</th>
                                                <th style={{ textAlign: "left", padding: "10px" }}>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.spacesSelected.map((_, index) => (
                                                <tr key={index}>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>{this.state.fname[index]} {this.state.lname[index]}</td>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>{this.state.spaceName[index]}</td>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>{this.state.groupIDS[index]}</td>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>{this.state.totalAmount[index]}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            }
                        </DialogContent>
                        {this.state.wait
                            ? null
                            :
                            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={this.handleCancel} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                    Close
                                </Button>
                            </DialogActions>
                        }
                    </Dialog>

                    {/* Group Modal */}
                    <Dialog open={this.state.openGroupModal} onClose={() => this.setState({ openGroupModal: false })}>
                        <DialogTitle>Select or Create New Group</DialogTitle>
                        <DialogContent>
                            <Box style={{ display: 'flex', justifyContent: 'space-between', gap: '10%' }}>
                                <Autocomplete
                                    disablePortal
                                    loading={this.state?.groups?.length == 0}
                                    filterOptions={createFilterOptions({
                                        ignoreCase: true,
                                        matchFrom: "start",
                                    })}
                                    onChange={(_, value) => {
                                        if (value) {
                                            this.setState({
                                                groupName: value?.name,
                                                groupId: value?.id,
                                            }, () => {
                                                this.dataValidation();
                                            })
                                        }

                                    }}
                                    options={this.state?.groups && this.state?.groups?.length > 0 ? this.state?.groups.map((i) => ({ ...i, label: i.name, key: i.id })) : []}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Search Group" />}
                                />
                                <TextField id="groupName" required label="Group Name" type="text" className='field' value={this.state.groupName} onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, 0)} />
                            </Box>
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={() => this.setState({ openGroupModal: false, groupName: '', groupId: '' })}
                                style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                Cancel
                            </Button>
                            <Button onClick={() => this.setState({ openGroupModal: false })}
                                style={{ backgroundColor: '#2dcd7a', color: '#000000', padding: '2% 10% 2% 10%', margin: '0 2% 0 0', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </ThemeProvider>

                <Box className='scrollContainerUserDetails'>
                    <Box className='box' style={{ marginRight: '0%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={reservationConfirmation} alt="Reservations" style={{ height: '40px' }} />
                            <Typography className='reservationstypography'>Confirm Reservation</Typography>
                        </div>
                        <div style={{ display: 'flex', width: '45%', justifyContent: 'space-between' }}>
                            <Button startIcon={<ArrowBackIcon />} className='cancel' style={{ marginRight: '2%', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }} onClick={() => {
                                this.props.backToSelectSpace();
                            }}>Select Space</Button>
                            <Button className='cancel' onClick={() => {
                                this.props.history.replace(`/manager?serverName=${this.props.serverName}&view=reservations`)
                            }}>Cancel Reservation</Button>
                            <Button className='cancel' disabled={!this.state.formValid} onClick={() => { this.makeReservations(); }}
                                style={{
                                    marginRight: '-2%',
                                    backgroundColor: !(this.state.formValid)
                                        ? "#999999"
                                        : "#2dcd7a",
                                    color: "#000000"
                                }} >Complete Reservation</Button>
                        </div>
                    </Box>

                    <Box style={{ display: 'flex', justifyContent: 'left', marginLeft: '2%', marginBottom: '2%' }}>
                        <Button onClick={() => this.setState({ openGroupModal: true })} style={{ backgroundColor: '#2dcd7a', color: '#000000', }}> Select Group</Button>
                        {this.state.groupName != ''
                            ?
                            <Typography style={{ fontSize: '1.5rem', display: 'flex', justifyContent: 'left', marginLeft: '2%' }}> Group Name: {this.state.groupName}</Typography>
                            : null
                        }
                    </Box>

                    <Box className='main'>
                        <Box className='leftRow'>
                            <Box className='userDetails'>
                                <ThemeProvider theme={theme}>
                                    <Box style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                        <Typography className='typo'>Organizer Details</Typography>
                                        <Box style={{ marginRight: "1%", padding: "4% 3% 0 0" }}>
                                            <Autocomplete
                                                disablePortal
                                                loading={this.state?.allGuests?.length == 0}
                                                filterOptions={createFilterOptions({
                                                    ignoreCase: true,
                                                    matchFrom: "start",
                                                })}
                                                onChange={(_, value) => {
                                                    if (value) {
                                                        this.userDetailsChange("fname", value?.first_name, 0);
                                                        this.userDetailsChange("lname", value?.last_name, 0);
                                                        this.userDetailsChange("emailId", value?.email, 0);
                                                        this.userDetailsChange("phoneNumber", value?.phone, 0);
                                                        this.userDetailsChange("address", value?.address, 0);
                                                        this.userDetailsChange("city", value?.city, 0);
                                                        this.userDetailsChange("state", value?.state, 0);
                                                        this.userDetailsChange("zip", value?.mail_code, 0);
                                                        this.userDetailsChange("camperId", value?.id, 0);
                                                    }
                                                }}
                                                options={this.state?.allGuests && this.state?.allGuests?.length > 0 ? this.state?.allGuests.map((i) => ({ ...i, label: i.first_name + " " + i.last_name, key: i.id })) : []}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} label="Search Guest" />}
                                            />
                                        </Box>
                                    </Box>
                                    <hr className='horizontalLine' />
                                    <div className='form-row'>
                                        <TextField id="fname" required label="First Name" type="text" className='field' value={this.state.fname[0] || ''}
                                            InputLabelProps={{ shrink: Boolean(this.state.fname[0]) }} onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, 0)} />
                                        <TextField id="lname" required label="Last Name" type="text" className='field' value={this.state.lname[0] || ''}
                                            InputLabelProps={{ shrink: Boolean(this.state.lname[0]) }} onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, 0)} />
                                    </div>
                                    <div className="form-row-3" style={{ marginBottom: '-0.5%' }}>
                                        <TextField id="guests" required label="Guests" type="text" className="left" value={this.state.guests[0] || ''}
                                            InputLabelProps={{ shrink: Boolean(this.state.guests[0]) }} onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, 0)}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                                        <TextField id="childrens" required label="Childrens" type="text" className="middle" value={this.state.childrens[0] || ''}
                                            InputLabelProps={{ shrink: Boolean(this.state.childrens[0]) }} onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, 0)}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                                        <TextField id="pets" required label="Pets" type="text" className="right" value={this.state.pets[0] || ''}
                                            InputLabelProps={{ shrink: Boolean(this.state.pets[0]) }} onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, 0)}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                                    </div>
                                    <div className='form-row'>
                                        <TextField id="emailId" required label="Email Id" type="email" className='field' value={this.state.emailId[0] || ''}
                                            InputLabelProps={{ shrink: Boolean(this.state.emailId[0]) }} onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, 0)} />
                                        <TextField id="phoneNumber" required label="Phone Number" type="text" className='field' value={this.state.phoneNumber[0] || ''}
                                            InputLabelProps={{ shrink: Boolean(this.state.phoneNumber[0]) }} onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, 0)}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        />
                                    </div>
                                    <div className='form-row-2'>
                                        <TextField id="address" required label="Address" type="text" className='full-width' value={this.state.address[0]}
                                            InputLabelProps={{ shrink: Boolean(this.state.address[0]) }} onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, 0)} />
                                    </div>
                                    <div className='form-row-3'>
                                        <TextField id="city" required label="City" type="text" className='left' value={this.state.city[0] || ''}
                                            InputLabelProps={{ shrink: Boolean(this.state.city[0]) }} onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, 0)} />
                                        <TextField id="state" required label="State" type="text" className='middle' value={this.state.state[0] || ''}
                                            InputLabelProps={{ shrink: Boolean(this.state.state[0]) }} onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, 0)} />
                                        <TextField id="zip" required label="Postal Code" type="text" className='right' value={this.state.zip[0] || ''}
                                            InputLabelProps={{ shrink: Boolean(this.state.zip[0]) }} onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, 0)} />
                                    </div>
                                </ThemeProvider>
                            </Box>
                        </Box>
                        <Box className='rightRow'>
                            <Box className='bookingDetails' style={{ marginBottom: '7%' }}>
                                <Typography className='bookingTypo'>Booking Details (Site: {this.props.spacesSelected[0].spaceName})</Typography>
                                <hr className='horizontalLine' />
                                <Box className='bookingRow'>
                                    <Box>
                                        <DateRangeIcon />
                                        <Typography className='bookingLabel'>{this.props.checkIn}</Typography>
                                    </Box>
                                    <TrendingFlatIcon />
                                    <Box className='checkout'>
                                        <DateRangeIcon />
                                        <Typography className='bookingValue'>{this.props.checkOut}</Typography>
                                    </Box>
                                </Box>
                                <Box className='bookingRow'>
                                    <Box>
                                        <GroupIcon />
                                        <Typography className='bookingLabel'>Guests</Typography>
                                    </Box>
                                    <Typography className='bookingValue'>{this.state.guests[0]}</Typography>
                                </Box>
                                <Box className="bookingRow">
                                    <Box>
                                        <GroupIcon />
                                        <Typography className="bookingLabel">Childrens</Typography>
                                    </Box>
                                    <Typography className="bookingValue">{this.state.childrens[0]}</Typography>
                                </Box>
                                <Box className="bookingRow">
                                    <Box>
                                        <PetsIcon />
                                        <Typography className="bookingLabel">Pets</Typography>
                                    </Box>
                                    <Typography className="bookingValue">{this.state.pets[0]}</Typography>
                                </Box>
                                <hr className='horizontalLine' />
                                <Box className='bookingRow'>
                                    <Typography className='bookingLabel'>Site Name</Typography>
                                    <Typography className='bookingValue'>{this.props.spacesSelected[0].spaceName}</Typography>
                                </Box>
                                <Box className='bookingRow'>
                                    <Typography className='bookingLabel'>Site Type</Typography>
                                    <Typography className='bookingValue'>{this.props.spacesSelected[0].siteType}</Typography>
                                </Box>
                                {this.state.months > 0
                                    ? <Box className='bookingRow'>
                                        <Typography className='bookingLabel'>Months Total ({this.state.months})</Typography>
                                        <Typography className='bookingValue'>${this.state.monthsTotal[0]}</Typography>
                                    </Box>
                                    : null
                                }
                                {this.state.weeks > 0
                                    ? <Box className='bookingRow'>
                                        <Typography className='bookingLabel'>Weeks Total ({this.state.weeks})</Typography>
                                        <Typography className='bookingValue'>${this.state.weeksTotal[0]}</Typography>
                                    </Box>
                                    : null
                                }
                                {this.state.days > 0
                                    ? <Box className='bookingRow'>
                                        <Typography className='bookingLabel'>Days Total ({this.state.days})</Typography>
                                        <Typography className='bookingValue'>${this.state.daysTotal[0]}</Typography>
                                    </Box>
                                    : null
                                }
                                <hr className='horizontalLine' />
                                <Box className='bookingRow'>
                                    <Typography className='bookingLabel' style={{ fontWeight: 'bold' }}>Sub Total</Typography>
                                    <Typography className='bookingValue' style={{ fontWeight: 'bold' }}>${this.state.subTotal[0]}</Typography>
                                </Box>
                                <hr className='horizontalLine' />
                                {this.state.taxName[0] && Array.isArray(this.state.taxName[0]) &&
                                    this.state.taxName[0].map((taxName, taxIndex) => (
                                        <Box className='bookingRow' key={taxIndex}>
                                            <Typography className='bookingLabel'>
                                                {taxName} ({this.state.taxPercent[0][taxIndex]}%)
                                            </Typography>
                                            <Typography className='bookingValue'>
                                                ${this.state.tax[0][taxIndex]}
                                            </Typography>
                                        </Box>
                                    ))
                                }
                                <hr className='horizontalLine' />

                                <Box className='bookingRow'>
                                    <Typography className='bookingLabel' style={{ fontWeight: 'bold' }}>Total</Typography>
                                    <Typography className='bookingValue' style={{ fontWeight: 'bold' }}>${this.state.totalAmount[0]}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="container">
                        <Box className="main">
                            <Box className="leftRow">
                                <Box className="userDetails">
                                    <ThemeProvider theme={theme}>
                                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography className="typo">Guest Details</Typography>
                                            <Box style={{ marginRight: "1%", padding: "4% 3% 0 0" }}>
                                                <Autocomplete
                                                    disablePortal
                                                    loading={this.state?.allGuests?.length == 0}
                                                    filterOptions={createFilterOptions({
                                                        ignoreCase: true,
                                                        matchFrom: "start",
                                                    })}
                                                    onChange={(_, value) => {
                                                        if (value) {
                                                            this.userDetailsChange("fname", value?.first_name, this.state.selectedSpaceIndex);
                                                            this.userDetailsChange("lname", value?.last_name, this.state.selectedSpaceIndex);
                                                            this.userDetailsChange("emailId", value?.email, this.state.selectedSpaceIndex);
                                                            this.userDetailsChange("phoneNumber", value?.phone, this.state.selectedSpaceIndex);
                                                            this.userDetailsChange("address", value?.address, this.state.selectedSpaceIndex);
                                                            this.userDetailsChange("city", value?.city, this.state.selectedSpaceIndex);
                                                            this.userDetailsChange("state", value?.state, this.state.selectedSpaceIndex);
                                                            this.userDetailsChange("zip", value?.mail_code, this.state.selectedSpaceIndex);
                                                            this.userDetailsChange("camperId", value?.id, this.state.selectedSpaceIndex);
                                                        }
                                                    }}
                                                    options={this.state?.allGuests?.map((i) => ({
                                                        ...i,
                                                        label: `${i.first_name} ${i.last_name}`,
                                                        key: i.id,
                                                    }))}
                                                    sx={{ width: 300 }}
                                                    renderInput={(params) => <TextField {...params} label="Search Guest" />}
                                                />
                                            </Box>
                                        </Box>
                                        <hr className="horizontalLine" />
                                        <div className="form-row" style={{ marginLeft: '3%' }}>
                                            <Select
                                                value={this.state.selectedSpaceIndex}
                                                onChange={this.handleSpaceChange}
                                                style={{ width: "100px" }}
                                            >
                                                {this.props.spacesSelected.slice(1).map((space, index) => (
                                                    <MenuItem value={index + 1} key={space.spaceName}>
                                                        {space.spaceName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className="form-row">
                                            <TextField id="fname" required label="First Name" type="text" className="field" value={this.state.fname[this.state.selectedSpaceIndex]}
                                                onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, this.state.selectedSpaceIndex)}
                                                InputLabelProps={{ shrink: Boolean(this.state.fname[this.state.selectedSpaceIndex]) }} />
                                            <TextField id="lname" required label="Last Name" type="text" className="field" value={this.state.lname[this.state.selectedSpaceIndex]}
                                                onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, this.state.selectedSpaceIndex)}
                                                InputLabelProps={{ shrink: Boolean(this.state.lname[this.state.selectedSpaceIndex]) }} />
                                        </div>
                                        <div className="form-row-3" style={{ marginBottom: '-0.5%' }}>
                                            <TextField id="guests" required label="Guests" type="text" className="left" value={this.state.guests[this.state.selectedSpaceIndex]}
                                                onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, this.state.selectedSpaceIndex)}
                                                InputLabelProps={{ shrink: Boolean(this.state.guests[this.state.selectedSpaceIndex]) }} />
                                            <TextField id="childrens" required label="Childrens" type="text" className="middle" value={this.state.childrens[this.state.selectedSpaceIndex]}
                                                onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, this.state.selectedSpaceIndex)}
                                                InputLabelProps={{ shrink: Boolean(this.state.childrens[this.state.selectedSpaceIndex]) }} />
                                            <TextField id="pets" required label="Pets" type="text" className="right" value={this.state.pets[this.state.selectedSpaceIndex]}
                                                onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, this.state.selectedSpaceIndex)}
                                                InputLabelProps={{ shrink: Boolean(this.state.pets[this.state.selectedSpaceIndex]) }} />
                                        </div>
                                        <div className="form-row">
                                            <TextField id="emailId" label="Email Id" type="email" className="field" value={this.state.emailId[this.state.selectedSpaceIndex]}
                                                onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, this.state.selectedSpaceIndex)}
                                                InputLabelProps={{ shrink: Boolean(this.state.emailId[this.state.selectedSpaceIndex]) }} />
                                            <TextField id="phoneNumber" label="Phone Number" type="text" className="field" value={this.state.phoneNumber[this.state.selectedSpaceIndex]}
                                                onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, this.state.selectedSpaceIndex)}
                                                InputLabelProps={{ shrink: Boolean(this.state.phoneNumber[this.state.selectedSpaceIndex]) }} inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                            />
                                        </div>
                                        <div className="form-row-2">
                                            <TextField id="address" label="Address" type="text" className="full-width" value={this.state.address[this.state.selectedSpaceIndex]}
                                                onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, this.state.selectedSpaceIndex)}
                                                InputLabelProps={{ shrink: Boolean(this.state.address[this.state.selectedSpaceIndex]) }} />
                                        </div>
                                        <div className="form-row-3">
                                            <TextField id="city" label="City" type="text" className="left" value={this.state.city[this.state.selectedSpaceIndex]}
                                                onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, this.state.selectedSpaceIndex)}
                                                InputLabelProps={{ shrink: Boolean(this.state.city[this.state.selectedSpaceIndex]) }} />
                                            <TextField id="state" label="State" type="text" className="middle" value={this.state.state[this.state.selectedSpaceIndex]}
                                                onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, this.state.selectedSpaceIndex)}
                                                InputLabelProps={{ shrink: Boolean(this.state.state[this.state.selectedSpaceIndex]) }} />
                                            <TextField id="zip" label="Postal Code" type="text" className="right" value={this.state.zip[this.state.selectedSpaceIndex]}
                                                onChange={(event) => this.userDetailsChange(event.target.id, event.target.value, this.state.selectedSpaceIndex)}
                                                InputLabelProps={{ shrink: Boolean(this.state.zip[this.state.selectedSpaceIndex]) }} />
                                        </div>
                                    </ThemeProvider>
                                </Box>
                            </Box>
                            <Box className="rightRow">
                                <Box className="bookingDetails" style={{ marginBottom: "7%" }}>
                                    <Typography className="bookingTypo">Booking Details (Site: {this.props.spacesSelected[this.state.selectedSpaceIndex].spaceName})</Typography>
                                    <hr className="horizontalLine" />
                                    <Box className="bookingRow">
                                        <Box>
                                            <DateRangeIcon />
                                            <Typography className="bookingLabel">{this.props.checkIn}</Typography>
                                        </Box>
                                        <TrendingFlatIcon />
                                        <Box className="checkout">
                                            <DateRangeIcon />
                                            <Typography className="bookingValue">{this.props.checkOut}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="bookingRow">
                                        <Box>
                                            <GroupIcon />
                                            <Typography className="bookingLabel">Guests</Typography>
                                        </Box>
                                        <Typography className="bookingValue">{this.state.guests[this.state.selectedSpaceIndex]}</Typography>
                                    </Box>
                                    <Box className="bookingRow">
                                        <Box>
                                            <GroupIcon />
                                            <Typography className="bookingLabel">Childrens</Typography>
                                        </Box>
                                        <Typography className="bookingValue">{this.state.childrens[this.state.selectedSpaceIndex]}</Typography>
                                    </Box>
                                    <Box className="bookingRow">
                                        <Box>
                                            <PetsIcon />
                                            <Typography className="bookingLabel">Pets</Typography>
                                        </Box>
                                        <Typography className="bookingValue">{this.state.pets[this.state.selectedSpaceIndex]}</Typography>
                                    </Box>
                                    <hr className="horizontalLine" />
                                    <Box className="bookingRow">
                                        <Typography className="bookingLabel">Site Name</Typography>
                                        <Typography className="bookingValue">{this.props.spacesSelected[this.state.selectedSpaceIndex].spaceName}</Typography>
                                    </Box>
                                    <Box className="bookingRow">
                                        <Typography className="bookingLabel">Site Type</Typography>
                                        <Typography className="bookingValue">{this.props.spacesSelected[this.state.selectedSpaceIndex].siteType}</Typography>
                                    </Box>
                                    {this.state.months > 0 && (
                                        <Box className="bookingRow">
                                            <Typography className="bookingLabel">Months Total ({this.state.months})</Typography>
                                            <Typography className="bookingValue">${this.state.monthsTotal[this.state.selectedSpaceIndex]}</Typography>
                                        </Box>
                                    )}
                                    {this.state.weeks > 0 && (
                                        <Box className="bookingRow">
                                            <Typography className="bookingLabel">Weeks Total ({this.state.weeks})</Typography>
                                            <Typography className="bookingValue">${this.state.weeksTotal[this.state.selectedSpaceIndex]}</Typography>
                                        </Box>
                                    )}
                                    {this.state.days > 0 && (
                                        <Box className="bookingRow">
                                            <Typography className="bookingLabel">Days Total ({this.state.days})</Typography>
                                            <Typography className="bookingValue">${this.state.daysTotal[this.state.selectedSpaceIndex]}</Typography>
                                        </Box>
                                    )}
                                    <hr className="horizontalLine" />
                                    <Box className="bookingRow">
                                        <Typography className="bookingLabel" style={{ fontWeight: "bold" }}>Sub Total</Typography>
                                        <Typography className="bookingValue" style={{ fontWeight: "bold" }}>${this.state.subTotal[this.state.selectedSpaceIndex]}</Typography>
                                    </Box>
                                    <hr className="horizontalLine" />
                                    {this.state.taxName[this.state.selectedSpaceIndex] &&
                                        Array.isArray(this.state.taxName[this.state.selectedSpaceIndex]) &&
                                        this.state.taxName[this.state.selectedSpaceIndex].map((taxName, taxIndex) => (
                                            <Box className="bookingRow" key={taxIndex}>
                                                <Typography className="bookingLabel">
                                                    {taxName} ({this.state.taxPercent[this.state.selectedSpaceIndex][taxIndex]}%)
                                                </Typography>
                                                <Typography className="bookingValue">
                                                    ${this.state.tax[this.state.selectedSpaceIndex][taxIndex]}
                                                </Typography>
                                            </Box>
                                        ))}
                                    <hr className="horizontalLine" />
                                    <Box className="bookingRow">
                                        <Typography className="bookingLabel" style={{ fontWeight: "bold" }}>Total</Typography>
                                        <Typography className="bookingValue" style={{ fontWeight: "bold" }}>${this.state.totalAmount[this.state.selectedSpaceIndex]}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        )
    };
};
export default withRouter(withStyles(useStyles)(GroupReservationUserDetails));