import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { getReservations, checkIn, checkOut, cancel, getEmailTemplates, sendMail, getEmails } from '../utils/OpenCampground_Database_Calls'
import SearchBar from "material-ui-search-bar";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import View from './View';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Select, MenuItem } from '@mui/material';
import reservations from '../assets/reservation.png'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import '../CSS/Reservations.css';

const useStyles = theme => ({
    circularProgress: {
        position: 'absolute',
        left: '55%',
        top: '50%',
        color: '#2dcd7a'
    },
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Reservations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reservations: [],
            allReservations: null,
            currentPage: 1,
            searchValue: '',
            searchById: true,
            searchByName: false,
            showAllReservations: true,
            showReservations: false,
            showCheckedInReservations: false,
            view: false,
            viewItem: {},
            open: false,
            confirm: false,
            popupText: '',
            reservationToHandle: null,
            action: null,
            confirmationOpen: false,
            confirmationText: '',
            popupTitle: '',
            confirmationTitel: '',
            //email
            reservationCancelMailMessage: '',
            reservationCancelSubject: '',
            reservationFeedbackMailMessage: '',
            reservationFeedbackSubject: '',
            sender: '',
            reply: '',
            cc: '',
            bcc: '',
            noReservations: false,
            searchType: 'id',
        };
    }

    async componentDidMount() {
        let mailMessages = await getEmailTemplates(this.props.serverName)
        let email = await getEmails(this.props.serverName)
        this.setState({
            reservationCancelMailMessage: mailMessages[6].body,
            reservationCancelSubject: email.reservation_cancel_subject,
            reservationFeedbackMailMessage: mailMessages[2].body,
            reservationFeedbackSubject: email.feedback_subject,
            sender: email.sender,
            reply: email.reply,
            cc: email.cc,
            bcc: email.bcc,
        })
        this.reservations()
    }

    reservations = async () => {
        console.log("serverName: ", this.props.serverName)
        let listOfReservations = await getReservations(this.props.serverName);
        console.log(listOfReservations)
        console.log(listOfReservations.length === 0 ? true : false)
        this.setState({
            // noReservations: listOfReservations.length === 0 ? true : false,
            reservations: listOfReservations,
            allReservations: listOfReservations,
        })
    }

    getReservationsByName = async (value) => {
        let filteredReservations = this.state.allReservations.filter(reservations =>
            reservations.first_name && reservations.first_name.toLowerCase() === value.toLowerCase()
        );
        this.setState({
            value: '',
            reservations: filteredReservations,
            noReservations: filteredReservations.length > 0 ? false : true
        });
    }

    getReservationsByReservationNumber = (value) => {
        const filteredReservations = this.state.allReservations.filter(reservation =>
            reservation.id.toString().includes(value)
        );
        this.setState({
            value: '',
            reservations: filteredReservations,
            noReservations: filteredReservations.length > 0 ? false : true
        });
    }

    displayAllReservations = async () => {
        this.setState({
            reservations: this.state.allReservations,
            noReservations: false
        })
    }

    replaceMailTemplatePlaceHolders = (type, reservationNumber, camper, startDate, endDate,) => {
        let mailMessage
        if (type === "feedback") {
            mailMessage = this.state.reservationFeedbackMailMessage
        } else {
            mailMessage = this.state.reservationCancelMailMessage
        }
        mailMessage = mailMessage.replace('{{camper}}', camper);
        mailMessage = mailMessage.replace('{{number}}', reservationNumber);
        mailMessage = mailMessage.replace('{{start}}', startDate);
        mailMessage = mailMessage.replace('{{departure}}', endDate);
        return mailMessage
    }

    openPopup = async (reservationItem, action) => {
        console.log(reservationItem)
        let popupText = "";
        let popupTitel = "";
        switch (action) {
            case "checkOut":
                popupTitel = "Confirm Checkout"
                popupText = "Proceed with checkout for: Site Name:" + reservationItem.sitename + " Reservation Id: " + reservationItem.id + ". Ensure all charges are settled."
                break;
            case "checkIn":
                popupTitel = "Confirm Check-In"
                popupText = "Proceed with check-in for: Reservation Number: " + reservationItem.id + " Site Name:" + reservationItem.sitename + ". Verify all details."
                break;
            case "cancel":
                popupTitel = "Confirm Cancellation"
                popupText = "Proceed with cancellation for: Reservation Number: " + reservationItem.id + ". Verify cancellation policy."
                break;
            default:
                popupText = "";
        }

        this.setState({
            open: true,
            popupTitle: popupTitel,
            popupText: popupText,
            reservationToHandle: reservationItem,
            action: action
        });
    }

    handleConfirm = async () => {
        const { action, reservationToHandle } = this.state;
        this.setState({ confirm: true, open: false });
        let confirmationText = '';
        let confirmationTitel = '';
        let mailmessage = '';
        let mailResponse = null
        switch (action) {
            case "checkOut":
                await checkOut(this.props.serverName, reservationToHandle.id);
                mailmessage = this.replaceMailTemplatePlaceHolders("feedback", reservationToHandle.id, reservationToHandle.first_name + ' ' + reservationToHandle.last_name)
                mailResponse = await sendMail(this.props.serverName, this.state.sender, /*reservationToHandle.email*/"dan@opencampground.com", this.state.reservationFeedbackSubject, mailmessage, this.state.reply, this.state.cc, this.state.bcc)
                confirmationTitel = "Guest Checkout"
                confirmationText = "Confirm guest checkout details: Site Name:" + reservationToHandle.sitename + " Reservation Id: " + reservationToHandle.id + ". Verify accuracy before proceeding.";
                break;
            case "checkIn":
                await checkIn(this.props.serverName, reservationToHandle.id);
                confirmationTitel = "Guest Check-In"
                confirmationText = "Confirm check-in details: Reservation Number: " + reservationToHandle.id + " Site Name:" + reservationToHandle.sitename + ". Ensure all information is up to date.";
                break;
            case "cancel":
                await cancel(this.props.serverName, reservationToHandle.id);
                mailmessage = this.replaceMailTemplatePlaceHolders("cancel", reservationToHandle.id, reservationToHandle.first_name + ' ' + reservationToHandle.last_name, reservationToHandle.startdate, reservationToHandle.enddate)
                mailResponse = await sendMail(this.props.serverName, this.state.sender, /*reservationToHandle.email*/"dan@opencampground.com", this.state.reservationCancelSubject, mailmessage, this.state.reply, this.state.cc, this.state.bcc)
                confirmationTitel = "Guest Checkout"
                confirmationText = "Confirm cancellation for: Reservation Number: " + reservationToHandle.id + ". Note applicable cancellation fees";
                break;
            default:
                break;
        }
        this.setState({
            confirmationOpen: true,
            confirmationTitel: confirmationTitel,
            confirmationText: confirmationText
        });
        this.reservations();
    };

    handleCancel = () => {
        this.setState({ confirm: false, open: false });
        console.log("canceled");
    };

    filterClick = (type) => {
        let filteredReservations;
        if (type === 'reserved') {
            filteredReservations = this.state.allReservations.filter(reservation => reservation.checked_in === 0);
        } else if (type === 'checkedIn') {
            filteredReservations = this.state.allReservations.filter(reservation => reservation.checked_in === 1);
        } else if (type === 'groups') {
            filteredReservations = this.state.allReservations.filter(reservation => reservation.group_id !== null);
        } else {
            filteredReservations = this.state.allReservations;
        }
        this.setState({
            reservations: filteredReservations,
            noReservations: filteredReservations.length > 0 ? false : true
        });
    }

    view = async (viewItem) => {
        this.setState({
            viewItem: viewItem,
            view: true
        })
    }

    goBack = () => {
        this.setState({
            view: false,
            viewItem: {}
        });
        this.reservations()
    }

    render() {
        const { classes } = this.props;
        let reservationRows, previousButton, nextButton, lastButton
        let paginationButtons = []
        if (!this.state.noReservations && this.state.allReservations != null) {
            const itemsPerPage = 10;
            const totalPages = Math.ceil(this.state.reservations.length / itemsPerPage);
            let currentPage = this.state.currentPage || 1;

            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = Math.min(startIndex + itemsPerPage, this.state.reservations.length);

            const reservationsOnPage = this.state.reservations.slice(startIndex, endIndex);

            reservationRows = reservationsOnPage.map((item) => {
                return (
                    <tr key={item.id} className='reservationRows'>
                        <td>{item.id}</td>
                        <td>{item.first_name} {item.last_name}</td>
                        <td>{item.group_name ? "Group Name: " + item.group_name : item.rigname}</td>
                        <td>{item.startdate}</td>
                        <td>{item.enddate}</td>
                        <td>{item.sitename}</td>
                        <td>${item.amount_paid}</td>
                        <td>${((parseFloat(item.total) + parseFloat(item.tax_amount)) - parseFloat(item.amount_paid)).toFixed(2)}</td>
                        <td style={{
                            color: item.unconfirmed_remote === 1 ? '#FB8100' :
                                item.checked_in === 0 ? '#000000' : '#01AE00'
                        }}>
                            {item.unconfirmed_remote === 1 ? 'Review' :
                                item.checked_in === 0 ? 'Reserved' : 'Checked In'}
                        </td>
                        <td className='actions'>
                            <div className='actionButtons'>
                                {item.unconfirmed_remote === 1
                                    ?
                                    <>
                                        <Button
                                            className='selectButton'
                                            onClick={() => this.view(item)}
                                            style={{ backgroundColor: '#999999', color: '#ffffff', padding: '2% 10% 2% 10%', borderRadius: '10px', textTransform: "capitalize" }}
                                        >
                                            View
                                        </Button>
                                    </>
                                    :
                                    <>
                                        {item.checked_in === 0
                                            ?
                                            <>
                                                <Button
                                                    className='selectButton'
                                                    onClick={() => this.view(item)}
                                                    style={{ backgroundColor: '#999999', color: '#ffffff', padding: '2% 10% 2% 10%', borderRadius: '10px', textTransform: "capitalize" }}
                                                >
                                                    View
                                                </Button>
                                                <Button
                                                    className='selectButton'
                                                    onClick={() => this.openPopup(item, "checkIn")}
                                                    style={{ whiteSpace: "nowrap", backgroundColor: '#EAFAE8', color: '#01AE00', padding: '2% 10% 2% 10%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}
                                                >
                                                    Check In
                                                </Button>
                                                <Button
                                                    className='selectButton'
                                                    onClick={() => this.openPopup(item, "cancel")}
                                                    style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}
                                                >
                                                    Cancel
                                                </Button>
                                            </>
                                            :
                                            <>
                                                <Button
                                                    className='selectButton'
                                                    onClick={() => this.view(item)}
                                                    style={{ backgroundColor: '#999999', color: '#ffffff', padding: '2% 10% 2% 10%', borderRadius: '10px', textTransform: "capitalize" }}
                                                >
                                                    View
                                                </Button>
                                                <Button
                                                    className='selectButton'
                                                    onClick={() => this.openPopup(item, "checkOut")}
                                                    style={{ whiteSpace: "nowrap", backgroundColor: '#3671CE', color: '#ffffff', padding: '2% 10% 2% 10%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}
                                                >
                                                    Check Out
                                                </Button>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </td>
                    </tr>
                );
            });

            paginationButtons = [];
            const maxButtonsToShow = 10;
            let startPage, endPage;

            if (totalPages <= maxButtonsToShow) {
                startPage = 1;
                endPage = totalPages;
            } else {
                const maxPagesBeforeCurrentPage = Math.floor(maxButtonsToShow / 2);
                const maxPagesAfterCurrentPage = Math.ceil(maxButtonsToShow / 2) - 1;
                if (currentPage <= maxPagesBeforeCurrentPage) {
                    startPage = 1;
                    endPage = maxButtonsToShow;
                } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                    startPage = totalPages - maxButtonsToShow + 1;
                    endPage = totalPages;
                } else {
                    startPage = currentPage - maxPagesBeforeCurrentPage;
                    endPage = currentPage + maxPagesAfterCurrentPage;
                }
            }

            for (let i = startPage; i <= endPage; i++) {
                paginationButtons.push(
                    <Button className={`paginationButton ${currentPage === i ? "activePage" : ""}`} key={i} onClick={() => this.setState({ currentPage: i })}>
                        {i}
                    </Button>
                );
            }

            previousButton = (
                <Button className='previousButton' disabled={currentPage === 1} onClick={() => this.setState({ currentPage: currentPage - 1 })}>
                    <ArrowBackIosIcon />
                </Button>
            );

            nextButton = (
                <Button className='nextButton' disabled={currentPage === totalPages} onClick={() => this.setState({ currentPage: currentPage + 1 })}>
                    <ArrowForwardIosIcon />
                </Button>
            );

            lastButton = (
                <Button className='paginationButton' disabled={currentPage === totalPages} onClick={() => this.setState({ currentPage: totalPages })}>
                    {totalPages}
                </Button>
            );
        }
        if (this.state.reservations.length > 0 || this.state.noReservations) {
            return (
                <Grid className='reservationList'>
                    {!this.state.view
                        ?
                        <>
                            <ThemeProvider theme={theme}>
                                <Dialog open={this.state.open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
                                    <DialogTitle>{this.state.popupTitle}</DialogTitle>
                                    <DialogContent>
                                        {this.state.popupText}
                                    </DialogContent>
                                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Button onClick={this.handleCancel} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                            Cancel
                                        </Button>
                                        <Button onClick={this.handleConfirm} style={{ backgroundColor: '#2dcd7a', color: '#000000', padding: '2% 10% 2% 10%', margin: '0 2% 0 0', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                            Confirm
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog open={this.state.confirmationOpen} onClose={() => this.setState({ confirmationOpen: false })} classes={{ paper: classes.dialogPaper }}>
                                    <DialogTitle>{this.state.confirmationTitel}</DialogTitle>
                                    <DialogContent>
                                        {this.state.confirmationText}
                                    </DialogContent>
                                    <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button onClick={() => this.setState({ confirmationOpen: false })} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Box className='infoBox'>
                                    <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                                    <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                                </Box>

                                <Box className='box' style={{ marginTop: '-2%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={reservations} alt="Reservations" className='buttonImage' />
                                        <Typography className='reservationstypography' style={{ marginLeft: '5%' }}>
                                            Reservations
                                        </Typography>
                                    </div>
                                </Box>

                                <Box className='box'
                                    style={{ marginTop: '-3%' }}>
                                    <div className='buttonGroup' style={{ width: "60%" }}>
                                        <Button style={{ whiteSpace: "nowrap" }} className='button' onClick={() => { this.setState({ showReservations: false, showAllReservations: true }); this.filterClick('all'); }}>All Reservations</Button>
                                        <Button style={{ whiteSpace: "nowrap" }} className='button' onClick={() => { this.setState({ showAllReservations: false, showReservations: true }); this.filterClick('groups'); }}>Groups</Button>
                                        <Button style={{ whiteSpace: "nowrap" }} className='button' onClick={() => { this.setState({ showAllReservations: false, showReservations: true }); this.filterClick('reserved'); }}>Reserved</Button>
                                        <Button style={{ whiteSpace: "nowrap" }} className='button' onClick={() => { this.setState({ showReservations: false, showCheckedInReservations: true }); this.filterClick('checkedIn'); }}>Checked In</Button>
                                    </div>

                                    <Select
                                        value={this.state.searchType}
                                        onChange={(e) => this.setState({ searchType: e.target.value })}
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        <MenuItem value="id">Search By ID</MenuItem>
                                        <MenuItem value="name">Search By First Name</MenuItem>
                                    </Select>

                                    {this.state.searchType === 'id'
                                        ?
                                        <>
                                            <SearchBar
                                                className='searchBox'
                                                placeholder='Search'
                                                value={this.state.searchValue}
                                                onChange={(newValue) => this.setState({ searchValue: newValue })}
                                                onRequestSearch={() => this.getReservationsByReservationNumber(this.state.searchValue)}
                                                cancelOnEscape={true}
                                                onCancelSearch={() => this.displayAllReservations()}
                                            />
                                        </>
                                        :
                                        <>
                                            <SearchBar
                                                className='searchBox'
                                                placeholder='Search'
                                                value={this.state.searchValue}
                                                onChange={(newValue) => this.setState({ searchValue: newValue })}
                                                onRequestSearch={() => this.getReservationsByName(this.state.searchValue)}
                                                cancelOnEscape={true}
                                                onCancelSearch={() => this.displayAllReservations()}
                                            />
                                        </>
                                    }
                                </Box>
                            </ThemeProvider>
                            <Box className='reservationTableHeadingBox'>
                                <Typography className='headerTypo'>Reservation #</Typography>
                                <Typography className='headerTypo'>Customer Name</Typography>
                                <Typography className='headerTypo'>Rig Type</Typography>
                                <Typography className='headerTypo'>Start Date</Typography>
                                <Typography className='headerTypo'>End Date</Typography>
                                <Typography className='headerTypo'>Site</Typography>
                                <Typography className='headerTypo'>Amount Paid</Typography>
                                <Typography className='headerTypo'>Amount Due</Typography>
                                <Typography className='headerTypo'>Status</Typography>
                                <Typography className='headerTypo'>Actions</Typography>
                            </Box>
                            {!this.state.noReservations && this.state.allReservations.length > 0
                                ?
                                <>
                                    <Box className='tableBox'>
                                        <table>
                                            <tbody>
                                                {reservationRows}
                                            </tbody>
                                        </table>
                                    </Box>
                                    <div className="pagination">
                                        {previousButton}
                                        {paginationButtons}
                                        ...
                                        {lastButton}
                                        {nextButton}
                                    </div>
                                </>
                                : <Typography className='headerTypo' style={{ fontSize: '1.5rem' }}>No Reservations Found</Typography>
                            }

                        </>
                        : <View serverName={this.props.serverName} viewItem={this.state.viewItem} goBack={this.goBack} user={this.props.user} />
                    }
                </Grid>
            );
        } else if (this.state.reservations.length === 0 && this.state.allReservations != null) {
            return (
                <>
                    <Grid className='reservationList'>
                        <Box className='infoBox'>
                            <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                            <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                        </Box>

                        <Box className='box' style={{ marginTop: '-2%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={reservations} alt="Reservations" className='buttonImage' />
                                <Typography className='reservationstypography' style={{ marginLeft: '5%' }}>
                                    Reservations
                                </Typography>
                            </div>
                        </Box>
                        <Typography style={{ marginTop: '2%', fontSize: '1.5rem' }}> You do not have any ongoing or future reservations</Typography>
                    </Grid>
                </>
            );
        } else {
            return (
                <CircularProgress className={classes.circularProgress} />
            );
        }
    };
};

export default withStyles(useStyles)(Reservations);