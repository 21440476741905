import React, { Component } from 'react';
import { Grid, Button, Typography, Box } from '@material-ui/core';
import '../CSS/Settings.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import reports from '../assets/reports.png'
import {
    getArrivingTodayOrTomorrow, getCheckingOutTodatOrTomorrow, getInParkList, getSceduledArrivals,
    getScheduledCheckOuts, getReservationsReport, getPaymentsReport, getGroups, occupancyReport,
    getSiteRigDiscountExtra, extraReport
} from '../utils/OpenCampground_Database_Calls'
import ReportReservations from './ReportReservations';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs';
import { Select, MenuItem } from '@mui/material';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: '',
            date: '',
            data: [],
            message: '',
            headerMessage: '',
            date1: '',
            date2: '',
            formValid: false,
            showData: false,
            activeTab: "",
            selectMonth: false,
            allExtras: [],
            selectedExtra: "ALL"
            // subTotalType: 'noSubtotal',
            // monthlyTotal: {}
        };
    }

    setStateFunction = async (data, message, headerMessage, show) => {
        this.setState({
            data: data,
            message: message,
            headerMessage: headerMessage,
            showData: true,
            ...(show !== undefined && { show: show })
        });
    }

    arrivalsOrDepartures = async (type, show, day) => {
        if (type === 'scheduled') {
            let data, message, headerMessage;
            if (show === 'scheduledArrivals') {
                data = await getSceduledArrivals(this.props.serverName, this.state.date1, this.state.date2)
                message = "No arrivals from " + this.state.date1 + " to " + this.state.date2
                headerMessage = "Arrivals scheduled from " + this.state.date1 + " to " + this.state.date2
            } else {
                data = await getScheduledCheckOuts(this.props.serverName, this.state.date1, this.state.date2)
                message = "No departures from " + this.state.date1 + " to " + this.state.date2
                headerMessage = "Departures scheduled from " + this.state.date1 + " to " + this.state.date2
            }
            this.setStateFunction(data.body, message, headerMessage)
        } else {
            let tempDate = new Date();
            if (day === 'tomorrow') {
                tempDate.setDate(tempDate.getDate() + 1);
            }
            const date = tempDate.toLocaleDateString('en-CA');
            let data, message, headerMessage;
            if (type === 'arrivals') {
                data = await getArrivingTodayOrTomorrow(this.props.serverName, date);
                message = "No arrivals for " + date
                headerMessage = "Arrivals scheduled for " + date
            } else {
                data = await getCheckingOutTodatOrTomorrow(this.props.serverName, date);
                message = "No departures for " + date
                headerMessage = "Departures scheduled for " + date
            }
            this.setStateFunction(data.body, message, headerMessage, show)
        }
    }

    getInParkList = async (show) => {
        let getInParkListResponse = await getInParkList(this.props.serverName)
        this.setStateFunction(getInParkListResponse.body, "No customers in park", "Customers in park")
    }

    formValidation = () => {
        // if (this.state.show === 'extras') {

        // } else {

        // }
        if (this.state.date1 && this.state.date2) {
            this.setState({
                formValid: true
            })
        } else if (this.state.formValid) {
            this.setState({
                formValid: false
            })
        }
    }

    getReservations = async () => {
        let data = await getReservationsReport(this.props.serverName, this.state.date1, this.state.date2)
        let message = "No reservations were made between " + this.state.date1 + " to " + this.state.date2
        let headerMessage = "Reservations made between " + this.state.date1 + " to " + this.state.date2
        this.setStateFunction(data.body, message, headerMessage)
    }

    getPayments = async () => {
        let data = await getPaymentsReport(this.props.serverName, this.state.date1, this.state.date2);
        console.log(data)
        let message = "No payments were made between " + this.state.date1 + " to " + this.state.date2;
        let headerMessage = "Total payments of $" + data.body[0].grand_Total + " was made between " + this.state.date1 + " to " + this.state.date2;
        console.log(data)
        this.setStateFunction(data.body, message, headerMessage)
        // let monthlyTotals = {};
        // if (this.state.subTotalType === 'subtotalMonth') {
        //     const monthNames = [
        //         "January", "February", "March", "April", "May", "June",
        //         "July", "August", "September", "October", "November", "December"
        //     ];
        //     data.body.forEach(payment => {
        //         let paymentDate = payment.pmt_date;
        //         if (paymentDate >= this.state.date1 && paymentDate <= this.state.date2) {
        //             let paymentMonthName = monthNames[(paymentDate.split('-')[1].split('0')[1]) - 1] + '/' + paymentDate.split('-')[0].split('0')[1];
        //             if (!monthlyTotals[paymentMonthName]) {
        //                 monthlyTotals[paymentMonthName] = 0;
        //             }
        //             monthlyTotals[paymentMonthName] += payment.amount;
        //         }
        //     });
        // this.setState({
        //     monthlyTotal: monthlyTotals
        // })
        // }
    }

    getGroupData = async () => {
        let data = await getGroups(this.props.serverName)
        let message = "No Existing Groups"
        let headerMessage = "Groups"
        let show = 'groups'
        this.setStateFunction(data.body, message, headerMessage, show)
    }

    occupancyReport = async (type) => {
        let startDate
        let endDate
        if (type === "specificMonth") {
            // startDate = dayjs(`${this.state.month}-01`).startOf('month').format('YYYY-MM-DD');
            // endDate = dayjs(`${this.state.month}-01`).endOf('month').format('YYYY-MM-DD');
            startDate = this.state.date1
            endDate = this.state.date2
        } else if (type === "lastMonth") {
            const lastMonthDate = dayjs().subtract(1, 'month');
            startDate = lastMonthDate.startOf('month').format('YYYY-MM-DD');
            endDate = lastMonthDate.endOf('month').format('YYYY-MM-DD');
        } else if (type === "lastQuarter") {
            const lastQuarterStart = dayjs().subtract(3, 'month').startOf('month');
            const lastQuarterEnd = dayjs().subtract(1, 'month').endOf('month');

            startDate = lastQuarterStart.format('YYYY-MM-DD');
            endDate = lastQuarterEnd.format('YYYY-MM-DD');
        } else if (type === "lastYear") {
            const lastYearDate = dayjs().subtract(1, 'year');
            startDate = lastYearDate.startOf('year').format('YYYY-MM-DD');
            endDate = lastYearDate.endOf('year').format('YYYY-MM-DD');
        }
        let data = await occupancyReport(this.props.serverName, startDate, endDate)
        let message = "Occupancy Report"
        let headerMessage = "Occupancy Report"
        let show = 'occupancy'
        this.setStateFunction(data.body, message, headerMessage, show)
    }

    getExtra = async () => {
        let siteRigDiscountExtra = await getSiteRigDiscountExtra(this.props.serverName);
        this.setState({ allExtras: siteRigDiscountExtra[3] })
    }

    extraChange = (event) => {
        this.setState({
            selectedExtra: event.target.value,
        });
    };

    getExtraData = async () => {
        let extraId
        console.log(this.state.selectedExtra)
        if (this.state.selectedExtra === 'ALL') {
            extraId = 0
        } else {
            const selectedExtra = this.state.allExtras.find(extra => extra.name === this.state.selectedExtra);
            extraId = selectedExtra.id
        }
        let data = await extraReport(this.props.serverName, this.state.date1, this.state.date2, extraId)
        let message = "No extra's were added between " + this.state.date1 + " and " + this.state.date2
        let headerMessage = "Extra's between dates " + this.state.date1 + " and " + this.state.date2
        let show = 'extras'
        this.setStateFunction(data.body, message, headerMessage, show)
    }

    render() {
        return (
            <Grid className='settingsPage'>
                <Box className='infoBox' style={{ marginRight: '-1%' }}>
                    <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                    <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                </Box>

                <Box className='settingsBox'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={reports} alt="Settings" className='buttonImage' />
                        <Typography className='settingsTypo' style={{ marginLeft: '10%', whiteSpace: 'nowrap' }}>Custom Reports</Typography>
                    </div>
                </Box>

                <Box className='reportsList' style={{ marginRight: '-1%' }}>
                    <Box className='paymentOptionWrapper'>
                        <Box className={`paymentOption ${this.state.activeTab === "Arriving Today" ? "activePaymentOption" : ""}`} onClick={() => { this.arrivalsOrDepartures("arrivals", "arrivingToday", "today"); this.setState({ activeTab: "Arriving Today" }) }}>
                            Arriving Today
                        </Box>
                        <Box className={`paymentOption ${this.state.activeTab === "Arriving Tomorrow" ? "activePaymentOption" : ""}`}
                            onClick={() => { this.arrivalsOrDepartures("arrivals", "arrivingTomorrow", "tomorrow"); this.setState({ activeTab: "Arriving Tomorrow" }) }}>
                            Arriving Tomorrow
                        </Box>
                        <Box className={`paymentOption ${this.state.activeTab === "Scheduled Arrivals" ? "activePaymentOption" : ""}`}
                            onClick={() => { this.setState({ show: 'scheduledArrivals', showData: false, date1: '', date2: '', formValid: false, activeTab: "Scheduled Arrivals" }); }}>
                            Scheduled Arrivals
                        </Box>
                        <Box className={`paymentOption ${this.state.activeTab === "Checking Out Today" ? "activePaymentOption" : ""}`}
                            onClick={() => { this.arrivalsOrDepartures("departures", "departingToday", "today"); this.setState({ activeTab: "Checking Out Today" }) }}>
                            Checking Out Today
                        </Box>
                        <Box className={`paymentOption ${this.state.activeTab === "Checking Out Tomorrow" ? "activePaymentOption" : ""}`}
                            onClick={() => { this.arrivalsOrDepartures("departures", "departingTomorrow", "tomorrow"); this.setState({ activeTab: "Checking Out Tomorrow" }) }}>
                            Checking Out Tomorrow
                        </Box>
                        <Box className={`paymentOption ${this.state.activeTab === "Scheduled Checkout" ? "activePaymentOption" : ""}`}
                            onClick={() => { this.setState({ show: 'scheduledCheckout', showData: false, date1: '', date2: '', formValid: false, activeTab: "Scheduled Checkout" }); }}>
                            Scheduled Checkout
                        </Box>
                        <Box className={`paymentOption ${this.state.activeTab === "In Park List" ? "activePaymentOption" : ""}`}
                            onClick={() => { this.setState({ show: 'inParkList', activeTab: "In Park List", showData: false }); this.getInParkList("inParkList"); }}>
                            In Park List
                        </Box>
                        <Box className={`paymentOption ${this.state.activeTab === "Occupancy" ? "activePaymentOption" : ""}`}
                            onClick={() => { this.setState({ show: 'occupancy', activeTab: "Occupancy" }); }}>
                            Occupancy
                        </Box>
                        <Box className={`paymentOption ${this.state.activeTab === "Payments" ? "activePaymentOption" : ""}`}
                            onClick={() => { this.setState({ show: 'payments', showData: false, date1: '', date2: '', formValid: false, activeTab: "Payments" }); }}>
                            Payments
                        </Box>
                        <Box className={`paymentOption ${this.state.activeTab === "Reservations" ? "activePaymentOption" : ""}`}
                            onClick={() => { this.setState({ show: 'reservations', showData: false, date1: '', date2: '', formValid: false, activeTab: "Reservations" }); }}>
                            Reservations
                        </Box>
                        <Box className={`paymentOption ${this.state.activeTab === "Groups" ? "activePaymentOption" : ""}`}
                            onClick={() => { this.getGroupData(); this.setState({ activeTab: "Groups" }) }}>
                            Groups
                        </Box>
                        <Box className={`paymentOption ${this.state.activeTab === "Extras" ? "activePaymentOption" : ""}`}
                            onClick={() => { this.getExtra(); this.setState({ selectedExtra: 'ALL', show: 'extras', showData: false, date1: '', date2: '', formValid: false, activeTab: "Extras" }) }}>
                            Extras
                        </Box>
                    </Box>
                </Box>

                <Box className='viewBox' style={{ marginRight: '-2%' }}>
                    {this.state.show === 'arrivingToday' || this.state.show === 'arrivingTomorrow' || this.state.show === 'departingToday' ||
                        this.state.show === 'departingTomorrow' || this.state.show === 'inParkList'
                        ?
                        <>
                            <ReportReservations serverName={this.props.serverName} data={this.state.data} message={this.state.message} headerMessage={this.state.headerMessage} display={"9"} show={this.state.show} />
                        </>
                        : null
                    }

                    {this.state.show === 'scheduledArrivals' || this.state.show === 'scheduledCheckout' || this.state.show === 'reservations' || this.state.show === 'payments'
                        ?
                        <>
                            <ThemeProvider theme={theme}>
                                <Box style={{ display: 'flex', alignItems: 'center', padding: '1% 0 2% 0', justifyContent: 'flex-start', width: '93%' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            id='date1'
                                            label='Start Date*'
                                            value={this.state.date1 ? dayjs(this.state.date1) : null}
                                            onChange={(date) => {
                                                const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : '';
                                                this.setState({ date1: formattedDate }, () => {
                                                    this.formValidation();
                                                });
                                            }}
                                        />
                                    </LocalizationProvider>

                                    <Box style={{ width: '20px' }} />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            id='date2'
                                            label='End Date*'
                                            value={this.state.date2 ? dayjs(this.state.date2) : null}
                                            minDate={dayjs(this.state.date1).add(1, 'day')}
                                            onChange={(date) => {
                                                const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : '';
                                                this.setState({ date2: formattedDate }, () => {
                                                    this.formValidation();
                                                });
                                            }}
                                        />
                                    </LocalizationProvider>

                                    {this.state.show === 'scheduledArrivals' || this.state.show === 'scheduledCheckout'
                                        ?
                                        <Button style={{ textTransform: "capitalize", backgroundColor: '#2dcd7a', marginLeft: '3%', height: "40px", color: this.state.formValid ? "#000000" : "rgba(0, 0, 0, 0.26)" }}
                                            onClick={() => { this.arrivalsOrDepartures("scheduled", this.state.show) }} disabled={!this.state.formValid}>
                                            Generate Report
                                        </Button>
                                        : null
                                    }

                                    {this.state.show === 'reservations'
                                        ? <Button style={{ textTransform: "capitalize", backgroundColor: '#2dcd7a', marginLeft: '3%', height: "40px", color: this.state.formValid ? "#000000" : "rgba(0, 0, 0, 0.26)" }}
                                            onClick={() => { this.getReservations() }} disabled={!this.state.formValid}>
                                            Generate Report
                                        </Button>
                                        : null
                                    }

                                    <Box />
                                    {this.state.show === 'payments'
                                        ? <>
                                            {/* <Select
                                                value={this.state.subTotalType}
                                                onChange={(e) => this.setState({ subTotalType: e.target.value })}
                                            >
                                                <MenuItem value="noSubtotal">No Subtotal</MenuItem>
                                                <MenuItem value="subtotalMonth">Subtotal By Month</MenuItem>
                                            </Select> */}
                                            <Button style={{ textTransform: "capitalize", backgroundColor: '#2dcd7a', marginLeft: '3%', height: "40px", color: this.state.formValid ? "#000000" : "rgba(0, 0, 0, 0.26)" }}
                                                onClick={() => { this.getPayments() }} disabled={!this.state.formValid}>
                                                Generate Report
                                            </Button>
                                        </>
                                        : null
                                    }
                                </Box>

                                {this.state.showData && this.state.show !== 'payments'
                                    ?
                                    <>
                                        <ReportReservations serverName={this.props.serverName} data={this.state.data} message={this.state.message} headerMessage={this.state.headerMessage} display={"9"} show={this.state.show} />
                                    </>
                                    : null
                                }

                                {this.state.showData && this.state.show === 'payments'
                                    ?
                                    <>
                                        <ReportReservations serverName={this.props.serverName} data={this.state.data} message={this.state.message} headerMessage={this.state.headerMessage} display={"8"} monthlyTotal={this.state.monthlyTotal} show={this.state.show} />
                                    </>
                                    : null
                                }
                            </ThemeProvider>
                        </>
                        : null
                    }

                    {this.state.show === 'groups'
                        ?
                        <>
                            <ReportReservations serverName={this.props.serverName} data={this.state.data} message={this.state.message} headerMessage={this.state.headerMessage} display={"6"} show={this.state.show} />
                        </>
                        : null
                    }

                    {this.state.show === 'occupancy'
                        ?
                        <>
                            <ThemeProvider theme={theme}>
                                <Box style={{ display: 'flex', alignItems: 'center', padding: '1% 0 2% 0', justifyContent: 'flex-start', width: '93%' }}>
                                    {this.state.selectMonth
                                        ?
                                        <>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    id='date1'
                                                    label='Start Date*'
                                                    value={this.state.date1 ? dayjs(this.state.date1) : null}
                                                    onChange={(date) => {
                                                        const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : '';
                                                        this.setState({ date1: formattedDate }, () => {
                                                            this.formValidation();
                                                        });
                                                    }}
                                                />
                                            </LocalizationProvider>

                                            <Box style={{ width: '20px' }} />
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    id='date2'
                                                    label='End Date*'
                                                    value={this.state.date2 ? dayjs(this.state.date2) : null}
                                                    minDate={dayjs(this.state.date1).add(1, 'day')}
                                                    onChange={(date) => {
                                                        const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : '';
                                                        this.setState({ date2: formattedDate }, () => {
                                                            this.formValidation();
                                                        });
                                                    }}
                                                />
                                            </LocalizationProvider>

                                            <Box style={{ width: '20px' }} />
                                            <Button style={{ textTransform: "capitalize", backgroundColor: '#2dcd7a', marginLeft: '3%', height: "40px", color: this.state.formValid ? "#000000" : "rgba(0, 0, 0, 0.26)" }}
                                                onClick={() => { this.occupancyReport("specificMonth") }} disabled={!this.state.formValid}>
                                                Generate Report
                                            </Button>
                                        </>
                                        :
                                        <>
                                            <Button style={{ textTransform: "capitalize", backgroundColor: '#2dcd7a', marginLeft: '-1.5%', height: "40px", color: "#000000" }}
                                                onClick={() => { this.occupancyReport("lastMonth") }} >
                                                Last Month
                                            </Button>

                                            <Box style={{ width: '20px' }} />
                                            <Button style={{ textTransform: "capitalize", backgroundColor: '#2dcd7a', marginLeft: '3%', height: "40px", color: "#000000" }}
                                                onClick={() => { this.occupancyReport("lastQuarter") }} >
                                                Last 3 Months
                                            </Button>

                                            <Box style={{ width: '20px' }} />
                                            <Button style={{ textTransform: "capitalize", backgroundColor: '#2dcd7a', marginLeft: '3%', height: "40px", color: "#000000" }}
                                                onClick={() => { this.occupancyReport("lastYear") }} >
                                                Last Year
                                            </Button>

                                            <Box style={{ width: '20px' }} />
                                            <Button style={{ textTransform: "capitalize", backgroundColor: '#2dcd7a', marginLeft: '3%', height: "40px", color: "#000000" }}
                                                onClick={() => this.setState({ selectMonth: true })} >
                                                Select Dates
                                            </Button>
                                        </>
                                    }
                                </Box>
                                {this.state.showData
                                    ?
                                    <>
                                        <ReportReservations serverName={this.props.serverName} data={this.state.data} message={this.state.message} headerMessage={this.state.headerMessage} display={"5"} show={this.state.show} />
                                    </>
                                    : null
                                }
                            </ThemeProvider>
                        </>
                        : null
                    }

                    {this.state.show === 'extras'
                        ?
                        <>
                            <ThemeProvider theme={theme}>
                                {this.state.allExtras.length > 0
                                    ?
                                    <>
                                        <Box style={{ display: 'flex', alignItems: 'center', padding: '1% 0 2% 0', justifyContent: 'flex-start', width: '93%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    id='date1'
                                                    label='Start Date*'
                                                    value={this.state.date1 ? dayjs(this.state.date1) : null}
                                                    onChange={(date) => {
                                                        const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : '';
                                                        this.setState({ date1: formattedDate }, () => {
                                                            this.formValidation();
                                                        });
                                                    }}
                                                />
                                            </LocalizationProvider>

                                            <Box style={{ width: '20px' }} />
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    id='date2'
                                                    label='End Date*'
                                                    value={this.state.date2 ? dayjs(this.state.date2) : null}
                                                    minDate={dayjs(this.state.date1).add(1, 'day')}
                                                    onChange={(date) => {
                                                        const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : '';
                                                        this.setState({ date2: formattedDate }, () => {
                                                            this.formValidation();
                                                        });
                                                    }}
                                                />
                                            </LocalizationProvider>

                                            <Box style={{ width: '20px' }} />
                                            <FormControl style={{ width: '150px' }}>
                                                <InputLabel>Extras</InputLabel>
                                                <Select
                                                    labelId="extra"
                                                    id="selectedExtra"
                                                    value={this.state.selectedExtra}
                                                    label="Extras"
                                                    onChange={this.extraChange}
                                                >
                                                    <MenuItem value="ALL">All</MenuItem>
                                                    {this.state.allExtras.map((extra, index) => (
                                                        <MenuItem key={index} value={extra.name}>
                                                            {extra.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <Box />
                                            <Button style={{ textTransform: "capitalize", backgroundColor: '#2dcd7a', marginLeft: '3%', height: "40px", color: this.state.formValid ? "#000000" : "rgba(0, 0, 0, 0.26)" }}
                                                onClick={() => { this.getExtraData() }} disabled={!this.state.formValid}>
                                                Generate Report
                                            </Button>
                                        </Box>
                                    </>
                                    : null
                                }
                                {this.state.showData
                                    ?
                                    <>
                                        <ReportReservations serverName={this.props.serverName} data={this.state.data} message={this.state.message} headerMessage={this.state.headerMessage} display={"7"} show={this.state.show} />
                                    </>
                                    : null
                                }
                            </ThemeProvider>
                        </>
                        : null
                    }
                </Box>
            </Grid >
        );
    };
};

export default Reports;
