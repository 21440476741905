import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import '../CSS/NewReservation.css';
import {
    getTaxes, makeReservation, getCardType, recordPayment, cardConnectPaymemt,
    addVariableCharge, addExtras, getIntegrations, getEmailTemplates, sendMail, getEmails,
    getCampers, cardConnectGetListOfTerminals, cardConnectConnectToTerminal, cardConnectTerminalPayment
} from '../utils/OpenCampground_Database_Calls'
import { makePayment } from '../utils/MxMerchant_Services'
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { format } from 'date-fns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import reservationConfirmation from '../assets/reservation_confirm.png'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import GroupIcon from '@mui/icons-material/Group';
import PetsIcon from '@mui/icons-material/Pets';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import TodayIcon from '@mui/icons-material/Today';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Autocomplete, createFilterOptions } from '@mui/material';

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    }
});

class NewReservationUserDetails extends Component {
    constructor(props) {
        super(props);
        this.addExtra = this.addExtra.bind(this);
        this.state = {
            userDetails: true,

            //userDetails
            fname: '',
            lname: '',
            emailId: '',
            phoneNumber: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            counts: [],
            guests: [],
            internalNotes: '',

            //pricing
            days: '',
            weeks: '',
            months: '',
            daysTotal: '',
            weeksTotal: '',
            monthsTotal: '',
            subTotal: '',
            taxes: [],
            totalAmount: '',
            tax: [],
            taxPercent: [],
            taxName: [],
            convenienceCharge: null,
            baseTotal: 0.0,
            selectedExtras: [],
            selectedExtraAmount: [],
            selectedExtraCount: [],
            extraId: [],
            extraSubTotal: 0,
            selectedPaymentValue: '',
            partialTotal: 0.0,
            balance: 0.0,
            partialBase: '',
            cardNumber: '',
            cardOwner: '',
            expiryMonth: '',
            expiryYear: '',
            cvv: '',
            convenienceFees: '2.95',
            ccPOSAmount: '',

            //addOns
            start: '',
            end: '',
            type2Add: false,

            //popup
            open: false,
            popupText: '',
            open1: false,
            popupText1: '',

            //paymentIntegrations
            paymentIntegration: [],

            //email
            remoteResReceivedMailMessage: '',
            remoteReservationReceivedSubject: '',
            sender: '',
            reply: '',
            cc: '',
            bcc: '',
            cashDate: format(new Date(), 'MM/dd/yyyy'),
            cashAmount: '',
            checkAmount: '',
            checkNumber: '',
            memo: '',
            checkDate: format(new Date(), 'MM/dd/yyyy'),
            discountTotal: null,
            oneTimeDiscount: 0.00,
            originalSubTotal: '',
            camperId: null,

        };
    }

    async componentDidMount() {
        this.getGuests()
        let paymentIntegration = await getIntegrations(this.props.serverName)
        let mailMessages = await getEmailTemplates(this.props.serverName)
        let email = await getEmails(this.props.serverName)
        this.setState({
            paymentIntegration: paymentIntegration
        })
        //calculate total
        let term = this.getDuration(this.props.checkIn, this.props.checkOut);
        let subTotal = 0.0;
        let monthsTotal = 0;
        let weeksTotal = 0;
        let daysTotal = 0;
        let discountTotal = null;
        if (term[2] > 0) {
            if (this.props.monthly > 0) {
                monthsTotal = (term[2] * this.props.monthly);
                subTotal += monthsTotal;
            } else {
                term[1] = term[1] + (term[2] * 4);
                term[2] = 0
            }
        }
        if (term[1] > 0) {
            if (this.props.weekly > 0) {
                weeksTotal = (term[1] * this.props.weekly);
                subTotal += weeksTotal;
            } else {
                term[0] = term[0] + (term[1] * 7);
                term[1] = 0
            }
        }
        if (term[0] > 0) {
            daysTotal = (term[0] * this.props.daily);
            subTotal += daysTotal;
        }
        console.log("Subtotal: ", subTotal)
        if (this.props.discount != null) {
            if (monthsTotal > 0 && this.props.monthlyDiscount) {
                discountTotal += (monthsTotal * this.props.discountPercent) / 100
            }
            if (weeksTotal > 0 && this.props.weeklyDiscount) {
                discountTotal += (weeksTotal * this.props.discountPercent) / 100
            }
            if (daysTotal > 0 && this.props.dailyDiscount) {
                discountTotal += (daysTotal * this.props.discountPercent) / 100
            }
            subTotal -= discountTotal
        }
        console.log("Discounted Value: ", discountTotal)
        this.calculateTaxAndTotal(subTotal, term);
        this.setState({
            days: term[0],
            weeks: term[1],
            months: term[2],
            subTotal: subTotal,
            originalSubTotal: subTotal,
            monthsTotal: monthsTotal,
            weeksTotal: weeksTotal,
            daysTotal: daysTotal,
            paymentIntegration: paymentIntegration,
            selectedPaymentValue: paymentIntegration.name === 'None' ? 'cash' : 'fullPayment',
            remoteResReceivedMailMessage: mailMessages[3].body,
            remoteReservationReceivedSubject: email.remote_res_subject,
            sender: email.sender,
            reply: email.reply,
            cc: email.cc,
            bcc: email.bcc,
            discountTotal: discountTotal
        })
    }

    getGuests = async () => {
        try {
            let listOfGuests = await getCampers(this.props.serverName);
            this.setState({
                guests: listOfGuests
            });
        } catch (error) {
            console.error("Error fetching guests:", error);
        }

    }

    getDuration(checkIn, checkOut) {
        const msPerDay = 24 * 60 * 60 * 1000;
        const msPerWeek = msPerDay * 7;

        const startDate = new Date(checkIn);
        const endDate = new Date(checkOut);

        let totalDays = Math.floor((endDate - startDate) / msPerDay);

        const months = Math.floor(totalDays / 30);
        totalDays %= 30;

        const weeks = Math.floor(totalDays / 7);
        totalDays %= 7;

        const days = totalDays;

        return [days, weeks, months];
    }

    calculateTaxAndTotal = async (subTotal, term) => {
        let taxes = await getTaxes(this.props.serverName);
        let totalAmount = 0.0;
        let tax = [];
        let taxPercent = [];
        let taxName = [];
        let convenienceFees = 0.0;
        let baseTotal = 0.0
        //taxes
        for (let index = 0; index < taxes.length; index++) {
            const element = taxes[index];
            if (element.is_percent === 1) {
                if (element.apl_daily === 0 && element.apl_week === 0 && element.apl_month === 0) {
                    console.log("No tax")
                } else if (element.apl_daily === 1) {
                    taxPercent[index] = element.percent;
                    tax[index] = (subTotal * (element.percent / 100)).toFixed(2);
                    taxName[index] = element.name;
                }
            } else {
                let totalDays = term[0];
                if (element.apl_month === 1) {
                    if (element.monthly_charge_daily === 1) {
                        totalDays = totalDays + (term[2] * 30)
                    } else {
                        taxPercent[index] = element.amount;
                        tax[index] = (term[2] * element.amount)
                        taxName[index] = element.name;
                    }
                } else if (element.apl_week === 1) {
                    if (element.weekly_charge_daily === 1) {
                        totalDays = totalDays + (term[1] * 7)
                    } else {
                        taxPercent[index] = element.amount;
                        tax[index] = (term[1] * element.amount)
                        taxName[index] = element.name;
                    }
                }
                taxPercent[index] = element.amount;
                tax[index] = (totalDays * element.amount)
                taxName[index] = element.name;

            }
        }

        totalAmount = subTotal;
        for (let index = 0; index < tax.length; index++) {
            totalAmount = totalAmount + parseFloat(tax[index]);
        }
        baseTotal = totalAmount
        if (this.state.paymentIntegration.name === 'CampPay' && this.state.selectedPaymentValue === 'fullPayment') {
            convenienceFees = (totalAmount.toFixed(2) * this.state.convenienceFees / 100);
        }

        this.setState({
            taxes: taxes,
            totalAmount: totalAmount.toFixed(2),
            tax: tax,
            taxPercent: taxPercent,
            taxName: taxName,
            convenienceCharge: this.state.paymentIntegration.name === 'CampPay' ? convenienceFees.toFixed(2) : null,
            baseTotal: baseTotal.toFixed(2)
        })
    }

    userDetailsChange = (event) => {
        if (event.target.id === 'phoneNumber') {
            const numericValue = event.target.value.replace(/[^0-9]/g, '');
            this.setState({
                [event.target.id]: numericValue
            }, () => {
                this.userDetailsValidation();
            });
        } else {
            this.setState({
                [event.target.id]: event.target.value
            }, () => {
                this.userDetailsValidation();
            })
        }
    }

    getDays = () => {
        const msPerDay = 24 * 60 * 60 * 1000;
        const startDate = new Date(this.props.checkIn);
        const endDate = new Date(this.props.checkOut);
        let totalDays = Math.floor((endDate - startDate) / msPerDay);
        return totalDays;
    }

    addExtra = (extra, count) => {

        console.log("inside")
        let extraTotal = 0;
        let totalDays = this.getDays();
        let duration = this.getDuration(this.props.checkIn, this.props.checkOut)
        if (extra.extra_type === 0) {
            let tempTotal = 0
            if (duration[2] > 0) {
                tempTotal = tempTotal + (duration[2] * extra.monthly)
            }
            if (duration[1] > 0) {
                tempTotal = tempTotal + (duration[1] * extra.weekly)
            }
            if (duration[0]) {
                tempTotal = tempTotal + (duration[0] * extra.daily);
            }
            extraTotal = this.state.subTotal + tempTotal
            this.calculateTaxAndTotal(extraTotal, duration);
            this.setState({
                subTotal: extraTotal,
                selectedExtras: [...this.state.selectedExtras, extra.name],
                selectedExtraCount: [...this.state.selectedExtraCount, totalDays],
                selectedExtraAmount: [...this.state.selectedExtraAmount, tempTotal],
                extraSubTotal: this.state.extraSubTotal + tempTotal,
                extraId: [...this.state.extraId, extra.id]
            })
        } else if (extra.extra_type === 1) {
            extraTotal = this.state.subTotal + (count * totalDays * extra.daily)
            this.calculateTaxAndTotal(extraTotal, duration);
            this.setState({
                subTotal: extraTotal,
                selectedExtras: [...this.state.selectedExtras, extra.name],
                selectedExtraCount: [...this.state.selectedExtraCount, count * totalDays],
                selectedExtraAmount: [...this.state.selectedExtraAmount, count * totalDays * extra.daily],
                extraSubTotal: this.state.extraSubTotal + count * totalDays * extra.daily,
                extraId: [...this.state.extraId, extra.id]
            })
        } else if (extra.extra_type === 2) {
            extraTotal = this.state.subTotal + ((this.state.end - this.state.start) * extra.rate)
            this.calculateTaxAndTotal(extraTotal, duration);
            this.setState({
                subTotal: extraTotal,
                selectedExtras: [...this.state.selectedExtras, extra.name],
                selectedExtraCount: [...this.state.selectedExtraCount, (this.state.end - this.state.start)],
                selectedExtraAmount: [...this.state.selectedExtraAmount, ((this.state.end - this.state.start) * extra.rate)],
                extraSubTotal: this.state.extraSubTotal + ((this.state.end - this.state.start) * extra.rate),
                extraId: [...this.state.extraId, extra.id]
            })
        } else {
            extraTotal = this.state.subTotal + (count * extra.charge)
            this.calculateTaxAndTotal(extraTotal, duration);
            this.setState({
                subTotal: extraTotal,
                selectedExtras: [...this.state.selectedExtras, extra.name],
                selectedExtraCount: [...this.state.selectedExtraCount, count],
                selectedExtraAmount: [...this.state.selectedExtraAmount, count * extra.charge],
                extraSubTotal: this.state.extraSubTotal + count * extra.charge,
                extraId: [...this.state.extraId, extra.id]
            })
        }
    }

    handleCountChange = (index, event) => {
        const newCounts = { ...this.state.counts, [index]: event.target.value };
        this.setState({ counts: newCounts });
    };

    handlePaymentSelect = (value) => {
        let convenienceFees = 0.0
        let partialTotal = 0.0
        let balance = 0.0
        let totalAmount = 0.0
        if (value === 'checkoutPayment') {
            this.setState({
                // totalAmount: this.state.totalAmount - this.state.convenienceCharge,
                totalAmount: this.state.totalAmount,
                convenienceCharge: null,
                selectedPaymentValue: value
            })
        } else if (value === 'partialPayment') {
            convenienceFees = (this.state.baseTotal * this.state.convenienceFees / 100);
            partialTotal = parseFloat(this.state.baseTotal) + parseFloat(convenienceFees.toFixed(2))
            balance = 0.0
            this.setState({
                partialBase: this.state.baseTotal,
                convenienceCharge: this.state.paymentIntegration.name === 'CampPay' ? convenienceFees.toFixed(2) : null,
                partialTotal: this.state.paymentIntegration.name === 'CampPay' ? partialTotal.toFixed(2) : parseFloat(this.state.baseTotal),
                // balance: balance.toFixed(2),
                selectedPaymentValue: value
            })
        } else if (value === 'fullPayment') {
            convenienceFees = (this.state.baseTotal * this.state.convenienceFees / 100);
            totalAmount = parseFloat(this.state.baseTotal)
            this.setState({
                convenienceCharge: this.state.paymentIntegration.name === 'CampPay' ? convenienceFees.toFixed(2) : null,
                totalAmount: parseFloat(this.state.baseTotal),
                selectedPaymentValue: value
            })
        } else if (value === 'cash' || value === 'check') {
            this.setState({
                totalAmount: parseFloat(this.state.baseTotal),
                convenienceCharge: null,
                selectedPaymentValue: value
            })
        } else if (value === 'ccPOS') {
            convenienceFees = (this.state.baseTotal * this.state.convenienceFees / 100);
            totalAmount = parseFloat(this.state.baseTotal) + parseFloat(convenienceFees.toFixed(2))
            this.setState({
                convenienceCharge: null,
                totalAmount: parseFloat(this.state.baseTotal),
                ccPOSAmount: (parseFloat(this.state.baseTotal)),
                selectedPaymentValue: value
            })
        }
    }

    amountChange = (event) => {
        if (this.state.selectedPaymentValue === 'check' || this.state.selectedPaymentValue === 'cash' || this.state.selectedPaymentValue === 'ccPOS') {
            this.setState({
                [event.target.id]: event.target.value
            })
        } else {
            let convenience = 0.0
            let totalPartialAmount = 0.0
            convenience = (event.target.value * this.state.convenienceFees / 100)
            totalPartialAmount = parseFloat(event.target.value) + parseFloat(convenience.toFixed(2))
            this.setState({
                partialTotal: totalPartialAmount.toFixed(2),
                convenienceCharge: this.state.paymentIntegration.name === 'CampPay' ? convenience.toFixed(2) : null,
                partialBase: event.target.value
            })
        }
    }

    handlePaymentDetailsChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        }, () => {
            this.userDetailsValidation();
        })
    }

    userDetailsValidation = () => {
        console.log("selected camper id is: ", this.state.camperId)
        if (this.state.fname && this.state.lname && this.state.emailId && this.state.phoneNumber && this.state.address && this.state.city &&
            this.state.state && this.state.zip
            // && this.state.cardNumber.length == 16 && this.state.cardOwner && this.state.expiryMonth.length == 2
            // && this.state.expiryYear.length == 2 && (this.state.cvv.length >= 3 && this.state.cvv.length <= 4)
        ) {
            this.setState({
                userDetailsFormValid: true
            })
        } else if (this.state.formValid) {
            this.setState({
                userDetailsFormValid: false
            })
        }
    }

    pay = async () => {
        this.setState({
            userDetailsFormValid: false
        })
        let amount;
        if (this.state.selectedPaymentValue === 'fullPayment' || this.state.selectedPaymentValue === 'partialPayment') {
            amount = this.state.totalAmount
            if (this.state.selectedPaymentValue === 'fullPayment') {
                amount = this.state.totalAmount
            } else {
                amount = this.state.partialTotal
            }

            if (this.state.paymentIntegration.name === 'CampPay') {
                let payment = await makePayment('1000017768', amount, this.state.cardNumber, this.state.expiryMonth, this.state.expiryYear, this.state.cvv, this.state.zip, this.state.address)
                if (payment.status === 'Approved') {
                    let reservation = await this.makeReservation(payment.amount)
                    console.log("reservation: ", reservation)
                    console.log("reservation.insertId: ", reservation.insertId)
                    let cardId = await getCardType(payment.cardAccount.cardType)
                    let paymentRecords = await recordPayment(this.props.serverName, payment.amount, reservation.insertId, payment.created, payment.created,
                        payment.reference, cardId, payment.cardAccount.last4, this.state.cardOwner, format(new Date(), 'yyyy-MM-dd'), 1, payment.cardAccount.token
                    )
                    let variableChargeResponse = await addVariableCharge(this.props.serverName, reservation.insertId, "Service Fees", this.state.convenienceCharge, new Date(), new Date())
                    let aaddExtra = this.addExtraToDatabase(reservation.insertId)
                    this.sendReservationConfirmationMail(reservation.insertId)
                } else {
                    this.setState({
                        open: true,
                        popupText: 'OpenWorldPay Payment Declined. Please try again'
                    })
                }
            } else {
                amount = parseFloat(amount).toFixed(2);
                let paymentResponse = await cardConnectPaymemt('177000000077', amount, this.state.cardNumber, this.state.expiryMonth, this.state.expiryYear, this.state.cvv, this.state.zip, this.state.address, this.state.fname, this.state.city)
                console.log("payment: ", paymentResponse)
                if (paymentResponse.respstat === "A") {
                    let reservation = await this.makeReservation(amount)
                    //replace hardcoded 2 with cardId
                    // let cardId = await getCardType(paymentResponse.binInfo.product)
                    let paymentRecords = await recordPayment(this.props.serverName, amount, reservation.insertId, format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd'),
                        paymentResponse.retref, "2", paymentResponse.account.slice(-4), this.state.cardOwner, format(new Date(), 'yyyy-MM-dd'), 1, paymentResponse.token)
                    let aaddExtra = this.addExtraToDatabase(reservation.insertId)
                    this.sendReservationConfirmationMail(reservation.insertId)
                } else {
                    this.setState({
                        open: true,
                        popupText: 'CardConnect Payment Declined. Please try again'
                    })
                }
            }
        } else if (this.state.selectedPaymentValue === 'ccPOS') {
            this.setState({
                open1: true,
                popupText1: 'POS Terminal Payment In Progress... Please Do Not Close or Refersh The Tab'
            })
            let listOfTerminals = await cardConnectGetListOfTerminals('177000000077')
            let connectToTerminalResponse = await cardConnectConnectToTerminal('177000000077', listOfTerminals.terminals[0])
            let popupText
            if (connectToTerminalResponse.status === 200) {
                let paymentResponse = await cardConnectTerminalPayment('177000000077', listOfTerminals.terminals[0], this.state.ccPOSAmount * 100, connectToTerminalResponse.SessionKey)
                if (paymentResponse.errorCode === 8) {
                    popupText = 'Payment Cancelled By User'
                } else if (paymentResponse.resptext === 'Approval') {
                    let reservation = await this.makeReservation(this.state.ccPOSAmount)
                    //hard coded 2 for cardId after reference number
                    await recordPayment(this.props.serverName, this.state.ccPOSAmount, reservation.insertId, format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd'),
                        "POS Terminal: " + paymentResponse.retref, "2", "", "", format(new Date(), 'yyyy-MM-dd'), 1)
                    let aaddExtra = this.addExtraToDatabase(reservation.insertId)
                    this.sendReservationConfirmationMail(reservation.insertId)
                } else {
                    popupText = 'POS Terminal Payment Declined. Please try again'
                }
            } else {
                popupText = 'Cannot Connect to POS terminal. Please try again'
            }
            this.setState({
                open1: false,
                open: true,
                popupText: popupText
            })
        } else if (this.state.selectedPaymentValue === 'cash') {
            let reservation = await this.makeReservation(this.state.cashAmount)
            let paymentRecords = await recordPayment(this.props.serverName, this.state.cashAmount, reservation.insertId, format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd'),
                "Cash Payment: ", "1", "", "", this.state.cashDate.format('YYYY-MM-DD'), 0)
            let aaddExtra = this.addExtraToDatabase(reservation.insertId)
            this.sendReservationConfirmationMail(reservation.insertId)
        } else if (this.state.selectedPaymentValue === 'check') {
            let reservation = await this.makeReservation(this.state.checkAmount)
            let paymentRecords = await recordPayment(this.props.serverName, this.state.checkAmount, reservation.insertId, format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd'),
                "Check Payment: " + this.state.memo, "2", "", "", this.state.checkDate.format('YYYY-MM-DD'), 0)
            let aaddExtra = this.addExtraToDatabase(reservation.insertId)
            this.sendReservationConfirmationMail(reservation.insertId)
        } else {
            let reservation = await this.makeReservation("")
            let aaddExtra = this.addExtraToDatabase(reservation.insertId)
            this.sendReservationConfirmationMail(reservation.insertId)
        }
    }

    makeReservation = async (amountPaid) => {
        let rigId;
        let taxtotal = 0.0;
        //rig
        for (let index = 0; index < this.props.rigs.length; index++) {
            if (this.props.rigType === this.props.rigs[index].name) {
                rigId = this.props.rigs[index].id
            }
        }
        //tax
        for (let index = 0; index < this.state.tax.length; index++) {
            taxtotal = taxtotal + parseFloat(this.state.tax[index]);
        }
        console.log(this.state.camperId)
        let reservation = await makeReservation(this.props.serverName, this.state.lname, this.state.fname, this.state.address, this.state.city, this.state.state,
            this.state.zip, this.state.phoneNumber, this.state.emailId, rigId, this.props.siteId, this.props.discountId, this.props.checkIn, this.props.checkOut, amountPaid, //payment.amount
            this.props.slides, this.props.length, this.props.guests, this.props.pets, (this.state.totalAmount - taxtotal), format(new Date(), 'yyyy-MM-dd'), taxtotal, 0, this.state.internalNotes,
            this.state.oneTimeDiscount, this.state.camperId
        );
        return reservation
    }

    addExtraToDatabase = async (reservationId) => {
        if (this.state.selectedExtras.length > 0) {
            let days = this.getDays()
            for (let index = 0; index < this.state.selectedExtras.length; index++) {
                let extraResponse = await addExtras(this.props.serverName, this.state.extraId[index], reservationId, this.state.counts[index], days, this.state.selectedExtraAmount[index], format(new Date(), 'yyyy-MM-dd'))
            }
        }
        return
    }

    sendReservationConfirmationMail = async (reservationId) => {
        let mailmessage = this.replaceMailTemplatePlaceHolders()
        let mailResponse = await sendMail(this.props.serverName, this.state.sender, /*this.state.emailId*/"dan@opencampground.com", this.state.remoteReservationReceivedSubject, mailmessage, this.state.reply, this.state.cc, this.state.bcc)
        this.setState({
            open: true,
            popupText: 'Reservation Created with Reservation ID: ' + reservationId
        })
    }

    replaceMailTemplatePlaceHolders = () => {
        let mailMessage = this.state.remoteResReceivedMailMessage
        mailMessage = mailMessage.replace('{{camper}}', this.state.fname + ' ' + this.state.lname);
        return mailMessage
    }

    handleStartEnd = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        }, () => {
            this.validateAddOns();
        })
    }

    validateAddOns = () => {
        if (this.state.start > 0 && this.state.end > 0 && this.state.end > this.state.start) {
            this.setState({
                type2Add: true
            })
        } else if (this.state.type2Add) {
            this.setState({
                type2Add: false
            })
        }
    }

    deleteExtra = async (index) => {
        console.log("extra id: ", this.state.extraId[index])
        let term = this.getDuration(this.props.checkIn, this.props.checkOut)
        this.calculateTaxAndTotal(this.state.subTotal - this.state.selectedExtraAmount[index], term);
        let array1 = [...this.state.selectedExtras];
        let array2 = [...this.state.selectedExtraCount];
        let array3 = [...this.state.selectedExtraAmount];
        array1.splice(index, 1);
        array2.splice(index, 1);
        array3.splice(index, 1);
        this.setState({
            selectedExtras: array1,
            selectedExtraCount: array2,
            selectedExtraAmount: array3,
            subTotal: this.state.subTotal - this.state.selectedExtraAmount[index]
        });
    }

    handleCancel = () => {
        this.setState({ open: false }, () => {
            this.props.history.replace(`/manager?serverName=${this.props.serverName}&view=reservations`);
        });
    };

    addOneTimeDiscount = (event) => {
        if (event.target.value == "") {
            this.setState({
                oneTimeDiscount: event.target.value,
            })
        } else {
            let oneTimeDiscount = parseFloat(event.target.value)
            let newSubtotal = this.state.originalSubTotal - oneTimeDiscount
            this.calculateTaxAndTotal(newSubtotal, this.getDuration(this.props.checkIn, this.props.checkOut));
            this.setState({
                oneTimeDiscount: oneTimeDiscount,
                subTotal: newSubtotal
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid className='reservationList' style={{ width: '100%' }}>
                <ThemeProvider theme={theme} classes={{ paper: classes.dialogPaper }}>
                    <Dialog open={this.state.open} onClose={this.handleCancel}>
                        <DialogTitle>Popup Title</DialogTitle>
                        <DialogContent>
                            {this.state.popupText}
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={this.handleCancel} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </ThemeProvider>

                {this.state.userDetails
                    ?
                    <>
                        <Box className='scrollContainerUserDetails'>
                            <Box className='box'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={reservationConfirmation} alt="Reservations" style={{ height: '40px' }} />
                                    <Typography className='reservationstypography'>Confirm Reservation</Typography>
                                </div>
                                <div>
                                    <Button startIcon={<ArrowBackIcon />} className='cancel' style={{ marginRight: '2%', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }} onClick={() => {
                                        this.props.backToSelectSpace();
                                    }}>Select Space</Button>
                                    <Button className='cancel' style={{ marginRight: '-2%' }} onClick={() => {
                                        this.props.history.replace(`/manager?serverName=${this.props.serverName}&view=reservations`)
                                    }}>Cancel Reservation</Button>
                                </div>
                            </Box>
                            <Box className='main'>
                                <Box className='leftRow'>
                                    <Box className='userDetails'>
                                        <ThemeProvider theme={theme}>
                                            <Box style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                                <Typography className='typo'>Guest Details</Typography>
                                                <Box style={{ marginRight: "1%", padding: "4% 3% 0 0" }}>
                                                    <Autocomplete
                                                        disablePortal
                                                        loading={this.state?.guests?.length == 0}
                                                        filterOptions={createFilterOptions({
                                                            ignoreCase: true,
                                                            matchFrom: "start",
                                                        })}
                                                        onChange={(_, value) => {
                                                            if (value) {
                                                                this.setState({
                                                                    fname: value?.first_name,
                                                                    lname: value?.last_name,
                                                                    emailId: value?.email,
                                                                    phoneNumber: value?.phone,
                                                                    address: value?.address,
                                                                    city: value?.city,
                                                                    state: value?.state,
                                                                    zip: value?.mail_code,
                                                                    camperId: value?.id,
                                                                }, () => {
                                                                    this.userDetailsValidation();
                                                                })
                                                            }

                                                        }}
                                                        options={this.state?.guests && this.state?.guests?.length > 0 ? this.state?.guests.map((i) => ({ ...i, label: i.first_name + " " + i.last_name, key: i.id })) : []}
                                                        sx={{ width: 300 }}
                                                        renderInput={(params) => <TextField {...params} label="Search Guest" />}
                                                    />
                                                </Box>
                                            </Box>
                                            <hr className='horizontalLine' />
                                            <div className='form-row'>
                                                <TextField id="fname" required label="First Name" type="text" className='field' value={this.state.fname} onChange={this.userDetailsChange} />
                                                <TextField id="lname" required label="Last Name" type="text" className='field' value={this.state.lname} onChange={this.userDetailsChange} />
                                            </div>

                                            <div className='form-row'>
                                                <TextField id="emailId" required label="Email Id" type="email" className='field' value={this.state.emailId} onChange={this.userDetailsChange} />
                                                <TextField id="phoneNumber" required label="Phone Number" type="text" className='field' value={this.state.phoneNumber} onChange={this.userDetailsChange}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                />
                                            </div>

                                            <div className='form-row-2'>
                                                <TextField id="address" required label="Address" type="text" className='full-width' value={this.state.address} onChange={this.userDetailsChange} />
                                            </div>
                                            <div className='form-row-3'>
                                                <TextField id="city" required label="City" type="text" className='left' value={this.state.city} onChange={this.userDetailsChange} />
                                                <TextField id="state" required label="State" type="text" className='middle' value={this.state.state} onChange={this.userDetailsChange} />
                                                <TextField id="zip" required label="Postal Code" type="text" className='right' value={this.state.zip} onChange={this.userDetailsChange} />
                                            </div>
                                        </ThemeProvider>
                                    </Box>

                                    {this.state.selectedExtras.length > 0
                                        ?
                                        <Box className='addOns'>
                                            <Typography className='typo'>Extras </Typography>
                                            <hr className='horizontalLine' />
                                            <ThemeProvider theme={theme}>
                                                <Box className='addOnsForm'>
                                                    <Typography>Extra Name</Typography>
                                                    <Typography>Count</Typography>
                                                    <Typography>Total</Typography>
                                                </Box>
                                                {this.state.selectedExtras.map((extra, index) => (
                                                    <Box key={index} className='addOnsForm'>
                                                        <Typography>{this.state.selectedExtras[index]}</Typography>
                                                        <Typography>{this.state.selectedExtraCount[index]}</Typography>
                                                        <Typography>${this.state.selectedExtraAmount[index]}</Typography>
                                                        <Button
                                                            style={{ backgroundColor: '#FDECEB', color: '#DB3737', borderRadius: '5px', width: '80%', marginTop: '-2%' }}
                                                            onClick={() => this.deleteExtra(index)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Box>
                                                ))}
                                            </ThemeProvider>
                                        </Box>
                                        : null
                                    }

                                    <Box className='addOns'>
                                        <Typography className='typo'>Add-Ons</Typography>
                                        <hr className='horizontalLine' />
                                        <ThemeProvider theme={theme}>
                                            {this.props.extras.map((extra, index) => (
                                                <Box key={index} className='addOnsForm'>
                                                    {extra.extra_type === 0
                                                        ?
                                                        <>
                                                            <Typography className='bookingLabel'>{extra.name}</Typography>
                                                            <Button
                                                                className='addButton'
                                                                onClick={() => this.addExtra(extra)}
                                                            >
                                                                Add
                                                            </Button>
                                                        </>
                                                        : null
                                                    }
                                                    {extra.extra_type === 1 || extra.extra_type === 3
                                                        ?
                                                        <>
                                                            <Typography className='bookingLabel'>{extra.name}</Typography>
                                                            <TextField
                                                                required
                                                                label="Count"
                                                                type="number"
                                                                className='textfields'
                                                                value={this.state.counts[index] || ''}
                                                                onChange={(event) => this.handleCountChange(index, event)}
                                                            />
                                                            <Button
                                                                className='addButton'
                                                                onClick={() => this.addExtra(extra, this.state.counts[index])}
                                                                disabled={!(this.state.counts[index] && this.state.counts[index] > 0)}
                                                            >
                                                                Add
                                                            </Button>
                                                        </>
                                                        : null
                                                    }
                                                    {extra.extra_type === 2
                                                        ?
                                                        <>
                                                            <Typography className='bookingLabel'>{extra.name}</Typography>
                                                            <TextField
                                                                required
                                                                label="Start"
                                                                id="start"
                                                                type="number"
                                                                className='textfields'
                                                                value={this.state.start || ''}
                                                                onChange={this.handleStartEnd}
                                                            />
                                                            <TextField
                                                                required
                                                                label="End"
                                                                id="end"
                                                                type="number"
                                                                className='textfields'
                                                                value={this.state.end || ''}
                                                                onChange={this.handleStartEnd}
                                                            />
                                                            <Button
                                                                className='addButton'
                                                                onClick={() => this.addExtra(extra, 0)}
                                                                disabled={!this.state.type2Add}
                                                            >
                                                                Add
                                                            </Button>
                                                        </>
                                                        : null
                                                    }
                                                </Box>
                                            ))}
                                        </ThemeProvider>
                                    </Box>

                                    <Box className='payment'>
                                        <Typography className='typo'>Payment Information</Typography>
                                        <hr className='horizontalLine' />
                                        <ThemeProvider theme={theme}>
                                            <Box className='paymentOptionWrapper'>
                                                {this.state.paymentIntegration.name === 'None'
                                                    ?
                                                    <>
                                                        <Box className={`paymentOption ${this.state.selectedPaymentValue === "cash" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("cash") }}>
                                                            Cash
                                                        </Box>
                                                        <Box className={`paymentOption ${this.state.selectedPaymentValue === "check" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("check") }}>
                                                            Check
                                                        </Box>
                                                    </>
                                                    :
                                                    <>
                                                        <Box className={`paymentOption ${this.state.selectedPaymentValue === "fullPayment" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("fullPayment") }}>
                                                            Full Payment
                                                        </Box>
                                                        <Box className={`paymentOption ${this.state.selectedPaymentValue === "partialPayment" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("partialPayment") }}>
                                                            Partial Payment
                                                        </Box>
                                                        <Box className={`paymentOption ${this.state.selectedPaymentValue === "cash" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("cash") }}>
                                                            Cash
                                                        </Box>
                                                        <Box className={`paymentOption ${this.state.selectedPaymentValue === "check" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("check") }}>
                                                            Check
                                                        </Box>
                                                        {this.state.paymentIntegration.name === 'CardConnect' || this.state.paymentIntegration.name === 'CardConnect_o'
                                                            ?
                                                            <Box className={`paymentOption ${this.state.selectedPaymentValue === "ccPOS" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("ccPOS") }}>
                                                                Terminal
                                                            </Box>
                                                            : null
                                                        }
                                                        <Box className={`paymentOption ${this.state.selectedPaymentValue === "checkoutPayment" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("checkoutPayment") }}>
                                                            Pay at time of checkIn
                                                        </Box>
                                                    </>
                                                }
                                            </Box>
                                            {this.state.selectedPaymentValue === 'fullPayment' || this.state.selectedPaymentValue === 'partialPayment'
                                                ? (
                                                    <>
                                                        {this.state.selectedPaymentValue === 'fullPayment'
                                                            ? (
                                                                <>
                                                                    <div className='rows'>
                                                                        <Typography className='headings'>Amount to be paid</Typography>
                                                                        <TextField id="paymentAmt" required label="Amount" type="number" className='resRight' disabled={true} variant='standard'
                                                                            value={this.state.paymentIntegration.name === 'CampPay'
                                                                                ? parseFloat(this.state.totalAmount) + parseFloat(this.state.convenienceCharge)
                                                                                : parseFloat(this.state.totalAmount)}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )
                                                            : (
                                                                <>
                                                                    {this.state.paymentIntegration.name === 'CampPay'
                                                                        ?
                                                                        <>
                                                                            <div className='rows'>
                                                                                <Typography className='headings'>Amount to be paid</Typography>
                                                                                <TextField id="paymentAmt" required label="Amount" type="number"
                                                                                    className='resRight' value={this.state.partialBase} onChange={this.amountChange} />
                                                                            </div>
                                                                            <div className='rows'>
                                                                                <Typography className='headings'>Convenience fees</Typography>
                                                                                <TextField id="paymentAmt" type="number"
                                                                                    className='resRight' value={this.state.convenienceCharge} disabled={true} variant='standard' />
                                                                            </div>
                                                                            <div className='rows'>
                                                                                <Typography className='headings'>Total</Typography>
                                                                                <TextField id="paymentAmt" type="number"
                                                                                    className='resRight' value={this.state.partialTotal} disabled={true} variant='standard' />
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className='rows'>
                                                                                <Typography className='headings'>Amount to be paid</Typography>
                                                                                <TextField id="paymentAmt" required label="Amount" type="number"
                                                                                    className='resRight' value={this.state.partialBase} onChange={this.amountChange} />
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                        <Typography className='typo'>Card Details</Typography>
                                                        <hr className='horizontalLine' />
                                                        <Box className='cardDetailsBox' style={{ marginBottom: "50px" }}>
                                                            <Box style={{ display: 'flex', width: "85%" }}>
                                                                <div className='row'>
                                                                    <Typography className='typo'>Card Number</Typography>
                                                                    <TextField
                                                                        InputProps={{
                                                                            startAdornment: <CreditCardIcon />
                                                                        }}
                                                                        id="cardNumber" required label="Card Number" type="number"
                                                                        className='resRight' value={this.state.cardNumber} onChange={this.handlePaymentDetailsChange} />
                                                                </div>

                                                                <div className='row'>
                                                                    <Typography className='typo'>Card Owner</Typography>
                                                                    <TextField InputProps={{
                                                                        startAdornment: <PersonIcon />
                                                                    }} id="cardOwner" required label="Card Owner" type="text"
                                                                        className='resRight' value={this.state.cardOwner} onChange={this.handlePaymentDetailsChange} />
                                                                </div>
                                                            </Box>
                                                            <Box style={{ display: 'flex', marginTop: "30px" }}>
                                                                <div className='expiryInputRow'>
                                                                    <Typography className='typo'>Expiration Date</Typography>
                                                                    <Box style={{ display: 'flex' }}>
                                                                        <TextField style={{ width: "150px" }} InputProps={{
                                                                            startAdornment: <TodayIcon />
                                                                        }} id="expiryMonth" required label="MM" type="number" placeholder="MM"
                                                                            className='expiryInput' value={this.state.expiryMonth} onChange={this.handlePaymentDetailsChange} />
                                                                        <TextField style={{ width: "150px" }} InputProps={{
                                                                            startAdornment: <DateRangeIcon />
                                                                        }} id="expiryYear" required label="YY" type="number" placeholder="YY"
                                                                            className='expiryInputYY' value={this.state.expiryYear} onChange={this.handlePaymentDetailsChange} />
                                                                    </Box>
                                                                </div>
                                                                <div className='row' style={{ marginLeft: "50px" }}>
                                                                    <Typography className='typo'>CVV</Typography>
                                                                    <TextField style={{ width: "150px" }} InputProps={{
                                                                        startAdornment: <KeyIcon />
                                                                    }} id="cvv" required label="CVV" type="number"
                                                                        className='cvvInput' value={this.state.cvv} onChange={this.handlePaymentDetailsChange} />
                                                                </div>
                                                            </Box>
                                                        </Box>
                                                        <Button
                                                            className='addButton'
                                                            onClick={this.pay}
                                                            disabled={!this.state.userDetailsFormValid}
                                                        >
                                                            Pay
                                                        </Button>
                                                    </>
                                                )
                                                : null

                                            }
                                            {this.state.selectedPaymentValue === 'cash' || this.state.selectedPaymentValue === 'check'
                                                ?
                                                <>
                                                    {this.state.selectedPaymentValue === 'cash'
                                                        ?
                                                        <>
                                                            <div className='rows'>
                                                                <Typography className='headings'>Cash Amount</Typography>
                                                                <TextField id="cashAmount" required label="Amount" type="number"
                                                                    className='resRight' onChange={this.amountChange} />
                                                            </div>
                                                            <div className='rows'>
                                                                <Typography className='headings'>Date</Typography>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        id='cashDate'
                                                                        label='Cash Date*'
                                                                        className='date'
                                                                        onChange={(date) => {
                                                                            this.setState({ cashDate: date }, () => {
                                                                                this.userDetailsValidation();
                                                                            })
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                            <div className='rows'>
                                                                <Button
                                                                    className='addButton'
                                                                    onClick={this.pay}
                                                                    disabled={!this.state.userDetailsFormValid}
                                                                >
                                                                    Pay
                                                                </Button>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className='rows'>
                                                                <Typography className='headings'>Check Amount</Typography>
                                                                <TextField id="checkAmount" required label="Amount" type="number"
                                                                    className='resRight' onChange={this.amountChange} />
                                                            </div>
                                                            <div className='rows'>
                                                                <Typography className='headings'>Check Number</Typography>
                                                                <TextField id="checkNumber" required label="Number" type="number"
                                                                    className='resRight' onChange={this.amountChange} />
                                                            </div>
                                                            <div className='rows'>
                                                                <Typography className='headings'>Memo</Typography>
                                                                <TextField id="memo" required label="Memo" type="text"
                                                                    className='resRight' onChange={this.amountChange} />
                                                            </div>
                                                            <div className='rows'>
                                                                <Typography className='headings'>Date</Typography>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        id='checkDate'
                                                                        label='Check Date*'
                                                                        className='date'
                                                                        onChange={(date) => {
                                                                            this.setState({ checkDate: date }, () => {
                                                                                this.userDetailsValidation();
                                                                            })
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                            <div className='rows'>
                                                                <Button
                                                                    className='addButton'
                                                                    onClick={this.pay}
                                                                    disabled={!this.state.userDetailsFormValid}
                                                                >
                                                                    Pay
                                                                </Button>
                                                            </div>
                                                        </>}
                                                </>

                                                : null
                                            }
                                            {this.state.selectedPaymentValue === 'ccPOS'
                                                ?
                                                <>
                                                    <div className='rows'>
                                                        <Typography className='headings'>Amount to be paid</Typography>
                                                        <TextField id="ccPOSAmount" required label="Amount" type="number"
                                                            className='resRight' value={this.state.ccPOSAmount} onChange={this.amountChange} />
                                                    </div>
                                                    <div className='rows'>
                                                        <Button
                                                            className='addButton'
                                                            onClick={this.pay}
                                                        >
                                                            Pay
                                                        </Button>
                                                    </div>
                                                </>
                                                : null
                                            }
                                            {this.state.selectedPaymentValue === 'checkoutPayment'
                                                ?
                                                <>
                                                    <Button
                                                        className='addButton'
                                                        style={{ whiteSpace: "nowrap", width: "200px" }}
                                                        onClick={this.pay}
                                                    >
                                                        Complete Reservation
                                                    </Button>
                                                </>
                                                : null
                                            }
                                        </ThemeProvider>
                                    </Box>

                                </Box>
                                <Box className='rightRow'>
                                    <Box className='bookingDetails' style={{ marginBottom: '7%' }}>
                                        <Typography className='typo'>Discounts</Typography>
                                        <hr className='horizontalLine' />
                                        <Box className='bookingRow' >
                                            <ThemeProvider theme={theme}>
                                                <Box style={{ marginBottom: '7%' }}>
                                                    <Typography className='bookingLabel'>One-Time Discount</Typography>
                                                </Box>
                                                <Box style={{ justifyContent: 'end', marginBottom: '7%' }}>
                                                    <TextField style={{ width: '100%', marginRight: '-20%' }} onChange={this.addOneTimeDiscount}
                                                        id="oneTimeDiscount" type="number" value={this.state.oneTimeDiscount}
                                                    />
                                                </Box>
                                            </ThemeProvider>
                                        </Box>
                                    </Box>

                                    <Box className='bookingDetails' style={{ marginBottom: '7%' }}>
                                        <Typography className='bookingTypo'>Booking Details</Typography>
                                        <hr className='horizontalLine' />
                                        <Box className='bookingRow'>
                                            <Box>
                                                <DateRangeIcon />
                                                <Typography className='bookingLabel'>{this.props.checkIn}</Typography>
                                            </Box>
                                            <TrendingFlatIcon />
                                            <Box className='checkout'>
                                                <DateRangeIcon />
                                                <Typography className='bookingValue'>{this.props.checkOut}</Typography>
                                            </Box>
                                        </Box>
                                        <Box className='bookingRow'>
                                            <Box>
                                                <GroupIcon />
                                                <Typography className='bookingLabel'>Guests</Typography>
                                            </Box>
                                            <Typography className='bookingValue'>{this.props.guests}</Typography>
                                        </Box>
                                        <Box className='bookingRow'>
                                            <Box>
                                                <PetsIcon />
                                                <Typography className='bookingLabel'>Pets</Typography>
                                            </Box>
                                            <Typography className='bookingValue'>{this.props.pets}</Typography>
                                        </Box>
                                        <hr className='horizontalLine' />
                                        <Box className='bookingRow'>
                                            <Typography className='bookingLabel'>Site Name</Typography>
                                            <Typography className='bookingValue'>{this.props.spaceName}</Typography>
                                        </Box>
                                        <Box className='bookingRow'>
                                            <Typography className='bookingLabel'>Site Type</Typography>
                                            <Typography className='bookingValue'>{this.props.siteType}</Typography>
                                        </Box>
                                        {this.state.months > 0
                                            ? <Box className='bookingRow'>
                                                <Typography className='bookingLabel'>Months Total ({this.state.months})</Typography>
                                                <Typography className='bookingValue'>${this.state.monthsTotal}</Typography>
                                            </Box>
                                            : null
                                        }
                                        {this.state.weeks > 0
                                            ? <Box className='bookingRow'>
                                                <Typography className='bookingLabel'>Weeks Total ({this.state.weeks})</Typography>
                                                <Typography className='bookingValue'>${this.state.weeksTotal}</Typography>
                                            </Box>
                                            : null
                                        }
                                        {this.state.days > 0
                                            ? <Box className='bookingRow'>
                                                <Typography className='bookingLabel'>Days Total ({this.state.days})</Typography>
                                                <Typography className='bookingValue'>${this.state.daysTotal}</Typography>
                                            </Box>
                                            : null
                                        }
                                        {this.state.discountTotal != null
                                            ? <Box className='bookingRow'>
                                                <Typography className='bookingLabel'>Discount ({this.props.discountPercent}%)</Typography>
                                                <Typography className='bookingValue'>${this.state.discountTotal}</Typography>
                                            </Box>
                                            : null
                                        }
                                        {this.state.oneTimeDiscount > 0.00
                                            ? <Box className='bookingRow'>
                                                <Typography className='bookingLabel'>One-Time Discount</Typography>
                                                <Typography className='bookingValue'>${this.state.oneTimeDiscount}</Typography>
                                            </Box>
                                            : null
                                        }
                                        {Array.isArray(this.state.selectedExtras) && this.state.selectedExtras.length > 0
                                            ? <>
                                                {this.state.selectedExtras.map((extraName, index) => (
                                                    <Box className='bookingRow' key={index}>
                                                        <Typography className='bookingLabel'>{extraName} ({this.state.selectedExtraCount[index]})</Typography>
                                                        <Typography className='bookingValue'>${this.state.selectedExtraAmount[index]}</Typography>
                                                    </Box>
                                                ))}
                                            </>
                                            : null
                                        }
                                        <hr className='horizontalLine' />
                                        <Box className='bookingRow'>
                                            <Typography className='bookingLabel' style={{ fontWeight: 'bold' }}>Sub Total</Typography>
                                            <Typography className='bookingValue' style={{ fontWeight: 'bold' }}>${this.state.subTotal}</Typography>
                                        </Box>
                                        <hr className='horizontalLine' />
                                        {this.state.taxName.map((taxName, index) => (
                                            <Box className='bookingRow' key={index}>
                                                <Typography className='bookingLabel'>{taxName} ({this.state.taxPercent[index]}%)</Typography>
                                                <Typography className='bookingValue'>${this.state.tax[index]}</Typography>
                                            </Box>
                                        ))}
                                        <hr className='horizontalLine' />

                                        <Box className='bookingRow'>
                                            <Typography className='bookingLabel' style={{ fontWeight: 'bold' }}>Total</Typography>
                                            <Typography className='bookingValue' style={{ fontWeight: 'bold' }}>${this.state.totalAmount}</Typography>
                                        </Box>
                                    </Box>

                                    <Box className='bookingDetails'>
                                        <Typography className='typo'>Internal Notes</Typography>
                                        <hr className='horizontalLine' />
                                        <ThemeProvider theme={theme}>
                                            <textarea id="internalNotes" rows={15} onChange={this.userDetailsChange} value={this.state.internalNotes} style={{ width: '85%', marginBottom: '2%', whiteSpace: "pre-wrap", wordWrap: "break-word" }} />
                                        </ThemeProvider>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                    : null
                }
            </Grid>
        )
    };
};

export default withRouter(withStyles(useStyles)(NewReservationUserDetails));