import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import '../CSS/Settings.css';
import { deleteRecord, getPrices,} from '../utils/OpenCampground_Database_Calls'
class Prices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prices: [],
            pricesUpdate: false,
        };
    }

    async componentDidMount() {
        this.prices()
    }

    deleteRecord = async (data) => {
        let deleteResponse = await deleteRecord(this.props.serverName, "rates", data.id)
        this.setState({
            pricesUpdate: true
        })
        this.prices()
    }

    prices = async () => {
        if (this.state.pricesUpdate) {
            let prices = await getPrices(this.props.serverName)
            this.setState({
                prices: prices
            })
        } else {
            if (this.state.prices.length > 0) {
            } else {
                let prices = await getPrices(this.props.serverName)
                this.setState({
                    prices: prices
                })
            }
        }
    }

    render() {
        return (
            <TableContainer component={Paper} style={{ width: '60%', margin: '0 auto' }}>
                <Table className='table'>
                    <TableHead>
                        <TableRow>
                            <TableCell className='tableCell'>Season Name</TableCell>
                            <TableCell className='tableCell'>Price</TableCell>
                            <TableCell className='tableCell'>Daily</TableCell>
                            <TableCell className='tableCell'>Weekly</TableCell>
                            <TableCell className='tableCell'>Monthly</TableCell>
                            <TableCell className='tableCell'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.prices.map((price, index) => (
                            <TableRow key={index}>
                                <TableCell className='tableCell'>{price.season_name}</TableCell>
                                <TableCell className='tableCell'>{price.price_name}</TableCell>
                                <TableCell className='tableCell'>{price.daily_rate}</TableCell>
                                <TableCell className='tableCell'>{price.weekly_rate}</TableCell>
                                <TableCell className='tableCell'>{price.monthly_rate}</TableCell>
                                <TableCell className='tableCell'>
                                    <div>
                                        {/* <Button className='viewButtons' style={{ backgroundColor: '#d4d4d4', color: 'black'}}>Edit</Button> */}
                                        <Button className='viewButtons' onClick={() => this.deleteRecord(price)}>Delete</Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };
};
export default Prices;