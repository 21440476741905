import React, { Component } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent } from '@material-ui/core';
import '../CSS/Settings.css';
import { deleteRecord, getEmailTemplates, updateMailMessages } from '../utils/OpenCampground_Database_Calls'
class MailMessages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mailMessages: [],
            mailMessagesUpdate: false,
            editingIndex: -1,
            editingMailMessage: null,
            mailMessage: '',
        };
    }

    async componentDidMount() {
        this.mailMessages()
    }

    deleteRecord = async (data) => {
        let deleteResponse = await deleteRecord(this.props.serverName, "mail_templates", data.id)
        this.setState({
            mailMessagesUpdate: true
        })
        this.mailMessages()

    }

    mailMessages = async () => {
        if (this.state.mailMessagesUpdate) {
            let mailMessages = await getEmailTemplates(this.props.serverName)
            this.setState({
                mailMessages: mailMessages
            })
        } else {
            if (this.state.mailMessages.length > 0) {
            } else {
                let mailMessages = await getEmailTemplates(this.props.serverName)
                this.setState({
                    mailMessages: mailMessages
                })
            }
        }
    }

    editMailMessage = (index, message) => {
        this.setState({
            editingIndex: index,
            editingMailMessage: message,
        });
    }

    handleChange = (event) => {
        this.setState({
            editingMailMessage: event.target.value
        })
    }
    saveEdit = async () => {
        console.log(this.state.editingIndex + 1, this.state.editingMailMessage)
        await updateMailMessages(this.props.serverName, this.state.editingIndex + 1, this.state.editingMailMessage);
        this.setState({
            editingIndex: -1,
            mailMessagesUpdate: true,
        });
        this.mailMessages();
    }


    render() {
        return (
            <>
                {this.state.editingIndex >= 0
                    ?
                    <Dialog open={this.state.editingIndex >= 0} onClose={() => {
                        this.setState({
                            editingIndex: -1,
                            editingMailMessage: null,
                        });
                    }} maxWidth="sm" fullWidth>
                        <DialogContent dividers>
                            <div>
                                <h2 style={{ margin: "0px", marginBottom: "26px" }}>Update Mail Message</h2>
                            </div>
                            <div >
                                <textarea rows={20} id="mailMessage" onChange={this.handleChange} value={this.state.editingMailMessage.body}
                                    style={{ width: '95%', marginBottom: '2%', whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                                />
                                <Button style={{ backgroundColor: '#2dcd7a', color: '#000000' }} onClick={this.saveEdit} >Update</Button>
                                <Button style={{ backgroundColor: '#FDECEB', color: '#DB3737', marginLeft: '2%' }} onClick={() => {
                                    this.setState({
                                        editingIndex: -1,
                                        editingMailMessage: null,
                                    })
                                }}>Cancel</Button>
                            </div>
                            <div
                                style={{
                                    overflow: "hidden",
                                    borderRadius: "8px",
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                    background: "white",
                                    marginTop: "20px",
                                }}
                            >
                                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                    <thead style={{ backgroundColor: "#f1f2f4", color: "black" }}>
                                        <tr>
                                            <th style={{ textAlign: "left", padding: "10px" }}>Tag Name</th>
                                            <th style={{ textAlign: "left", padding: "10px" }}>Tag Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ backgroundColor: "#f9f9f9" }}>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>Full Name of Guest</td>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd", fontWeight: "bold", color: "#333" }}>
                                                {"{{camper}}"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>Space Name</td>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd", fontWeight: "bold", color: "#333" }}>
                                                {"{{space_name}}"}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: "#f9f9f9" }}>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>Reservation Number</td>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd", fontWeight: "bold", color: "#333" }}>
                                                {"{{number}}"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>Reservation Created Date</td>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd", fontWeight: "bold", color: "#333" }}>
                                                {"{{created}}"}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: "#f9f9f9" }}>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>Start Date</td>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd", fontWeight: "bold", color: "#333" }}>
                                                {"{{start}}"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>End Date</td>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd", fontWeight: "bold", color: "#333" }}>
                                                {"{{departure}}"}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: "#f9f9f9" }}>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>Charges</td>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd", fontWeight: "bold", color: "#333" }}>
                                                {"{{charges}}"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>Payment</td>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd", fontWeight: "bold", color: "#333" }}>
                                                {"{{payment}}"}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: "#f9f9f9" }}>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>Due</td>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd", fontWeight: "bold", color: "#333" }}>
                                                {"{{due}}"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>Deposit</td>
                                            <td style={{ padding: "10px", borderBottom: "1px solid #ddd", fontWeight: "bold", color: "#333" }}>
                                                {"{{deposit}}"}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </DialogContent>
                    </Dialog>
                    : ""}
                <TableContainer component={Paper} style={{ width: '60%', margin: '0 auto' }}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell className='tableCell'>Name</TableCell>
                                <TableCell className='tableCell'>Body</TableCell>
                                <TableCell className='tableCell'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.mailMessages.map((message, index) => (
                                <TableRow key={index}>
                                    <TableCell className='tableCell'>{message.name}</TableCell>
                                    <TableCell className='tableCell'>{message.body}</TableCell>
                                    <TableCell className='tableCell'>
                                        <div>
                                            <Button className='viewButtons' onClick={() => this.editMailMessage(index, message)} style={{ backgroundColor: '#d4d4d4', color: 'black' }}>Edit</Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    };
};
export default MailMessages;