import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import '../CSS/RemoteReservations.css';
import { getSiteRigDiscountExtra, getSpaces, getRemoteExplainations, getParkMapURL } from '../utils/OpenCampground_Database_Calls'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import RemoteSpaceSelect from './RemoteSpaceSelect';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class RemoteReservations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //pre requisit data
            sites: [],
            rigs: [],
            extras: [],
            taxes: [],

            //form
            checkIn: format(new Date(), 'MM/dd/yyyy'),
            checkOut: '',
            guests: '',
            rigType: '',
            pets: '',
            rigLength: '',
            siteType: 'Any',
            slides: '',
            children: '',

            //form validation
            formValid: false,

            //view changing variables
            form: true,
            selectSpace: false,

            //spaces
            spaceData: [],

            //popup
            open: false,
            popuptext: '',

            //explainations
            facilities: '',
            additionalDetails: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            rules: '',
            changes: '',
            showBlackoutDates: false,

            //map
            mapURL: null
        };
    }

    async componentDidMount() {
        let siteRigDiscountExtra = await getSiteRigDiscountExtra(this.props.serverName);
        let explainations = await getRemoteExplainations(this.props.serverName)
        let getMapURL =  await getParkMapURL(this.props.serverName)
        console.log(getMapURL.body.imageUrl)
        this.setState({
            sites: siteRigDiscountExtra[0],
            rigs: siteRigDiscountExtra[1],
            extras: siteRigDiscountExtra[3],
            explainations: explainations,
            facilities: explainations.facilities,
            additionalDetails: explainations.additionalDetails,
            addressLine1: explainations.addressLine1,
            addressLine2: explainations.addressLine2,
            addressLine3: explainations.addressLine3,
            rules: explainations.rules,
            changes: explainations.changes,
            mapURL: getMapURL.body.imageUrl
        })
    }

    rigChange = (event) => {
        this.setState({
            rigType: event.target.value
        }, () => {
            this.formValidation();
        })
    };

    siteChange = (event) => {
        this.setState({
            siteType: event.target.value
        }, () => {
            this.formValidation();
        })
    };

    handleChange = (event) => {
        const { id, value } = event.target;
        const ranges = {
            guests: [0, 999],
            rigLength: [0, 99],
            pets: [0, 9],
            slides: [0, 9],
            children: [0, 99],
        };

        if (ranges[id]) {
            const [min, max] = ranges[id];
            if (value >= min && value <= max) {
                this.setState({ [id]: value }, this.formValidation);
            }
        } else {
            this.setState({ [id]: value }, this.formValidation);
        }
    }

    formValidation = () => {
        if (this.state.checkIn && this.state.checkOut && (this.state.checkIn < this.state.checkOut) &&
            this.state.guests > 0 && this.state.rigType && this.state.pets >= 0 && this.state.rigLength &&
            this.state.siteType && this.state.slides >= 0 && this.state.children >= 0) {
            this.setState({
                formValid: true
            })
        } else if (this.state.formValid) {
            this.setState({
                formValid: false
            })
        }
    }

    findSpace = async () => {
        let inDate = this.state.checkIn.format('YYYY-MM-DD');
        let outDate = this.state.checkOut.format('YYYY-MM-DD');
        const isUnderBlackout = this.props.blackoutDates.some((blackout) => {
            const start = dayjs(blackout.start);
            const end = dayjs(blackout.end);
            return (
                dayjs(inDate).isBetween(start, end, 'day', '[]') ||
                dayjs(outDate).isBetween(start, end, 'day', '[]') ||
                (dayjs(inDate).isBefore(start) && dayjs(outDate).isAfter(end))
            );
        });

        if (isUnderBlackout) {
            this.setState({
                open: true,
                popuptext: 'There are some blackout dates that fall under your selected dates. To book in these dates, you need to cantact the staff. '
            })
        } else {
            let spacesData = await getSpaces(this.props.serverName, outDate, inDate)
            console.log("Space Date: ", spacesData)
            let filteredSpacesData
            if (this.state.siteType === 'Any') {
                filteredSpacesData = spacesData
            } else {
                filteredSpacesData = spacesData.filter(space => space.sitetype_name === this.state.siteType);
            }
            console.log("Filtered Space Date: ", filteredSpacesData)
            this.setState({
                checkIn: inDate,
                checkOut: outDate,
                formValid: false,
                form: false,
                selectSpace: true,
                spaceData: filteredSpacesData
            })
        }
    }

    updateSpace = (spacesData) => {
        this.setState({
            spaceData: spacesData
        })
    }

    isDateInBlackout = (date) => {
        return this.props.blackoutDates.some((blackout) => {
            const start = dayjs(blackout.start);
            const end = dayjs(blackout.end);
            return date.isBetween(start, end, 'day', '[]');
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid className='remoteReservationList'>
                {this.state.form
                    ?
                    <>
                        <ThemeProvider theme={theme}>
                            <Dialog open={this.state.open} onClose={() => this.setState({ confirmationOpen: false })} classes={{ paper: classes.dialogPaper }}>
                                <DialogTitle>Blackout Dates</DialogTitle>
                                <DialogContent>
                                    {this.state.popuptext}
                                </DialogContent>
                                <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button onClick={() => this.setState({ open: false })} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Box className='infoBox'>
                                <Typography className='infoBarTypo'>Welcome To {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)} Online Reservation System</Typography>
                                {this.props.blackoutDates.length
                                    ?
                                    <>
                                        {this.state.showBlackoutDates
                                            ? <Button className='blackoutButton' onClick={() => { this.setState({ showBlackoutDates: false }) }}>Back To Reservation</Button>
                                            : <Button className='blackoutButton' onClick={() => { this.setState({ showBlackoutDates: true }) }}>Show Blackout Dates</Button>
                                        }
                                    </>
                                    : null
                                }

                            </Box>
                            {this.state.showBlackoutDates
                                ?
                                <div className='remoteBlackoutDates'>
                                    <TableContainer component={Paper} style={{ marginTop: '5%' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ border: '1px solid #efefef' }}>Blackout Name</TableCell>
                                                    <TableCell style={{ border: '1px solid #efefef' }}>Start</TableCell>
                                                    <TableCell style={{ border: '1px solid #efefef' }}>End</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.blackoutDates.map((blackout) => (
                                                    <TableRow key={blackout.id}>
                                                        <TableCell style={{ border: '2px solid #efefef' }}>{blackout.name}</TableCell>
                                                        <TableCell style={{ border: '2px solid #efefef' }}>{blackout.start}</TableCell>
                                                        <TableCell style={{ border: '2px solid #efefef' }}>{blackout.end}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                :
                                <>
                                    <Box className='remoteResBox'>
                                        <Box className='remoteResFormBox'>
                                            <Typography className='remoteReservationstypography' style={{ textAlign: "center" }}>Create Reservations</Typography>
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        id='checkIn'
                                                        label='Check In*'
                                                        className='remoteResLeft'
                                                        disablePast
                                                        shouldDisableDate={(date) => this.isDateInBlackout(date)}
                                                        onChange={(date) => {
                                                            this.setState({ checkIn: date }, () => {
                                                                this.formValidation();
                                                            })
                                                        }}
                                                    />
                                                </LocalizationProvider></Grid>
                                            <Grid item xs={12} sm={6}> <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    id='checkOut'
                                                    label='Check Out*'
                                                    className='remoteResMiddle'
                                                    minDate={dayjs(this.state.checkIn).add(1, 'day')}
                                                    shouldDisableDate={(date) => this.isDateInBlackout(date)}
                                                    onChange={(date) => {
                                                        this.setState({ checkOut: date }, () => {
                                                            this.formValidation();
                                                        })
                                                    }}
                                                />
                                            </LocalizationProvider></Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="guests" required label="Number of Guest" type="number"
                                                    className='remoteResRight' onChange={this.handleChange}
                                                    value={this.state.guests} inputProps={{ min: 0, max: 999 }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="children" required label="Children" type="number"
                                                    className='remoteResRight' onChange={this.handleChange}
                                                    value={this.state.children} inputProps={{ min: 0, max: 99 }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="pets" required label="Pets" type="number"
                                                    className='remoteResMiddle' onChange={this.handleChange}
                                                    value={this.state.pets} inputProps={{ min: 0, max: 9 }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="rigLength" required label="Rig Length" type="number"
                                                    className='remoteResRight' onChange={this.handleChange}
                                                    value={this.state.rigLength} inputProps={{ min: 0, max: 99 }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl className='remoteResLeft'>
                                                    <InputLabel >Site Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="siteSelect"
                                                        value={this.state.siteType}
                                                        label="Site Type"
                                                        onChange={this.siteChange}
                                                    >
                                                        <MenuItem value="Any">Any</MenuItem>
                                                        {this.state.sites.map((site, index) => (
                                                            <MenuItem key={index} value={site.name}>
                                                                {site.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl className='remoteResLeft'>
                                                    <InputLabel >Rig Type</InputLabel>
                                                    <Select
                                                        labelId="rig"
                                                        id="rigSelect"
                                                        value={this.state.rigType}
                                                        label="Rig Type"
                                                        onChange={this.rigChange}
                                                    >
                                                        {this.state.rigs.map((rig, index) => (
                                                            <MenuItem key={index} value={rig.name}>
                                                                {rig.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="slides" required label="Number of slides" type="number"
                                                    className='remoteResMiddle' onChange={this.handleChange}
                                                    value={this.state.slides} inputProps={{ min: 0, max: 9 }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Button className='remoteResButton' onClick={this.findSpace} disabled={!this.state.formValid}>Find Space</Button>
                                    </Box>
                                </>
                            }
                        </ThemeProvider>
                    </>
                    : <RemoteSpaceSelect sites={this.state.sites} rigs={this.state.rigs} extras={this.state.extras}
                        checkIn={this.state.checkIn} checkOut={this.state.checkOut} guests={this.state.guests}
                        rigType={this.state.rigType} pets={this.state.pets} rigLength={this.state.rigLength}
                        slides={this.state.slides} children={this.state.children} spaceData={this.state.spaceData}
                        serverName={this.props.serverName} updateSpace={this.updateSpace} allow={this.props.allow} require={this.props.require}
                        facilities={this.state.facilities} additionalDetails={this.state.additionalDetails} addressLine1={this.state.addressLine1}
                        addressLine2={this.state.addressLine2} addressLine3={this.state.addressLine3} rules={this.state.rules}
                        changes={this.state.changes} siteType={this.state.siteType} mapURL={this.state.mapURL}/>
                }
            </Grid>
        )
    };
};

export default withStyles(useStyles)(RemoteReservations);