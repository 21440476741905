import React, { Component } from 'react';
import { Grid, Button, Typography, Box } from '@material-ui/core';
import '../CSS/ReportReservations.css';
import { withStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getGroupCampers } from '../utils/OpenCampground_Database_Calls';

const useStyles = theme => ({
    circularProgress: {
        position: 'absolute',
        left: '55%',
        top: '50%',
        color: '#2dcd7a'
    },
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class ReportReservations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupCamperData: [],
            groupCamperOpen: false,
            message: 'No Camper Details',
            headerMessage: '',
            averageOccupancy: '',
            revPAS: '',
            lengthOfStay: '',
            totalRevenue: ''
        };
    }

    async componentDidMount() {
        if (this.props.show === 'occupancy') {
            let totalAvailableSiteNights = (this.props.data[0].total_days * this.props.data.length)
            let totalOccupiedSiteNights = null
            let totalRevenue = null
            let numberOfReservations = null
            for (let index = 0; index < this.props.data.length; index++) {
                totalOccupiedSiteNights += this.props.data[index].occupied_days
                totalRevenue += this.props.data[index].total_amount
                numberOfReservations += this.props.data[index].reservations_count
            }
            this.setState({
                averageOccupancy: ((totalOccupiedSiteNights / totalAvailableSiteNights) * 100).toFixed(2),
                revPAS: (totalRevenue / totalAvailableSiteNights).toFixed(2),
                lengthOfStay: (totalOccupiedSiteNights / numberOfReservations).toFixed(1),
                totalRevenue: totalRevenue.toFixed(2)
            })
        }
    }

    getGroupCamper = async (item) => {
        let campersData = await getGroupCampers(this.props.serverName, item.id)
        console.log(campersData.body)
        this.setState({
            groupCamperData: campersData.body,
            groupCamperOpen: true,
            headerMessage: 'List of guests in group ' + item.name
        })
    }

    render() {
        const { classes } = this.props;
        let reservationRows, previousButton, nextButton, lastButton
        let paginationButtons = []
        const itemsPerPage = 10;
        const totalPages = Math.ceil(this.props.data.length / itemsPerPage);
        let currentPage = this.state.currentPage || 1;

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, this.props.data.length);

        const reservationsOnPage = this.props.data.slice(startIndex, endIndex);

        reservationRows = reservationsOnPage.map((item) => {
            if (this.props.show === 'groups') {
                return (
                    <tr key={item.id} className='display6Rows'>
                        <td>{item.name}</td>
                        <td>{item.expected_number}</td>
                        <td>{item.first_name} {item.last_name}</td>
                        <td>{item.startdate}</td>
                        <td>{item.enddate}</td>
                        <td>
                            <Button
                                className='selectButton'
                                onClick={() => this.getGroupCamper(item)}
                                style={{ backgroundColor: '#999999', color: '#ffffff', padding: '2% 10% 2% 10%', borderRadius: '10px', textTransform: "capitalize" }}
                            >
                                Show Guests
                            </Button>
                        </td>
                    </tr>
                );
            } else if (this.props.display === '8') {
                let tax = (item.reservation_tax * item.amount) / item.reservation_Total
                let baseAmount = item.amount - tax
                return (
                    <tr key={item.id} className='display8Rows'>
                        <td>{item.id}</td>
                        <td>{item.first_name} {item.last_name}</td>
                        <td>{item.reference_number}</td>
                        <td>{item.pmt_date}</td>
                        <td>{item.creditcard_name}</td>
                        <td>${baseAmount.toFixed(2)}</td>
                        <td>${tax.toFixed(2)}</td>
                        <td>${item.amount}</td>
                    </tr>
                );
            } else if (this.props.display === '9') {
                return (
                    <tr key={item.id} className='reservationRows'>
                        <td>{item.id}</td>
                        <td>{item.startdate}</td>
                        <td>{item.enddate}</td>
                        <td>{item.first_name} {item.last_name}</td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        <td>{item.sitename}</td>
                        <td>{item.rigname}</td>
                        <td>${item.total_amount}</td>
                    </tr>
                );
            } else if (this.props.display === '5') {
                return (
                    <tr key={item.id} className='display5Rows'>
                        <td>{item.space_name}</td>
                        <td>{((item.occupied_days / item.total_days) * 100).toFixed(2)}%</td>
                        <td>{item.occupied_days}</td>
                        <td>
                            {item.occupied_days > 0 && item.reservations_count > 0
                                ? `${(item.occupied_days / item.reservations_count).toFixed(1)} nights`
                                : 'N/A'}
                        </td>
                        <td>${(item.total_amount).toFixed(2)}</td>
                    </tr>
                );
            } else if (this.props.display === '7') {
                return (
                    <tr key={item.id} className='display7Rows'>
                        <td>{item.extraType}</td>
                        <td>{item.siteName}</td>
                        <td>{item.reservation_id}</td>
                        <td>{item.createdAt}</td>
                        <td>{item.first_name} {item.last_name}</td>
                        <td>{item.count}</td>
                        <td>${(item.charge).toFixed(2)}</td>
                    </tr>
                );
            }
        });

        paginationButtons = [];
        const maxButtonsToShow = 10;
        let startPage, endPage;

        if (totalPages <= maxButtonsToShow) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const maxPagesBeforeCurrentPage = Math.floor(maxButtonsToShow / 2);
            const maxPagesAfterCurrentPage = Math.ceil(maxButtonsToShow / 2) - 1;
            if (currentPage <= maxPagesBeforeCurrentPage) {
                startPage = 1;
                endPage = maxButtonsToShow;
            } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                startPage = totalPages - maxButtonsToShow + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrentPage;
                endPage = currentPage + maxPagesAfterCurrentPage;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Button className={`paginationButton ${currentPage === i ? "activePage" : ""}`} key={i} onClick={() => this.setState({ currentPage: i })}>
                    {i}
                </Button>
            );
        }

        previousButton = (
            <Button className='previousButton' disabled={currentPage === 1} onClick={() => this.setState({ currentPage: currentPage - 1 })}>
                <ArrowBackIosIcon />
            </Button>
        );

        nextButton = (
            <Button className='nextButton' disabled={currentPage === totalPages} onClick={() => this.setState({ currentPage: currentPage + 1 })}>
                <ArrowForwardIosIcon />
            </Button>
        );

        lastButton = (
            <Button className='paginationButton' disabled={currentPage === totalPages} onClick={() => this.setState({ currentPage: totalPages })}>
                {totalPages}
            </Button>
        );

        return (
            <Grid className='reportReservationsList'>
                {this.state.groupCamperOpen
                    ?
                    <>
                        <Box className='headerBox' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography className='headertypo'>
                                {this.state.headerMessage}
                            </Typography>
                            <Button
                                className='selectButton'
                                onClick={() => this.setState({ groupCamperData: [], groupCamperOpen: false })}
                                style={{ backgroundColor: '#999999', color: '#ffffff', padding: '1% 2% 1% 2%', borderRadius: '10px', textTransform: "capitalize" }}
                            >
                                Show Groups
                            </Button>
                        </Box>
                        {this.state.groupCamperData.length > 0
                            ?
                            <>
                                <Box className='display6Box'>
                                    <Typography className='headerTypo'>Name</Typography>
                                    <Typography className='headerTypo'>Email</Typography>
                                    <Typography className='headerTypo'>Phone</Typography>
                                    <Typography className='headerTypo'>Address</Typography>
                                    <Typography className='headerTypo'>City</Typography>
                                    <Typography className='headerTypo'>State</Typography>
                                </Box>
                                <Box className='tableBox'>
                                    <table>
                                        <tbody>
                                            {this.state.groupCamperData.map((item) => (
                                                <tr key={item.id} className='display6Rows'>
                                                    <td>{item.first_name} {item.last_name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.address}</td>
                                                    <td>{item.city}</td>
                                                    <td>{item.state}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Box>
                            </>
                            :
                            <Box className='headerBox'>
                                <Typography className='headertypo'>
                                    {this.state.message}
                                </Typography>
                            </Box>
                        }
                    </>
                    :
                    this.props.data.length > 0
                        ?
                        <>
                            <Box className='headerBox'>
                                <Typography className='headertypo'>
                                    {this.props.headerMessage}
                                </Typography>
                            </Box>
                            {this.props.show === 'occupancy'
                                ?
                                <>
                                    <div className='occExtraMainBlock'>
                                        <div className='individualBlock'>
                                            <h4 className='dataInfo'>Average Occupancy Rate</h4>
                                            <h2 >{this.state.averageOccupancy}%</h2>
                                        </div>
                                        <div className='individualBlock'>
                                            <h4 className='dataInfo'>Revenue Per Available Site</h4>
                                            <h2 >${this.state.revPAS}</h2>
                                        </div>
                                        <div className='individualBlock'>
                                            <h4 className='dataInfo'>Length of Stay</h4>
                                            <h2 >{this.state.lengthOfStay} nights</h2>
                                        </div>
                                        <div className='individualBlock'>
                                            <h4 className='dataInfo'>Total Revenue </h4>
                                            <h2 >${this.state.totalRevenue}</h2>
                                        </div>
                                    </div>
                                </>
                                : null
                            }
                            {this.props.show === 'groups'
                                ?
                                <Box className='display6Box'>
                                    <Typography className='headerTypo'>Group Name</Typography>
                                    <Typography className='headerTypo'>Spaces Booked</Typography>
                                    <Typography className='headerTypo'>Organizer</Typography>
                                    <Typography className='headerTypo'>Start Date</Typography>
                                    <Typography className='headerTypo'>EndDate</Typography>
                                    <Typography className='headerTypo'>Actions</Typography>
                                </Box>
                                : null
                            }
                            {this.props.display === '8'
                                ?
                                <Box className='display8Box'>
                                    <Typography className='headerTypo'>Reservation #</Typography>
                                    <Typography className='headerTypo'>Customer Name</Typography>
                                    <Typography className='headerTypo'>Reference #</Typography>
                                    <Typography className='headerTypo'>Payment Date</Typography>
                                    <Typography className='headerTypo'>Payment Type</Typography>
                                    <Typography className='headerTypo'>Base Amount</Typography>
                                    <Typography className='headerTypo'>Tax Amount</Typography>
                                    <Typography className='headerTypo'>Total Amount</Typography>
                                </Box>
                                : null
                            }
                            {this.props.display === '9'
                                ?
                                <Box className='reservationTableHeadingBox'>
                                    <Typography className='headerTypo'>Reservation #</Typography>
                                    <Typography className='headerTypo'>Start Date</Typography>
                                    <Typography className='headerTypo'>End Date</Typography>
                                    <Typography className='headerTypo'>Customer Name</Typography>
                                    <Typography className='headerTypo'>Phone Number</Typography>
                                    <Typography className='headerTypo'>Email</Typography>
                                    <Typography className='headerTypo'>Site</Typography>
                                    <Typography className='headerTypo'>Rig Type</Typography>
                                    <Typography className='headerTypo'>Total</Typography>
                                </Box>
                                : null
                            }
                            {this.props.display === '5'
                                ?
                                <Box className='display5Box'>
                                    <Typography className='headerTypo'>Site Name</Typography>
                                    <Typography className='headerTypo'>Occupancy Rate</Typography>
                                    <Typography className='headerTypo'>Total Nights Occupied</Typography>
                                    <Typography className='headerTypo'>Average Length of Stay</Typography>
                                    <Typography className='headerTypo'>Revenue</Typography>
                                </Box>
                                : null
                            }
                            {this.props.display === '7'
                                ?
                                <Box className='display7Box'>
                                    <Typography className='headerTypo'>Extra Type</Typography>
                                    <Typography className='headerTypo'>Site Name</Typography>
                                    <Typography className='headerTypo'>Reservation</Typography>
                                    <Typography className='headerTypo'>Date</Typography>
                                    <Typography className='headerTypo'>Customer Name</Typography>
                                    <Typography className='headerTypo'>Count</Typography>
                                    <Typography className='headerTypo'>Amount</Typography>
                                </Box>
                                : null
                            }
                            <Box className='tableBox'>
                                <table>
                                    <tbody>
                                        {reservationRows}
                                    </tbody>
                                </table>
                            </Box>
                            <div className="pagination">
                                {previousButton}
                                {paginationButtons}
                                ...
                                {lastButton}
                                {nextButton}
                            </div>
                        </>
                        :
                        <>
                            <Box className='headerBox'>
                                <Typography className='headertypo'>
                                    {this.props.message}
                                </Typography>
                            </Box>
                        </>
                }
            </Grid>
        );
    };
};

export default withStyles(useStyles)(ReportReservations);