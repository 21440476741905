import React, { Component } from "react";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import "../CSS/NewReservation.css";
import {
    getSiteRigDiscountExtra,
    getSpaces,
    getIntegrations,
    getEmailTemplates,
    sendMail,
    getEmails,
} from "../utils/OpenCampground_Database_Calls";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";
import dayjs from "dayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import newReservations from "../assets/new-reservations.png";
import NewReservationSpaceSelect from "./NewReservationSpaceSelect";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import GroupReservationSpaceSelect from './GroupReservationSpaceSelect'

const useStyles = (theme) => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: "10px",
    },
});

const theme = createTheme({
    palette: {
        primary: {
            main: "#2dcd7a",
        },
    },
});

class NewReservationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //pre requisit data
            sites: [],
            rigs: [],
            discounts: [],
            extras: [],
            taxes: [],

            //paymentIntegrations
            paymentIntegration: [],

            //email
            reservationConfirmationMailMessage: "",
            reservationConfirmationSubject: "",
            sender: "",
            reply: "",
            cc: "",
            bcc: "",

            //form
            checkIn: format(new Date(), "MM/dd/yyyy"),
            checkOut: "",
            guests: "",
            rigType: "",
            pets: "0",
            rigLength: "",
            siteType: "Any",
            slides: "",
            discount: null,

            //form validation
            formValid: false,
            userDetailsFormValid: false,

            //view chnging variables
            form: true,
            selectSpace: false,
            userDetails: false,

            //spaces
            spaceData: [],

            //discount
            discountApplyDaily: null,
            discountApplyWeek: null,
            discountApplyMonth: null,
            discountApplySeasonal: null,
            discountPercent: null,
            discountId: "",
            discountAmountOnce: null,
            discountAmountDaily: null,
            discountAmountWeekly: null,
            discountAmountMonthly: null,

            //groupReservations
            groupReservations: false
        };
    }

    async componentDidMount() {
        let siteRigDiscountExtra = await getSiteRigDiscountExtra(
            this.props.serverName
        );
        let paymentIntegration = await getIntegrations(this.props.serverName);
        let mailMessages = await getEmailTemplates(this.props.serverName);
        let email = await getEmails(this.props.serverName);
        this.setState({
            sites: siteRigDiscountExtra[0],
            rigs: siteRigDiscountExtra[1],
            discounts: siteRigDiscountExtra[2],
            extras: siteRigDiscountExtra[3],
            paymentIntegration: paymentIntegration,
            reservationConfirmationMailMessage: mailMessages[0].body,
            reservationConfirmationSubject: email.confirm_subject,
            sender: email.sender,
            reply: email.reply,
            cc: email.cc,
            bcc: email.bcc,
        });
    }

    rigChange = (event) => {
        this.setState(
            {
                rigType: event.target.value,
            },
            () => {
                this.formValidation();
            }
        );
    };

    siteChange = (event) => {
        this.setState({
            siteType: event.target.value,
        });
    };

    discountChange = (event) => {
        let selectedDiscount = this.state.discounts.find(
            (discount) => discount.name === event.target.value
        );
        this.setState({
            discount: event.target.value,
            discountApplyDaily: selectedDiscount.disc_appl_daily === 1,
            discountApplyWeek: selectedDiscount.disc_appl_week === 1,
            discountApplyMonth: selectedDiscount.disc_appl_month === 1,
            discountApplySeasonal: selectedDiscount.disc_appl_seasonal === 1,
            discountPercent: selectedDiscount.discount_percent,
            discountId: selectedDiscount.id,
            discountAmountOnce: selectedDiscount.amount,
            discountAmountDaily: selectedDiscount.amount_daily,
            discountAmountWeekly: selectedDiscount.amount_weekly,
            discountAmountMonthly: selectedDiscount.amount_monthly,
        });
    };

    handleChange = (event) => {
        const { id, value } = event.target;
        const ranges = {
            guests: [0, 999],
            rigLength: [0, 99],
            pets: [0, 9],
            slides: [0, 9],
        };

        if (ranges[id]) {
            const [min, max] = ranges[id];
            if (value >= min && value <= max) {
                this.setState({ [id]: value }, this.formValidation);
            }
        } else {
            this.setState({ [id]: value }, this.formValidation);
        }
    };

    formValidation = () => {
        const isValid = this.state.groupReservations
            ? this.state.checkIn && this.state.checkOut && this.state.checkIn < this.state.checkOut
            : this.state.checkIn && this.state.checkOut && this.state.checkIn < this.state.checkOut && this.state.guests > 0 && this.state.rigType;

        if (this.state.formValid !== isValid) {
            this.setState({ formValid: isValid });
        }
    };

    findSpace = async () => {
        let inDate = this.state.checkIn.format("YYYY-MM-DD");
        let outDate = this.state.checkOut.format("YYYY-MM-DD");
        let spacesData = await getSpaces(this.props.serverName, outDate, inDate);
        let filteredSpacesData;
        if (this.state.siteType === "Any") {
            filteredSpacesData = spacesData;
        } else {
            filteredSpacesData = spacesData.filter(
                (space) => space.sitetype_name === this.state.siteType
            );
        }
        this.setState({
            checkIn: inDate,
            checkOut: outDate,
            formValid: false,
            form: false,
            selectSpace: true,
            spaceData: filteredSpacesData,
        });
    };

    cancelReservation = () => {
        this.setState({
            form: true,
            selectSpace: false,
        });
    };

    handleSwitchChange = (event) => {
        this.setState({ groupReservations: event.target.checked })
    };

    render() {
        return (
            <Grid className="reservationList">
                <Box className="infoBox">
                    <Typography className="infoBarTypo">
                        Welcome:{" "}
                        {this.props.serverName.charAt(0).toUpperCase() +
                            this.props.serverName.slice(1)}
                    </Typography>
                    <Typography className="infoBarTypo">
                        Username: {this.props.user}
                    </Typography>
                </Box>
                {this.state.form
                    ?
                    <>
                        <Box className="formBox">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "2%",
                                }}
                            >
                                <img
                                    src={newReservations}
                                    alt="Reservations"
                                    style={{ height: "40px", marginRight: "8px" }}
                                />
                                <Typography style={{ fontSize: "2rem" }}>
                                    Create Reservations
                                </Typography>
                            </div>
                            <Button
                                className="cancel"
                                onClick={() => {
                                    this.props.history.replace(
                                        `/manager?serverName=${this.props.serverName}&view=reservations`
                                    );
                                }}
                            >
                                Cancel Reservation
                            </Button>
                        </Box>
                        <ThemeProvider theme={theme}>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: " 2% 0 2% 2%",
                                    padding: "4% 1% 2.5% 1%",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "10px",
                                    height: this.state.groupReservations ? "25%" : "50%",
                                    boxShadow: "0px 3px 5px rgba(159, 159, 159, 255)",
                                    marginRight: "-1%"
                                }}
                            >
                                <Box className="resBox">
                                    <Box></Box>
                                    <FormControlLabel control={<Switch checked={this.state.groupReservations} onChange={this.handleSwitchChange} />} label="Group Reservations" style={{ width: '120%', marginTop: '-20%' }} />
                                    <Box></Box>
                                    {!this.state.groupReservations
                                        ?
                                        <>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    id="checkIn"
                                                    label="Check In*"
                                                    className="resLeft"
                                                    disablePast
                                                    onChange={(date) => {
                                                        this.setState({ checkIn: date }, () => {
                                                            this.formValidation();
                                                        });
                                                    }}
                                                />
                                            </LocalizationProvider>

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    id="checkOut"
                                                    label="Check Out*"
                                                    className="resMiddle"
                                                    minDate={dayjs(this.state.checkIn).add(1, "day")}
                                                    onChange={(date) => {
                                                        this.setState({ checkOut: date }, () => {
                                                            this.formValidation();
                                                        });
                                                    }}
                                                />
                                            </LocalizationProvider>

                                            <TextField
                                                id="guests"
                                                required
                                                label="Number of Guest"
                                                type="number"
                                                className="resRight"
                                                onChange={this.handleChange}
                                                value={this.state.guests}
                                                inputProps={{ min: 0, max: 999 }}
                                            />

                                            <FormControl className="resLeft">
                                                <InputLabel>Rig Type</InputLabel>
                                                <Select
                                                    labelId="rig"
                                                    id="rigSelect"
                                                    value={this.state.rigType}
                                                    label="Rig Type"
                                                    onChange={this.rigChange}
                                                >
                                                    {this.state.rigs.map((rig, index) => (
                                                        <MenuItem key={index} value={rig.name}>
                                                            {rig.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                id="pets"
                                                label="Pets"
                                                type="number"
                                                className="resMiddle"
                                                onChange={this.handleChange}
                                                value={this.state.pets}
                                                inputProps={{ min: 0, max: 9 }}
                                            />

                                            <TextField
                                                id="rigLength"
                                                label="Rig Length"
                                                type="number"
                                                className="resRight"
                                                onChange={this.handleChange}
                                                value={this.state.rigLength}
                                                inputProps={{ min: 0, max: 99 }}
                                            />

                                            <FormControl className="resLeft">
                                                <InputLabel>Site Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="siteSelect"
                                                    value={this.state.siteType}
                                                    label="Site Type"
                                                    onChange={this.siteChange}
                                                >
                                                    <MenuItem value="Any">Any</MenuItem>
                                                    {this.state.sites.map((site, index) => (
                                                        <MenuItem key={index} value={site.name}>
                                                            {site.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                id="slides"
                                                label="Number of slides"
                                                type="number"
                                                className="resMiddle"
                                                onChange={this.handleChange}
                                                value={this.state.slides}
                                                inputProps={{ min: 0, max: 9 }}
                                            />

                                            <FormControl className="resRight">
                                                <InputLabel>Discount</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="discount"
                                                    value={this.state.discount}
                                                    label="Site Type"
                                                    onChange={this.discountChange}
                                                >
                                                    {this.state.discounts.map((discount, index) => (
                                                        <MenuItem key={index} value={discount.name}>
                                                            {discount.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </>
                                        :
                                        <>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    id="checkIn"
                                                    label="Check In*"
                                                    className="resLeft"
                                                    disablePast
                                                    onChange={(date) => {
                                                        this.setState({ checkIn: date }, () => {
                                                            this.formValidation();
                                                        });
                                                    }}
                                                />
                                            </LocalizationProvider>

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    id="checkOut"
                                                    label="Check Out*"
                                                    className="resMiddle"
                                                    minDate={dayjs(this.state.checkIn).add(1, "day")}
                                                    onChange={(date) => {
                                                        this.setState({ checkOut: date }, () => {
                                                            this.formValidation();
                                                        });
                                                    }}
                                                />
                                            </LocalizationProvider>

                                            <FormControl className="resLeft">
                                                <InputLabel>Site Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="siteSelect"
                                                    value={this.state.siteType}
                                                    label="Site Type"
                                                    onChange={this.siteChange}
                                                >
                                                    <MenuItem value="Any">Any</MenuItem>
                                                    {this.state.sites.map((site, index) => (
                                                        <MenuItem key={index} value={site.name}>
                                                            {site.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </>
                                    }
                                    <Box></Box>
                                    <Button
                                        className="resButton"
                                        style={{
                                            backgroundColor: this.state.formValid
                                                ? "#2dcd7a"
                                                : "#999999",
                                        }}
                                        onClick={this.findSpace}
                                        disabled={!this.state.formValid}
                                    >
                                        Find Space
                                    </Button>
                                </Box>
                            </Box>
                        </ThemeProvider>
                    </>
                    :
                    <>
                        {this.state.groupReservations
                            ?
                            <GroupReservationSpaceSelect
                                serverName={this.props.serverName} cancelReservation={this.cancelReservation}
                                sites={this.state.sites} rigs={this.state.rigs} extras={this.state.extras} rigType={this.state.rigType} rigLength={this.state.rigLength} slides={this.state.slides}
                                checkIn={this.state.checkIn} checkOut={this.state.checkOut} guests={this.state.guests} pets={this.state.pets} children={this.state.children}
                                spaceData={this.state.spaceData}
                                discount={this.state.discount} dailyDiscount={this.state.discountApplyDaily} weeklyDiscount={this.state.discountApplyWeek} monthlyDiscount={this.state.discountApplyMonth}
                                seasonalDiscount={this.state.discountApplySeasonal} discountPercent={this.state.discountPercent} discountId={this.state.discountId} discountAmountOnce={this.state.discountAmountOnce}
                                discountAmountDaily={this.state.discountAmountDaily} discountAmountWeekly={this.state.discountAmountWeekly} discountAmountMonthly={this.state.discountAmountMonthly}
                            />
                            :
                            <NewReservationSpaceSelect
                                serverName={this.props.serverName} cancelReservation={this.cancelReservation}
                                sites={this.state.sites} rigs={this.state.rigs} extras={this.state.extras} rigType={this.state.rigType} rigLength={this.state.rigLength} slides={this.state.slides}
                                checkIn={this.state.checkIn} checkOut={this.state.checkOut} guests={this.state.guests} pets={this.state.pets} children={this.state.children}
                                spaceData={this.state.spaceData}
                                discount={this.state.discount} dailyDiscount={this.state.discountApplyDaily} weeklyDiscount={this.state.discountApplyWeek} monthlyDiscount={this.state.discountApplyMonth}
                                seasonalDiscount={this.state.discountApplySeasonal} discountPercent={this.state.discountPercent} discountId={this.state.discountId} discountAmountOnce={this.state.discountAmountOnce}
                                discountAmountDaily={this.state.discountAmountDaily} discountAmountWeekly={this.state.discountAmountWeekly} discountAmountMonthly={this.state.discountAmountMonthly}
                            />
                        }
                    </>
                }
            </Grid>
        );
    }
}

export default withRouter(withStyles(useStyles)(NewReservationForm));
