import React, { Component } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import { customerDetailChanges, vehicleDetailChange, guestPrivateNoteChange } from '../utils/OpenCampground_Database_Calls'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneIcon from '../assets/phone.png';
import EmailIcon from '../assets/email_icon.png';
import LocationIcon from '../assets/location.png';
import VehicleIcon from '../assets/vehicle.png';
import NotesIcon from '../assets/Notes.png';
import CamperIcon from '../assets/Camper.png';
import GroupsIcon from '../assets/groups.png';
import ChildrenIcon from '../assets/children.png';
import PetsIcon from '../assets/Pets.png';
import BillIcon from '../assets/Bill.png';
import AVGIcon from '../assets/avg available.png';
import BalanceIcon from '../assets/balance_due.png';

import '../CSS/GuestDetail.css';

class GuestDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //customer details
            editCustomerInfo: false,
            fname: null,
            lname: null,
            phoneNumber: null,
            emailId: null,
            address: null,
            city: null,
            state: null,
            zip: null,

            //vehicle details
            editVehicleDetails: false,
            vstate: null,
            vlicense: null,
            vlength: null,
            vslides: null,

            //private notes
            editPrivateNotes: false,
            note: null
        };
    }

    async componentDidMount() {
        const length = this.props.individualGuestDetails.length - 1;
        this.setState({
            fname: this.props.individualGuestDetails[length].first_name,
            lname: this.props.individualGuestDetails[length].last_name,
            phoneNumber: this.props.individualGuestDetails[length].phone,
            emailId: this.props.individualGuestDetails[length].email,
            address: this.props.individualGuestDetails[length].address,
            city: this.props.individualGuestDetails[length].city,
            state: this.props.individualGuestDetails[length].state,
            zip: this.props.individualGuestDetails[length].mail_code,
            vstate: this.props.individualGuestDetails[length].vehicle_state,
            vlicense: this.props.individualGuestDetails[length].vehicle_license,
            vlength: this.props.individualGuestDetails[length].length,
            vslides: this.props.individualGuestDetails[length].slides,
            note: this.props.individualGuestDetails[length].notes
        })
    }

    guestDetailChanges = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    resetValues = (type) => {
        const length = this.props.individualGuestDetails.length - 1;
        if (type === "customer") {
            this.setState({
                editCustomerInfo: false,
                fname: this.props.individualGuestDetails[length].first_name,
                lname: this.props.individualGuestDetails[length].last_name,
                phoneNumber: this.props.individualGuestDetails[length].phone,
                emailId: this.props.individualGuestDetails[length].email,
                address: this.props.individualGuestDetails[length].address,
                city: this.props.individualGuestDetails[length].city,
                state: this.props.individualGuestDetails[length].state,
                zip: this.props.individualGuestDetails[length].mail_code,
            })
        } else if (type === "vehicle") {
            this.setState({
                editVehicleDetails: false,
                vstate: this.props.individualGuestDetails[length].vehicle_state,
                vlicense: this.props.individualGuestDetails[length].vehicle_license,
                vlength: this.props.individualGuestDetails[length].length,
                vslides: this.props.individualGuestDetails[length].slides
            })
        } else {
            this.setState({
                editPrivateNotes: false,
                note: this.props.individualGuestDetails[length].notes
            })
        }
    }

    saveUserDetails = async (camperId) => {
        let changeCamperDetails = await customerDetailChanges(this.props.serverName, this.state.fname, this.state.lname, this.state.emailId, this.state.phoneNumber,
            this.state.address, this.state.city, this.state.state, this.state.zip, camperId);
        console.log(changeCamperDetails)
        this.setState({ editCustomerInfo: false })
    }

    saveVehicleDetails = async (reservationId) => {
        let changeVehicleDetails = await vehicleDetailChange(this.props.serverName, this.state.vstate, this.state.vlicense, this.state.vlength, this.state.vslides, reservationId)
        this.setState({ editVehicleDetails: false })
    }

    savePrivateNotes = async (camperId) => {
        let editedNote = await guestPrivateNoteChange(this.props.serverName, this.state.note, camperId)
        this.setState({ editPrivateNotes: false })
    }

    render() {
        const { backToGuest, individualGuestDetails, serverName } = this.props;
        const length = individualGuestDetails.length - 1;

        const pastReservationsRows = individualGuestDetails
            .slice(0, length)
            .map((item) => (
                <tr key={item.id} className="reservationRows">
                    <td>{item.reservationID}</td>
                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td>{item.rigType}</td>
                    <td>{item.siteName}</td>
                    <td>{"$"}{item.amountPaid}</td>
                </tr>
            ));

        return (
            <div className="guest-detail">
                <div className="btn" >
                    <Button startIcon={<ArrowBackIcon />} className="buttn" onClick={() => { backToGuest(); }}>
                        Guests
                    </Button>
                </div>
                <div style={{ marginTop: "20px" }}>
                    <div class="container">
                        <div style={{ width: "73%" }}>
                            {this.state.editCustomerInfo
                                ?
                                <>
                                    <div class="customer-info" >
                                        <div className='form-row'>
                                            <TextField id="fname" required disabled={this.state.disabledTextFields} label="First Name" type="text"
                                                className="field" value={this.state.fname} onChange={this.guestDetailChanges} InputLabelProps={{ shrink: true }} />
                                            <TextField id="lname" required disabled={this.state.disabledTextFields} label="Last Name" type="text"
                                                className="field" style={{ marginLeft: '3%' }} value={this.state.lname} onChange={this.guestDetailChanges} InputLabelProps={{ shrink: true }} />
                                        </div>

                                        <div className='form-row'>
                                            <TextField id="emailId" required disabled={this.state.disabledTextFields} label="Email Id" type="email"
                                                className="field" value={this.state.emailId} onChange={this.guestDetailChanges} InputLabelProps={{ shrink: true }} />
                                            <TextField id="phoneNumber" required disabled={this.state.disabledTextFields} label="Phone Number" type="number"
                                                className="field" style={{ marginLeft: '3%' }} value={this.state.phoneNumber} onChange={this.guestDetailChanges} InputLabelProps={{ shrink: true }} />
                                        </div>

                                        <div className='form-row-2'>
                                            <TextField id="address" required disabled={this.state.disabledTextFields} label="Address" type="text"
                                                className="field full-width" value={this.state.address} onChange={this.guestDetailChanges} InputLabelProps={{ shrink: true }} />
                                        </div>

                                        <div className='form-row-3'>
                                            <TextField id="city" required disabled={this.state.disabledTextFields} label="City" type="text"
                                                className="field left" value={this.state.city} onChange={this.guestDetailChanges} InputLabelProps={{ shrink: true }} />
                                            <TextField id="state" required disabled={this.state.disabledTextFields} label="State" type="text"
                                                className="field middle" value={this.state.state} onChange={this.guestDetailChanges} InputLabelProps={{ shrink: true }} />
                                            <TextField id="zip" required disabled={this.state.disabledTextFields} label="Postal Code" type="text"
                                                className="field right" value={this.state.zip} onChange={this.guestDetailChanges} InputLabelProps={{ shrink: true }} />
                                        </div>

                                        <Button style={{ textTransform: 'capitalize', padding: "0px 15px", height: "35px", fontSize: '14px', marginRight: '20px', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }}
                                            onClick={() => {
                                                this.saveUserDetails(individualGuestDetails[length].camperId)
                                            }}>Save
                                        </Button>

                                        <Button style={{ textTransform: 'capitalize', padding: "0px 15px", height: "35px", fontSize: '14px', marginRight: '20px', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }}
                                            onClick={() => {
                                                this.resetValues("customer")
                                            }}>Cancel
                                        </Button>
                                    </div>
                                </>
                                :
                                <>
                                    <div class="customer-info" >
                                        <h3 className='imageFlexBoxBetween'>{this.state.fname} {this.state.lname}</h3>
                                        <p className='imageFlexBoxStart' style={{ fontSize: "14px" }}> <img width="40px" src={PhoneIcon} /> {this.state.phoneNumber}</p>
                                        <p className='imageFlexBoxStart' style={{ fontSize: "14px" }}> <img width="40px" src={EmailIcon} /> {this.state.emailId}</p>
                                        <p className='imageFlexBoxStart' style={{ fontSize: "14px" }}> <img width="40px" src={LocationIcon} />
                                            {this.state.address}, {this.state.city}, {this.state.state}, {this.state.zip}
                                        </p>
                                        <Button style={{ textTransform: 'capitalize', padding: "0px 15px", height: "35px", fontSize: '14px', marginRight: '20px', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }}
                                            onClick={() => { this.setState({ editCustomerInfo: true }) }}>
                                            Edit Profile
                                        </Button>
                                    </div>
                                </>
                            }
                            <div class="reservation-details current-reservation">
                                <h2>Latest Reservations</h2>

                                <div class="reservation-info">
                                    <p style={{ fontSize: "18px" }}><strong>Site: {individualGuestDetails[length].siteName} </strong>
                                        <span class="reserved">
                                            {individualGuestDetails[length].cancelled ? "Cancelled" : null}
                                            {individualGuestDetails[length].archived && !individualGuestDetails[length].cancelled ? "Completed" : null}
                                            {individualGuestDetails[length].checked_in && individualGuestDetails[length].archived == 0 ? "Checked In" : null}
                                            {!individualGuestDetails[length].checked_in && !individualGuestDetails[length].archived && !individualGuestDetails[length].cancelled ? "Reserved" : null}
                                            {/* Reserved */}
                                        </span>
                                    </p>
                                    <p style={{ marginLeft: "40px", fontSize: "18px" }}><strong>Reservation #: {individualGuestDetails[length].reservationID}</strong></p>
                                    <p style={{ marginLeft: "40px", fontSize: "18px" }}><strong>{individualGuestDetails[length].startDate} / {individualGuestDetails[length].endDate}</strong></p>
                                </div>

                                <div class="reservation-dates">
                                    <p><span><img src={CamperIcon} />Rig Type:</span> {individualGuestDetails[length].rigType}</p>
                                    <p><span><img src={GroupsIcon} />Group:</span> {individualGuestDetails[length].groupName != null ? individualGuestDetails[length].groupName : "N/A"}</p>
                                    <p><span><img src={ChildrenIcon} />Adults:</span> {individualGuestDetails[length].adults}</p>
                                    <p><span><img src={ChildrenIcon} />Children:</span> {individualGuestDetails[length].kids}</p>
                                    <p><span><img src={PetsIcon} />Pets:</span> {individualGuestDetails[length].pets}</p>
                                </div>

                                <div class="payment-info">
                                    {/* total */}
                                    <div style={{ display: "flex", alignItems: "center", padding: "10px", minWidth: "200px", background: "#E6F5ED", borderRadius: "8px" }}>
                                        <div>
                                            <div style={{ marginRight: "20px", borderRadius: "12px", padding: "10px", background: "#FFF", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={BillIcon} />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                Total:
                                                <h2>
                                                    ${individualGuestDetails[length].total}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    {/* paid */}
                                    <div style={{ display: "flex", alignItems: "center", padding: "10px", minWidth: "200px", background: "#EBF0FE", borderRadius: "8px" }}>
                                        <div>
                                            <div style={{ marginRight: "20px", borderRadius: "12px", padding: "10px", background: "#FFF", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={AVGIcon} />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                Paid:
                                            </div>
                                            <h2>
                                                ${individualGuestDetails[length].amountPaid}
                                            </h2>
                                        </div>
                                    </div>
                                    {/* balance */}
                                    <div style={{ display: "flex", alignItems: "center", padding: "10px", minWidth: "200px", background: "#F0DDDE", borderRadius: "8px" }}>
                                        <div>
                                            <div style={{ marginRight: "20px", borderRadius: "12px", padding: "10px", background: "#FFF", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={BalanceIcon} />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                Balance Due:
                                            </div>
                                            <h2>
                                                ${individualGuestDetails[length].total - individualGuestDetails[length].amountPaid}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {length > 0
                                ?
                                <div class="reservation-details past-reservations">
                                    <div style={{ display: "flex", justifyContent: 'space-between', marginBottom: '20px' }}>
                                        <h2 style={{ margin: "0px" }}>Past Reservations</h2>
                                    </div>
                                    <Grid>
                                        <Box className='reservationTableHeadingBox'>
                                            <Typography className='headerTypo'>Reservation #</Typography>
                                            <Typography className='headerTypo'>Start Date</Typography>
                                            <Typography className='headerTypo'>End Date</Typography>
                                            <Typography className='headerTypo'>Rig Type</Typography>
                                            <Typography className='headerTypo'>Site #</Typography>
                                            <Typography className='headerTypo'>Amount Paid</Typography>
                                        </Box>
                                        <Box className='tableBox'>
                                            <table>
                                                <tbody>
                                                    {pastReservationsRows}
                                                </tbody>
                                            </table>
                                        </Box>
                                    </Grid>
                                </div>
                                :
                                null
                            }
                        </div>
                        <aside>
                            {this.state.editVehicleDetails
                                ?
                                <>
                                    <div class="vehicle-details" style={{ marginRight: '-10%' }}>
                                        <h3 className='imageFlexBoxBetween'> <img src={VehicleIcon} width="40px" /> Vehicle Details </h3>
                                        <div className='fields'>
                                            <div>
                                                <p>Vehicle State </p>
                                                <input id="vstate" style={{ textAlign: 'center' }} value={this.state.vstate} onChange={this.guestDetailChanges} />
                                            </div>
                                            <div>
                                                <p>Vehicle License </p>
                                                <input id="vlicense" style={{ textAlign: 'center' }} value={this.state.vlicense} onChange={this.guestDetailChanges} />
                                            </div>
                                            <div>
                                                <p>Length </p>
                                                <input id="vlength" style={{ textAlign: 'center' }} value={this.state.vlength} onChange={this.guestDetailChanges} />
                                            </div>
                                            <div>
                                                <p>Slides </p>
                                                <input id="vslides" style={{ textAlign: 'center' }} value={this.state.vslides} onChange={this.guestDetailChanges} />
                                            </div>
                                        </div>
                                        <Button style={{ marginTop: '5%', textTransform: 'capitalize', padding: "0px 15px", height: "35px", fontSize: '14px', marginRight: '20px', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }}
                                            onClick={() => {
                                                this.saveVehicleDetails(individualGuestDetails[length].reservationID)
                                            }}>Save</Button>
                                        <Button style={{ marginTop: '5%', textTransform: 'capitalize', padding: "0px 15px", height: "35px", fontSize: '14px', marginRight: '20px', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }}
                                            onClick={() => {
                                                this.resetValues("vehicle")
                                            }}>Cancel</Button>
                                    </div>
                                </>
                                :
                                <>
                                    <div class="vehicle-details" style={{ marginRight: '-10%' }}>
                                        <h3 className='imageFlexBoxBetween'> <img src={VehicleIcon} width="40px" /> Vehicle Details </h3>
                                        <div className='fields'>
                                            <div>
                                                <p>Vehicle State </p>
                                                <p>{this.state.vstate != null ? this.state.vstate : "N/A"}</p>
                                            </div>
                                            <div>
                                                <p>Vehicle License </p>
                                                <p>{this.state.vlicense != null ? this.state.vlicense : "N/A"}</p>
                                            </div>
                                            <div>
                                                <p>Length </p>
                                                <p>{this.state.vlength != null ? this.state.vlength : "N/A"}</p>
                                            </div>
                                            <div>
                                                <p>Slides </p>
                                                <p>{this.state.vslides}</p>
                                            </div>
                                        </div>
                                        <Button style={{ marginTop: '5%', textTransform: 'capitalize', padding: "0px 15px", height: "35px", fontSize: '14px', marginRight: '20px', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }}
                                            onClick={() => { this.setState({ editVehicleDetails: true }) }}>Edit Details</Button>
                                    </div>
                                </>
                            }
                            {this.state.editPrivateNotes
                                ?
                                <>
                                    <div class="private-notes" style={{ marginRight: '-10%' }}>
                                        <h3 className='imageFlexBoxBetween'><img src={NotesIcon} width="40px" /> Private Notes </h3>
                                        <textarea rows={8} id="note" onChange={this.guestDetailChanges} value={this.state.note}
                                            style={{ width: '90%', marginBottom: '5%', whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                                        />
                                        <Button style={{ textTransform: 'capitalize', padding: "0px 15px", height: "35px", fontSize: '14px', marginRight: '20px', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }}
                                            onClick={() => {
                                                this.savePrivateNotes(individualGuestDetails[length].camperId)
                                            }}>Save</Button>
                                        <Button style={{ textTransform: 'capitalize', padding: "0px 15px", height: "35px", fontSize: '14px', marginRight: '20px', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }}
                                            onClick={() => {
                                                this.resetValues("notes")
                                            }}>Cancel</Button>
                                    </div>
                                </>
                                :
                                <>
                                    <div class="private-notes" style={{ marginRight: '-10%' }}>
                                        <h3 className='imageFlexBoxBetween'><img src={NotesIcon} width="40px" /> Private Notes </h3>
                                        <p style={{ minHeight: "180px" }}>{this.state.note}</p>
                                        <Button style={{ textTransform: 'capitalize', padding: "0px 15px", height: "35px", fontSize: '14px', marginRight: '20px', backgroundColor: "rgb(153, 153, 153)", color: "#FFF" }}
                                            onClick={() => {
                                                this.setState({ editPrivateNotes: true })
                                            }}>Edit Notes</Button>
                                    </div>
                                </>
                            }
                        </aside>
                    </div>
                </div>
            </div>
        );
    }
}

export default GuestDetail;
