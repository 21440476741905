import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import '../CSS/Settings.css';
import { deleteRecord, getTax, createNewTax, updateTax } from '../utils/OpenCampground_Database_Calls'; // Assume you have an updateTax function
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Dialog, DialogContent } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Taxes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taxes: [],
            taxesUpdate: false,
            taxName: '',
            percent: '0',
            amount: '0',
            isPercent: false,
            daily: true,
            week: false,
            month: false,
            season: false,
            active: true,
            editingTaxId: null,
        };
    }

    async componentDidMount() {
        this.taxes();
    }

    handleChange = (event) => {
        const { id, value, checked } = event.target;
        if (id === "isPercent" && event.target.type === "checkbox") {
            this.setState(
                {
                    [id]: checked,
                    amount: checked ? "0" : this.state.amount, 
                    percent: !checked ? "0" : this.state.percent, 
                },
                () => {
                    this.formValidation();
                }
            );
        } else {
            this.setState(
                {
                    [id]: event.target.type === "checkbox" ? checked : value,
                },
                () => {
                    this.formValidation();
                }
            );
        }
    }

    formValidation = () => {
        if (this.state.taxName && this.state.percent && this.state.amount) {
            this.setState({ formValid: true });
        } else {
            this.setState({ formValid: false });
        }
    }

    deleteRecord = async (data) => {
        await deleteRecord(this.props.serverName, "taxrates", data.id);
        this.setState({ taxesUpdate: true });
        this.taxes();
    }

    taxes = async () => {
        if (this.state.taxesUpdate) {
            let taxes = await getTax(this.props.serverName);
            this.setState({ taxes });
        } else if (this.state.taxes.length === 0) {
            let taxes = await getTax(this.props.serverName);
            this.setState({ taxes });
        }
    }

    addNew = async () => {
        await createNewTax(this.props.serverName, this.state.taxName, this.state.isPercent ? "1" : "0",
            this.state.percent, this.state.amount, this.state.daily ? "1" : "0", this.state.week ? "1" : "0",
            this.state.month ? "1" : "0", this.state.season ? "1" : "0");
        this.resetForm()
        this.taxes();
    }

    startEditing = (tax) => {
        this.setState({
            editingTaxId: tax.id,
            taxName: tax.name,
            percent: tax.percent,
            amount: tax.amount,
            isPercent: tax.is_percent,
            daily: tax.apl_daily,
            week: tax.apl_week,
            month: tax.apl_month,
            season: tax.apl_seasonal,
            active: tax.active
        });
    }

    saveEdits = async () => {
        await updateTax(this.props.serverName, this.state.editingTaxId, this.state.taxName, this.state.percent, this.state.amount,
            this.state.isPercent ? 1 : 0, this.state.daily ? 1 : 0, this.state.week ? 1 : 0, this.state.month ? 1 : 0, this.state.season ? 1 : 0, this.state.active ? 1 : 0);
        this.resetForm()
        this.taxes();
    }

    resetForm = () => {
        this.setState({
            taxesUpdate: true,
            taxName: '',
            percent: '0',
            amount: '0',
            isPercent: false,
            daily: true,
            week: false,
            month: false,
            season: false,
            active: true,
            editingTaxId: null,
            addNew: null
        })
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Button className='resButton' onClick={() => { this.setState({ addNew: true }) }} style={{ backgroundColor: '#2dcd7a', color: '#000000', marginBottom: '2%', textTransform: "capitalize" }}>Add New Tax</Button>
                {this.state.addNew || this.state.editingTaxId
                    ? <Dialog open={this.state.addNew || this.state.editingTaxId} onClose={() => {
                        this.resetForm();
                    }} maxWidth="sm" fullWidth>
                        <DialogContent dividers>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                <h2 style={{ margin: "0px", marginBottom: "10px" }}>{this.state.editingTaxId ? "Update Tax" : "Add Tax"}</h2>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                        <TextField
                                            id="taxName"
                                            required
                                            label="Tax Name"
                                            value={this.state.taxName}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>

                                        {this.state.isPercent
                                            ?
                                            <TextField
                                                id="percent"
                                                required
                                                label="Percent"
                                                value={this.state.percent}
                                                onChange={this.handleChange}
                                            />
                                            :
                                            <TextField
                                                id="amount"
                                                required
                                                label="Amount"
                                                value={this.state.amount}
                                                onChange={this.handleChange}
                                            />
                                        }
                                        <FormControlLabel
                                            label={"Tax is Percent"}
                                            control={
                                                <Checkbox
                                                    id="isPercent"
                                                    checked={this.state.isPercent}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                    </div>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
                                        <FormControlLabel
                                            label={"Applies to daily"}
                                            control={
                                                <Checkbox
                                                    id="daily"
                                                    checked={this.state.daily}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label={"Applies to weekly"}
                                            control={
                                                <Checkbox
                                                    id="week"
                                                    checked={this.state.week}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label={"Applies to monthly"}
                                            control={
                                                <Checkbox
                                                    id="month"
                                                    checked={this.state.month}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label={"Applies to seasonal"}
                                            control={
                                                <Checkbox
                                                    id="season"
                                                    checked={this.state.season}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                                        <Button
                                            style={{ backgroundColor: '#2dcd7a', color: '#000000' }}
                                            onClick={this.state.editingTaxId ? this.saveEdits : this.addNew}
                                            disabled={this.state.editingTaxId ? false : !this.state.formValid}
                                        >
                                            {this.state.editingTaxId ? "Update" : "Add"}
                                        </Button>
                                        <Button
                                            style={{ backgroundColor: '#FDECEB', color: '#DB3737' }}
                                            onClick={() => { this.resetForm() }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog> : ""}
                <TableContainer component={Paper} style={{ width: '90%', margin: '0 auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className='tableCell'>Name</TableCell>
                                <TableCell className='tableCell'>Is Percent</TableCell>
                                <TableCell className='tableCell'>Percent</TableCell>
                                <TableCell className='tableCell'>Daily Amount </TableCell>
                                <TableCell className='tableCell'>Applies To Daily</TableCell>
                                <TableCell className='tableCell'>Applies To Weekly</TableCell>
                                <TableCell className='tableCell'>Applies To Monthly</TableCell>
                                <TableCell className='tableCell'>Applies To Seasonal</TableCell>
                                <TableCell className='tableCell'>Active</TableCell>
                                <TableCell className='tableCell'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.taxes.map((tax) => (
                                <TableRow key={tax.id}>
                                    <TableCell className='tableCell'>
                                        {tax.name}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {tax.is_percent ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {this.state.editingTaxId === tax.id
                                            ? <TextField
                                                value={this.state.percent}
                                                id="percent"
                                                onChange={this.handleChange}
                                                style={{ width: '50%' }}
                                            />
                                            : `${tax.percent}%`}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {`$${tax.amount}`}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {tax.apl_daily ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {tax.apl_week ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {tax.apl_month ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {tax.apl_seasonal ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {tax.active ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        <div>
                                            <Button className='viewButtons' onClick={() => this.startEditing(tax)} style={{ backgroundColor: '#d4d4d4', color: 'black' }}>Edit</Button>
                                            <Button className='viewButtons' style={{ marginTop: '10%' }} onClick={() => this.deleteRecord(tax)}>Delete</Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </ThemeProvider>
        );
    };
}

export default Taxes;
