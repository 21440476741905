import React, { Component } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch, Link, useLocation } from 'react-router-dom';
import '../CSS/LandingPage.css';
import logo from '../assets/Main_Logo.png'
import Reservations from './Reservations';
import reservations from '../assets/reservation.png'
// import NewReservation from './NewReservation';
import NewReservationForm from './NewReservationForm';
import newReservations from '../assets/new-reservations.png'
import Guests from './Guests';
import guests from '../assets/guests.png'
import SiteAvailability from './SiteAvailability';
import siteAvailability from '../assets/siteAvaibility.png'
import Transactions from './Transactions';
import transactions from '../assets/transactions.png'
import Archived from './Archived';
import archived from '../assets/archived.png'
import Reports from './Reports';
import reports from '../assets/reports.png'
import knowledgeCenter from '../assets/knowledge center.png'
import Settings from './Settings';
import settings from '../assets/settings.png'
import Users from './Users';
import users from '../assets/manager-users.png'
import logout from '../assets/logout.png'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Content = ({ serverName, user }) => {
    const query = useQuery();
    const view = query.get('view');

    switch (view) {
        case 'newReservation':
            // return <NewReservation serverName={serverName} user={user} />;
            return <NewReservationForm serverName={serverName} user={user} />;
        case 'guests':
            return <Guests serverName={serverName} user={user} />;
        case 'siteAvailability':
            return <SiteAvailability serverName={serverName} user={user} />;
        case 'transactions':
            return <Transactions serverName={serverName} user={user} />;
        case 'archived':
            return <Archived serverName={serverName} user={user} />;
        case 'settings':
            return <Settings serverName={serverName} user={user} />;
        case 'users':
            return <Users serverName={serverName} user={user} />;
        case 'reports':
            return <Reports serverName={serverName} user={user} />;
        default:
            return <Reservations serverName={serverName} user={user} />;
    }
};


class LandingPage extends Component {
    render() {
        return (
            <Router>
                <Grid className='root'>
                    <Grid className='sidebar'>
                        <img src={logo} alt="Login Image" className='loginImage' />

                        <Typography className='typography'>Guest Management</Typography>
                        <Button className='button'>
                            <Link to={`/manager?serverName=${this.props.serverName}&view=reservations`} className='link'>
                                <img src={reservations} alt="Reservations" className='buttonImage' />
                                <span className='linksLabel'>Reservations</span>
                            </Link>
                        </Button>
                        <Button className='button'>
                            <Link to={`/manager?serverName=${this.props.serverName}&view=newReservation`} className='link'>
                                <img src={newReservations} alt="NewReservations" className='buttonImage' />
                                <span className='linksLabel'>New reservation</span>
                            </Link>
                        </Button>
                        <Button className='button'>
                            <Link to={`/manager?serverName=${this.props.serverName}&view=guests`} className='link'>
                                <img src={guests} alt="Guests" className='buttonImage' />
                                <span className='linksLabel'>Guests</span>
                            </Link>
                        </Button>
                        <Button className='button'>
                            <Link to={`/manager?serverName=${this.props.serverName}&view=siteAvailability`} className='link'>
                                <img src={siteAvailability} alt="Guests" className='buttonImage' />
                                <span className='linksLabel'>Site Availability</span>
                            </Link>
                        </Button>

                        <Typography className='typography'>Reporting</Typography>
                        <Button className='button'>
                            <Link to={`/manager?serverName=${this.props.serverName}&view=transactions`} className='link'>
                                <img src={transactions} alt="Transactions" className='buttonImage' />
                                <span className='linksLabel'>Transactions</span>
                            </Link>
                        </Button>
                        <Button className='button'>
                            <Link to={`/manager?serverName=${this.props.serverName}&view=archived`} className='link'>
                                <img src={archived} alt="Archived" className='buttonImage' />
                                <span className='linksLabel'>Archived</span>
                            </Link>
                        </Button>
                        <Button className='button'>
                            <Link to={`/manager?serverName=${this.props.serverName}&view=reports`} className='link'>
                                <img src={reports} alt="Reports" className='buttonImage' />
                                <span className='linksLabel'>Custom Reports</span>
                            </Link>
                        </Button>

                        <Typography className='typography'>Help / Support</Typography>
                        <Button
                            className="button"
                            onClick={() => window.open('https://opencampground.zohodesk.com/portal/en/home', '_blank')}
                            style={{ justifyContent: 'flex-start' }}
                        >
                            <img src={knowledgeCenter} alt="Settings" className="buttonImage" />
                            <span className='linksLabel'>Knowledge Base</span>
                        </Button>

                        <Typography className='typography'>Admin</Typography>
                        <Button className='button'>
                            <Link to={`/manager?serverName=${this.props.serverName}&view=settings`} className='link'>
                                <img src={settings} alt="Settings" className='buttonImage' />
                                <span className='linksLabel'>Settings</span>
                            </Link>
                        </Button>
                        {this.props.admin === 0
                            ? null
                            : <Button className='button'>
                                <Link to={`/manager?serverName=${this.props.serverName}&view=users`} className='link'>
                                    <img src={users} alt="Users" className='buttonImage' />
                                    <span className='linksLabel'>Manage Users</span>
                                </Link>
                            </Button>
                        }
                        <Button className='button link' onClick={() => { this.props.goBack() }}>
                            <Link to={`/manager?serverName=${this.props.serverName}`} className='link'>
                                <img src={logout} alt="LogOut" className='buttonImage' />
                                <span className='linksLabel'>Log Out</span>
                            </Link>
                        </Button>
                    </Grid>
                    <Switch>
                        <Route path="/manager" render={(props) => <Content {...props} serverName={this.props.serverName} user={this.props.user} />} />
                    </Switch>
                </Grid>
            </Router>
        );
    }
}

export default LandingPage;
