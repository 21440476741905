import React, { Component } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import RemoteReservations from './RemoteReservations';
import { getRemoteReservationsStatus, getSuspendedStatus, getBlackoutDates, getRemoteStatusIntegrationBlackout } from '../utils/OpenCampground_Database_Calls'
import * as QueryString from "query-string";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
const useStyles = theme => ({
    circularProgress: {
        position: 'absolute',
        left: '55%',
        top: '50%',
        color: '#2dcd7a'
    }
});

class RemoteLangingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serverName: '',
            remote: null,
            status: null,
            allowPayment: null,
            requirePayment: null,
            integrations: [],
            blackoutDates: []
        };
    }

    async componentDidMount() {
        const params = QueryString.parse(this.props.location.search);
        let suspendedResponse = await getSuspendedStatus(params.serverName)
        if (suspendedResponse.body[0].suspended == 1) {
            console.log("suspended")
            this.setState({
                serverName: params.serverName,
                status: "suspended"
            })
        } else {
            let getRemoteStatusIntegrationBlackoutResponse = await getRemoteStatusIntegrationBlackout(params.serverName)
            this.setState({
                serverName: params.serverName,
                status: "operational",
                remote: getRemoteStatusIntegrationBlackoutResponse[0][0].use_remote_reservations === 1,
                allowPayment: getRemoteStatusIntegrationBlackoutResponse[0][0].allow_gateway === 1,
                requirePayment: getRemoteStatusIntegrationBlackoutResponse[0][0].require_gateway === 1,
                integrations: getRemoteStatusIntegrationBlackoutResponse[1],
                blackoutDates: getRemoteStatusIntegrationBlackoutResponse[2]
            })
        }
    }

    render() {
        const { classes } = this.props;
        if (this.state.remote) {
            return (
                <RemoteReservations serverName={this.state.serverName} allow={this.state.allowPayment} require={this.state.requirePayment}
                    integrations={this.state.integrations} blackoutDates={this.state.blackoutDates} />
            );
        } else if (this.state.remote === false) {
            return (
                <Box style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box style={{ backgroundColor: '#ffffff', padding: '2%', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                        <Typography style={{ color: 'red', fontSize: '2rem', marginBottom: '10px' }}>
                            Online Reservations are disabled at this time.
                        </Typography>
                    </Box>
                </Box>
            );
        } else if (this.state.status === "suspended") {
            return (
                <Box style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box style={{ backgroundColor: '#ffffff', padding: '2%', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                        <Typography style={{ color: 'red', fontSize: '2rem', marginBottom: '10px' }}>
                            Online Reservations are not accessible at this time. Please contact the staff.
                        </Typography>
                    </Box>
                </Box>
            );
        } else {
            return (
                <CircularProgress className={classes.circularProgress} />
            );
        }
    };
};

export default withStyles(useStyles)(RemoteLangingPage);