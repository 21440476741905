import './App.css';
// import LandingPage from './components/LandingPage';
import Login from './components/Login';
import RemoteLangingPage from './components/RemoteLangingPage';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route path='/manager' component={Login} />
          <Route path='/remote' component={RemoteLangingPage} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
